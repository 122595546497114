import React from "react"
import styles from "../OrderDescripton.module.scss"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { getFileIco } from "../../../utils/helpers"
import { getApiUrl } from "../../../utils/getApiUrl"

interface Props {
  el: any
}

const FileRow: React.FC<Props> = ({ el }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const fileType = el?.filename?.substring(el?.filename?.lastIndexOf(".") + 1, el?.filename?.length)
  const [iconVisible, iconSrc] = getFileIco(fileType)

  return (
    <a
      href={`${getApiUrl()}/public/attachment/${el?.id}`}
      className={styles["file-row"]}
      target="_blank"
      rel="noreferrer"
    >
      {iconVisible ? <img src={`${iconSrc}` ?? ""} alt="" /> : <></>}
      <div className={styles["file-row__text"]}>
        <div>{el.filename}</div>
        <div>
          {`${Number(el?.size / 1000000).toFixed(2)}Mb ${
            el?.createdAt ? moment(el?.createdAt * 1000).format(`● DD MMM YYYY ${t("in")} HH:mm`) : ""
          }`}
        </div>
      </div>
    </a>
  )
}

export default FileRow
