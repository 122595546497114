// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accordion_accordion--is-open__k3RQ3 .Accordion_iconWrap__MFvXn {
  transform: scaleY(-1);
}
.Accordion_accordion--is-open__k3RQ3 .Accordion_main__9U0le {
  display: block;
}

.Accordion_header__zDsCR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: var(--fz-lg);
  line-height: var(--lh-lg);
  font-weight: 500;
  transition: var(--transition);
  transition-property: opacity;
}
@media (hover: hover) {
  .Accordion_header__zDsCR:hover {
    opacity: 0.8;
  }
}

.Accordion_iconWrap__MFvXn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transform: scaleY(1);
}

.Accordion_main__9U0le {
  display: none;
  padding: 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/Accordion/Accordion.module.scss"],"names":[],"mappings":"AAEI;EACE,qBAAA;AADN;AAII;EACE,cAAA;AAFN;;AAOA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;EACA,yBAAA;EACA,gBAAA;EACA,6BAAA;EACA,4BAAA;AAJF;AAME;EACE;IACE,YAAA;EAJJ;AACF;;AAQA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;AALF;;AAQA;EACE,aAAA;EACA,cAAA;AALF","sourcesContent":[".accordion {\r\n  &--is-open {\r\n    .iconWrap {\r\n      transform: scaleY(-1);\r\n    }\r\n\r\n    .main {\r\n      display: block;\r\n    }\r\n  }\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  width: 100%;\r\n  font-size: var(--fz-lg);\r\n  line-height: var(--lh-lg);\r\n  font-weight: 500;\r\n  transition: var(--transition);\r\n  transition-property: opacity;\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      opacity: 0.8;\r\n    }\r\n  }\r\n}\r\n\r\n.iconWrap {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-shrink: 0;\r\n  width: 16px;\r\n  height: 16px;\r\n  transform: scaleY(1);\r\n}\r\n\r\n.main {\r\n  display: none;\r\n  padding: 8px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion--is-open": `Accordion_accordion--is-open__k3RQ3`,
	"iconWrap": `Accordion_iconWrap__MFvXn`,
	"main": `Accordion_main__9U0le`,
	"header": `Accordion_header__zDsCR`
};
export default ___CSS_LOADER_EXPORT___;
