// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconBtn_btn__SbuWC {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #FAFAFA;
  padding: 0;
  transition: var(--transition);
  transition-property: background-color, color;
}
@media (hover: hover) {
  .IconBtn_btn__SbuWC:is(a, button):hover {
    background-color: #EEE;
  }
}
.IconBtn_btn--border-sm__edx2o {
  border-radius: 4px;
}
.IconBtn_btn--border-circle__VAQlS {
  border-radius: 50%;
}
.IconBtn_btn--gray__7ensR {
  background-color: var(--gray-20);
  color: #6F80A1;
}
@media (hover: hover) {
  .IconBtn_btn--gray__7ensR:is(a, button):hover {
    color: var(--text-color);
  }
}
.IconBtn_btn--white__1ZUuS {
  background-color: var(--white);
}
@media (hover: hover) {
  .IconBtn_btn--white__1ZUuS:is(a, button):hover {
    background-color: var(--accent-color);
    color: var(--accent-text-color);
  }
}
.IconBtn_btn--sm__q84rO {
  width: 28px;
  height: 28px;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/IconBtn/IconBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,UAAA;EACA,6BAAA;EACA,4CAAA;AACF;AAEI;EACE;IACE,sBAAA;EAAN;AACF;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,gCAAA;EACA,cAAA;AAJJ;AAOM;EACE;IACE,wBAAA;EALR;AACF;AAUE;EACE,8BAAA;AARJ;AAWM;EACE;IACE,qCAAA;IACA,+BAAA;EATR;AACF;AAcE;EACE,WAAA;EACA,YAAA;AAZJ","sourcesContent":[".btn {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-shrink: 0;\r\n  width: 32px;\r\n  height: 32px;\r\n  border-radius: 8px;\r\n  background-color: #FAFAFA;\r\n  padding: 0;\r\n  transition: var(--transition);\r\n  transition-property: background-color, color;\r\n\r\n  &:is(a, button) {\r\n    @media (hover: hover) {\r\n      &:hover {\r\n        background-color: #EEE;\r\n      }\r\n    }\r\n  }\r\n\r\n  &--border-sm {\r\n    border-radius: 4px;\r\n  }\r\n\r\n  &--border-circle {\r\n    border-radius: 50%;\r\n  }\r\n\r\n  &--gray {\r\n    background-color: var(--gray-20);\r\n    color: #6F80A1;\r\n\r\n    &:is(a, button) {\r\n      @media (hover: hover) {\r\n        &:hover {\r\n          color: var(--text-color);\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  &--white {\r\n    background-color: var(--white);\r\n\r\n    &:is(a, button) {\r\n      @media (hover: hover) {\r\n        &:hover {\r\n          background-color: var(--accent-color);\r\n          color: var(--accent-text-color);\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  &--sm {\r\n    width: 28px;\r\n    height: 28px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `IconBtn_btn__SbuWC`,
	"btn--border-sm": `IconBtn_btn--border-sm__edx2o`,
	"btn--border-circle": `IconBtn_btn--border-circle__VAQlS`,
	"btn--gray": `IconBtn_btn--gray__7ensR`,
	"btn--white": `IconBtn_btn--white__1ZUuS`,
	"btn--sm": `IconBtn_btn--sm__q84rO`
};
export default ___CSS_LOADER_EXPORT___;
