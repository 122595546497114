import styles from "./LabelCount.module.scss"
import clsx from "clsx"

interface Props {
  count: number
  size?: "sm"
  className?: string
}

const LabelCount = ({ count, size, className }: Props) => {
  if (!count) return null
  return <span className={clsx(styles.count, size && styles["count--" + size], className)}>{count}</span>
}

export default LabelCount
