import IconBtn from "../../Assets/IconBtn/IconBtn"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import clsx from "clsx"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import Search from "../../Search/Search"
import _debounce from "lodash/debounce"
import styles from "./MessagesModalUsers.module.scss"
import Avatar from "../../Assets/Avatar/Avatar"
import ScrollBlock from "../../Assets/ScrollBlock"
import Employees from "../../Employees/Employees"

interface Props {
  onClick?: (val: string) => void
  className?: string
}

const MessagesModalUsers = ({ onClick, className }: Props) => {
  const wrapRef = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [searchChat, setSearchChat] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const debounceFn = useCallback(
    _debounce((str: string) => {
      setSearch(str)
    }, 500),
    [],
  )

  useEffect(() => {
    debounceFn(searchChat)
  }, [searchChat])

  // TODO: remove comments
  // const chatList = useMemo(() => {
  //   if (!search) return sampleChats
  //   return sampleChats.filter(({ name }) => name?.toLowerCase().includes(search.toLowerCase()))
  // }, [sampleChats, search])

  useOnClickOutside(wrapRef, () => {
    if (isOpen) setOpen(false)
  })

  return (
    <div className={clsx(styles.wrap, className)} ref={wrapRef}>
      <IconBtn icon={"plus"} borderSize={"sm"} mode={"gray"} size={"sm"} onClick={() => setOpen((prev) => !prev)} />
      {isOpen && (
        <div className={styles.drop}>
          <Employees
            isShort
            onClick={(val) => {
              if (onClick) onClick(val)
              setOpen(false)
            }}
          />
          {/*<Search searchVal={searchChat} setSearchVal={setSearchChat} className={styles.search} />*/}
          {/*{chatList?.length ? (*/}
          {/*  <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={145}>*/}
          {/*    {chatList.map((item, index) => {*/}
          {/*      return (*/}
          {/*        <button key={index} type={"button"} className={styles.item}>*/}
          {/*          <Avatar img={item.img} name={item.name} className={styles.img} size={"sm"} />*/}
          {/*          <span className={styles.itemTxt}>{item.name}</span>*/}
          {/*        </button>*/}
          {/*      )*/}
          {/*    })}*/}
          {/*  </ScrollBlock>*/}
          {/*) : (*/}
          {/*  <div>ничего не нашло</div>*/}
          {/*)}*/}
        </div>
      )}
    </div>
  )
}

export default MessagesModalUsers
