// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelCount_count__arNbV {
  border: 1.6px solid #FAFAFA;
  font-weight: 600;
  width: 20px;
  height: 20px;
  font-size: 11px;
  line-height: 1;
  flex-shrink: 0;
  background-color: #FF3C3C;
  border-radius: 50%;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.LabelCount_count--sm__HfbDy {
  font-size: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/LabelCount/LabelCount.module.scss"],"names":[],"mappings":"AAAA;EAEE,2BAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AAAF;AAEE;EACE,cAAA;AAAJ","sourcesContent":[".count {\r\n  //box-shadow: 0 0 0 3px #FAFAFA;\r\n  border: 1.6px solid #FAFAFA;\r\n  font-weight: 600;\r\n  width: 20px;\r\n  height: 20px;\r\n  font-size: 11px;\r\n  line-height: 1;\r\n  flex-shrink: 0;\r\n  background-color: #FF3C3C;\r\n  border-radius: 50%;\r\n  color: var(--white);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 0;\r\n\r\n  &--sm {\r\n    font-size: 8px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": `LabelCount_count__arNbV`,
	"count--sm": `LabelCount_count--sm__HfbDy`
};
export default ___CSS_LOADER_EXPORT___;
