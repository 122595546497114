// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectObjectModal_modal__T\\+T\\+w .modal__inner {
  max-width: 560px;
  height: 514px;
}
.SelectObjectModal_modal__T\\+T\\+w .modal__body {
  height: calc(100% - 61px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.SelectObjectModal_search__xGemd {
  margin-bottom: 8px;
}

.SelectObjectModal_selectedObjBtn__EJQTD {
  margin-bottom: 8px;
}
.SelectObjectModal_selectedObjBtn__icon__yUbX3 {
  flex-shrink: 0;
  margin-left: auto;
}

.SelectObjectModal_childrenLoading__xsn65 {
  height: 39px;
  border-radius: 6px;
  margin-bottom: 6px;
}

.SelectObjectModal_radio__qp6Lq {
  min-height: 39px;
}
.SelectObjectModal_radio__qp6Lq p {
  border-bottom: 1px solid #F3F2F2;
}

.SelectObjectModal_footer__4zC4Y {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 8px;
  margin-top: auto;
}

.SelectObjectModal_btnBack__oV6DC {
  max-width: 122px;
  width: 100%;
}

.SelectObjectModal_btnNext__PfLQu {
  max-width: 204px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/SelectObjectModal/SelectObjectModal.module.scss"],"names":[],"mappings":"AAGM;EACE,gBAAA;EACA,aAAA;AAFR;AAKM;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AAHR;;AASA;EACE,kBAAA;AANF;;AASA;EACE,kBAAA;AANF;AAQE;EACE,cAAA;EACA,iBAAA;AANJ;;AAUA;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;AAPF;;AAUA;EACE,gBAAA;AAPF;AAQE;EACE,gCAAA;AANJ;;AAUA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AAPF;;AAUA;EACE,gBAAA;EACA,WAAA;AAPF;;AAUA;EACE,gBAAA;EACA,WAAA;AAPF","sourcesContent":[".modal {\r\n  :global {\r\n    .modal {\r\n      &__inner {\r\n        max-width: 560px;\r\n        height: 514px;\r\n      }\r\n\r\n      &__body {\r\n        height: calc(100% - 61px);\r\n        display: flex;\r\n        flex-direction: column;\r\n        overflow: auto;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.search {\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.selectedObjBtn {\r\n  margin-bottom: 8px;\r\n\r\n  &__icon {\r\n    flex-shrink: 0;\r\n    margin-left: auto;\r\n  }\r\n}\r\n\r\n.childrenLoading {\r\n  height: 39px;\r\n  border-radius: 6px;\r\n  margin-bottom: 6px;\r\n}\r\n\r\n.radio {\r\n  min-height: 39px;\r\n  p {\r\n    border-bottom: 1px solid #F3F2F2;\r\n  }\r\n}\r\n\r\n.footer {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  align-items: center;\r\n  grid-gap: 8px;\r\n  margin-top: auto;\r\n}\r\n\r\n.btnBack {\r\n  max-width: 122px;\r\n  width: 100%;\r\n}\r\n\r\n.btnNext {\r\n  max-width: 204px;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `SelectObjectModal_modal__T+T+w`,
	"search": `SelectObjectModal_search__xGemd`,
	"selectedObjBtn": `SelectObjectModal_selectedObjBtn__EJQTD`,
	"selectedObjBtn__icon": `SelectObjectModal_selectedObjBtn__icon__yUbX3`,
	"childrenLoading": `SelectObjectModal_childrenLoading__xsn65`,
	"radio": `SelectObjectModal_radio__qp6Lq`,
	"footer": `SelectObjectModal_footer__4zC4Y`,
	"btnBack": `SelectObjectModal_btnBack__oV6DC`,
	"btnNext": `SelectObjectModal_btnNext__PfLQu`
};
export default ___CSS_LOADER_EXPORT___;
