export const ArrowLeftIcon = () => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.07661 0.576172L6.92514 1.4247L2.3494 6.00044L6.92514 10.5762L6.07661 11.4247L0.652344 6.00044L6.07661 0.576172Z"
      />
    </svg>
  )
}
