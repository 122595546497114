import React from "react"
import styles from "./HistoryOrdModal.module.scss"

interface Props {}

const HistoryOrdModalSkeleton = (props: Props) => {
  return (
    <>
      <div className={styles.historyOrdModal__top}>
        <div className={`selectBack ${styles.historyOrdModal__topImg} ${styles.historyOrdModal__imgSk}`} />
        <div className={`selectBack ${styles.historyOrdModal__topText} ${styles.historyOrdModal__nameSk}`} />
      </div>
      <div className={styles.historyOrdModal__line}>
        <div className={styles.historyOrdModal__topText}>
          <p className={`selectBack ${styles.historyOrdModal__text1Sk}`} />
          <h5 className={`selectBack ${styles.historyOrdModal__text2Sk}`} />
        </div>
      </div>
      <div className={styles.historyOrdModal__line}>
        <div className={styles.historyOrdModal__topText}>
          <p className={`selectBack ${styles.historyOrdModal__text1Sk}`} />
          <h5 className={`selectBack ${styles.historyOrdModal__text2Sk}`} />
        </div>
      </div>
      <div className={styles.historyOrdModal__line}>
        <div className={styles.historyOrdModal__topText}>
          <p className={`selectBack ${styles.historyOrdModal__text1Sk}`} />
          <h5 className={`selectBack ${styles.historyOrdModal__text2Sk}`} />
        </div>
      </div>
      <div className={styles.historyOrdModal__line}>
        <div className={styles.historyOrdModal__topText}>
          <p className={`selectBack ${styles.historyOrdModal__text1Sk}`} />
          <h5 className={`selectBack ${styles.historyOrdModal__text2Sk}`} />
        </div>
      </div>
      <div className={`${styles.historyOrdModal__bottom}`}>
        <div className={`selectBack ${styles.historyOrdModal__btnSk}`} />
      </div>
    </>
  )
}

export default HistoryOrdModalSkeleton
