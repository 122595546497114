import { FC, ReactNode, useEffect } from "react"
import useSetSettings from "../../hooks/useSetSettings"
import clsx from "clsx"
import Footer from "../Footer"
import Header from "../Header"
import useWindowSize from "../../hooks/useWindowSize"
import OrdersLine from "../OrdersLine/OrdersLine"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { removeActiveOrdersCount, selectAuth } from "../../redux/slice/auth"
import OrdersLineLoader from "../OrdersLine/OrdersLineLoader"
import MobileMenu from "../MobileMenu/MobileMenu"
import MobileMoreModal from "../Modals/MobileMoreModal/MobileMoreModal"
import { getMobileMenuIsHidden, getOpenIsMoreModal } from "../../redux/slice/isMoreModal"
import LanguageModal from "../Modals/LanguageModal/LanguageModal"
import {
  decreaseCountUnreadMessages,
  increaseCountOrdersWithoutReview,
  updateCountUnreadMessages,
} from "../../redux/slice/main"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import AuthModal2 from "../Modals/AuthModal2/AuthModal2"
import MessagesModal from "../Modals/MessagesModal/MessagesModal"
import { getMessagesModal, setMessagesModalIsOpen } from "../../redux/slice/modals"
import { ILastMessage } from "../../types/content"
import { getLangModalIsOpen } from "../../redux/slice/languageModal"

interface Props {
  children: ReactNode
  mainClass?: string
  asideClass?: string
  isHeaderMobHidden?: boolean
  isAsideHidden?: boolean
  aside?: ReactNode
  isUserRequired?: boolean
}

const Layout: FC<Props> = ({
  children,
  mainClass,
  asideClass,
  isHeaderMobHidden,
  isAsideHidden,
  aside,
  isUserRequired = true,
}) => {
  useSetSettings()

  const user = useAppSelector(selectAuth)
  const mobileMoreModalIsOpen = useAppSelector(getOpenIsMoreModal)
  const messagesModalIsOpen = useAppSelector(getMessagesModal)
  const mobileMenuIsHidden = useAppSelector(getMobileMenuIsHidden)
  const { openModal } = useSelector((state: any) => state.authModal)
  const langModalIsOpen = useAppSelector(getLangModalIsOpen)

  const { isDesktop } = useWindowSize()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!user?.user?.id) return
    const channel = window?.Echo?.private(`privateUser.${user.user.id}`)
    channel?.listen(".changePublicUnreadMessages", (event: { count: number }) => {
      if (event?.count === 0 || event?.count) {
        dispatch(updateCountUnreadMessages(event.count || 0))
      }
    })
    channel?.listen(".resolvePublicOrder", (event: { id: string; status_alias: "resolved" | "cancelled" }) => {
      dispatch(removeActiveOrdersCount())
      if (event.status_alias === "resolved") {
        dispatch(increaseCountOrdersWithoutReview())
      }
    })
    //TODO
    // channel?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
    //   console.log("%c event: ", "font-size: 16px; font-weight: 700; color: red; ", event, "")
    // Если сообщение еще не было прочитанным, то уменьшаем
    // dispatch(decreaseCountUnreadMessages())
    // })

    return () => {
      channel.stopListening(`.changePublicUnreadMessages`)
      channel.stopListening(`.resolvePublicOrder`)
      window.Echo?.leave(`privateUser.${user.id}`)
    }
  }, [user?.user?.id])

  if (openModal && isUserRequired) return <AuthModal2 />

  return (
    <div className="wrapper">
      <Header isMobHidden={isHeaderMobHidden} />
      <main className={clsx(mainClass, !isDesktop && "layout__footer-padding")}>
        {isDesktop && !isAsideHidden && (
          <aside className={clsx("main-aside", asideClass)}>
            <div className={"main-aside__inner"}>
              {aside ? aside : user.token ? <OrdersLine /> : <OrdersLineLoader />}
            </div>
          </aside>
        )}
        <div className={clsx("main-content", isAsideHidden && "main-content--full")}>
          {isUserRequired ? <>{user?.user?.id && children}</> : children}
        </div>
      </main>
      {isDesktop && <Footer />}
      {!isDesktop && !mobileMenuIsHidden && <MobileMenu />}
      {user.token && !isDesktop && mobileMoreModalIsOpen && <MobileMoreModal />}
      {!isDesktop && langModalIsOpen && <LanguageModal isOpen={langModalIsOpen} />}
      {messagesModalIsOpen && (
        <MessagesModal
          open={messagesModalIsOpen}
          setOpen={(res) => {
            if (res !== undefined) dispatch(setMessagesModalIsOpen(res))
          }}
        />
      )}
    </div>
  )
}

export default Layout
