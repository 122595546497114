export const ExitIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3901 3.354L9.60923 3.9787L11.626 6.49968H6.66633C6.39019 6.49968 6.16633 6.72354 6.16633 6.99968C6.16633 7.27583 6.39019 7.49968 6.66633 7.49968H11.626L9.60923 10.0207L10.3901 10.6454L13.3066 6.99968L10.3901 3.354Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.83301 2.20345C1.83301 1.5637 2.42542 1.08848 3.04994 1.22726L6.44147 1.98094C6.67024 2.03177 6.83301 2.23468 6.83301 2.46903V4.42148H7.83301V2.46903C7.83301 1.76598 7.34471 1.15726 6.6584 1.00475L3.26687 0.251075C2.01784 -0.0264871 0.833008 0.923953 0.833008 2.20345V11.7969C0.833008 13.0764 2.01784 14.0269 3.26687 13.7493L6.6584 12.9956C7.34471 12.8431 7.83301 12.2344 7.83301 11.5314V9.71084H6.83301V11.5314C6.83301 11.7657 6.67024 11.9686 6.44147 12.0194L3.04994 12.7731C2.42542 12.9119 1.83301 12.4367 1.83301 11.7969V2.20345Z"
      />
    </svg>
  )
}
