// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesSearch_block__acmSA {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);
  padding: 12px 0;
}

.ArticlesSearch_header__h2dvv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 12px;
}

.ArticlesSearch_title__bn554 {
  font-weight: 500;
}

.ArticlesSearch_btn__p0ShQ {
  color: #7A808B;
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  transition: var(--transition);
}
@media (hover: hover) {
  .ArticlesSearch_btn__p0ShQ:hover {
    opacity: 0.7;
  }
}

.ArticlesSearch_item__sKn0\\+ {
  padding: 12px 16px;
}

.ArticlesSearch_noFound__a-kg9 {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticlesSearch/ArticlesSearch.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,mBAAA;EACA,uDAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,uBAAA;EACA,yBAAA;EACA,6BAAA;AACF;AACE;EACE;IACE,YAAA;EACJ;AACF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".block {\r\n  background-color: var(--white);\r\n  border-radius: 12px;\r\n  box-shadow: 0px 5px 9px 0px #0000000D;\r\n  padding: 12px 0;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 0 12px;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.title {\r\n  font-weight: 500;\r\n}\r\n\r\n.btn {\r\n  color: #7A808B;\r\n  font-size: var(--fz-sm);\r\n  line-height: var(--lh-sm);\r\n  transition: var(--transition);\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      opacity: 0.7;\r\n    }\r\n  }\r\n}\r\n\r\n.item {\r\n  padding: 12px 16px;\r\n}\r\n\r\n.noFound {\r\n  padding: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `ArticlesSearch_block__acmSA`,
	"header": `ArticlesSearch_header__h2dvv`,
	"title": `ArticlesSearch_title__bn554`,
	"btn": `ArticlesSearch_btn__p0ShQ`,
	"item": `ArticlesSearch_item__sKn0+`,
	"noFound": `ArticlesSearch_noFound__a-kg9`
};
export default ___CSS_LOADER_EXPORT___;
