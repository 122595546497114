import Layout from "../../components/Layout/Layout"
import RoomLayout from "../../components/RoomLayout/RoomLayout"
import { Outlet } from "react-router-dom"

export default function Room() {
  return (
    <Layout mainClass={"room-main"} isHeaderMobHidden>
      <RoomLayout>
        <Outlet />
      </RoomLayout>
    </Layout>
  )
}
