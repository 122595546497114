import React from "react"
import styles from "../OrderDescripton.module.scss"
import { useTranslation } from "react-i18next"
import FileRow from "./FileRow"
import { getApiUrl } from "../../../utils/getApiUrl"
import { IOrderOptions } from "../../../types/orderTypes"

interface Props {
  el: IOrderOptions
}

const InformationRow: React.FC<Props> = ({ el }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  return (
    <div className={styles["information-row"]}>
      <p>{t("information")}</p>
      <h5>{el.name}</h5>
      <div className={styles["information-row__content"]}>
        <div className={`${styles["info-text"]}`} dangerouslySetInnerHTML={{ __html: el?.description ?? "" }} />
        {!!el?.photos?.length && (
          <div className={styles["photo-block"]}>
            {el?.photos?.map((el: string, kk: number) => (
              <div className={styles["photo-block__preview"]} key={kk}>
                <img src={getApiUrl() + "/public/photo/" + el} alt="" />
              </div>
            ))}
          </div>
        )}
        {!!el?.attachments_info?.length && (
          <div className={styles["file-block"]}>
            <p>{t("attachments")}</p>
            {el?.attachments_info?.map((ell: any, kkk: number) => <FileRow el={ell} key={kkk} />)}
          </div>
        )}
      </div>
    </div>
  )
}

export default InformationRow
