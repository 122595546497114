import React, { useEffect, useRef, useState } from "react"
import { useFormikContext } from "formik"
import OptionsList from "./OptionsList"

interface IConditionList {
  conditionList: any
  elType: string
  elId: string
  onlyDate?: boolean
  maxNumber?: string
  minNumber?: string
  setValidateIds: any
}

interface ICondition {
  add_condition: any
  id: string
  list_items?: any[]
  main_condition: string
  options: any[]
  parentType: string
  type: string
}

const ConditionList: React.FC<IConditionList> = ({
  conditionList,
  elType,
  elId,
  onlyDate,
  maxNumber,
  minNumber,
  setValidateIds,
}) => {
  const { values } = useFormikContext<{ [key: string]: any }>()
  const optionsRef = useRef<HTMLElement>(null)

  const [needConditions, setNeedConditions] = useState<ICondition[]>([])

  const findCommonEl = (arr1: string[], arr2: string[]) => {
    return arr1?.filter((el) => arr2?.includes(el))
  }

  const formatDate = (date: Date): string => {
    return `${date?.getFullYear()}-${(date?.getMonth() + 1).toString().padStart(2, "0")}-${date
      ?.getDate()
      .toString()
      .padStart(2, "0")}`
  }
  const formatDateAndTime = (date: Date): string => {
    return `${date?.getFullYear()}-${(date?.getMonth() + 1).toString().padStart(2, "0")}-${date
      ?.getDate()
      .toString()
      .padStart(2, "0")}T${date?.getHours().toString().padStart(2, "0")}:${date
      ?.getMinutes()
      .toString()
      .padStart(2, "0")}`
  }

  const setConditions = (prev: ICondition[], condition: ICondition): ICondition[] => {
    if (prev?.find((el: ICondition) => el.id === condition.id)) {
      return prev
    } else return [...prev, condition]
  }
  const filterConditions = (prev: ICondition[], condition: ICondition): ICondition[] => {
    if (prev.find((el: ICondition) => el.id === condition.id)) {
      return prev.filter((el: ICondition) => el.id !== condition.id)
    } else return prev
  }

  const findRightCondition = () => {
    if (elType === "boolean") {
      conditionList?.forEach((condition: ICondition) => {
        if (values[elId] === true && condition?.type === "yes")
          setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
        else if (values[elId] === false && condition?.type === "no")
          setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
        else if (values[elId] !== null && condition?.type === "any")
          setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
        else setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        return
      })
    }
    if (elType === "number") {
      if (
        ((minNumber && Number(values[elId]) >= Number(minNumber)) || !minNumber) &&
        ((maxNumber && Number(values[elId]) <= Number(maxNumber)) || !maxNumber)
      ) {
        conditionList?.forEach((condition: ICondition) => {
          if (condition?.type === "equals") {
            if (Number(values[elId]) === Number(condition?.main_condition))
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "noequals") {
            if (values[elId] && Number(values[elId]) !== Number(condition?.main_condition))
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "more") {
            if (Number(values[elId]) > Number(condition?.main_condition))
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "less") {
            if (values[elId] && Number(values[elId]) < Number(condition?.main_condition))
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "moreorequals") {
            if (values[elId] && Number(values[elId]) >= Number(condition?.main_condition))
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "lessorequals") {
            if (values[elId] && Number(values[elId]) <= Number(condition?.main_condition))
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "between") {
            if (
              values[elId] &&
              Number(values[elId]) >= Number(condition?.main_condition) &&
              Number(values[elId]) <= Number(condition?.add_condition)
            )
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "nobetween") {
            if (
              (values[elId] && Number(values[elId]) <= Number(condition?.main_condition)) ||
              Number(values[elId]) >= Number(condition?.add_condition)
            )
              setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }

          if (condition?.type === "any") {
            if (values[elId]) setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            else if (needConditions) setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        })
      } else setNeedConditions([])
    }
    if (elType === "list") {
      conditionList?.forEach((condition: any) => {
        if (condition?.type === "inlist" || condition?.type === "include") {
          const resultOfFindCommonEl = findCommonEl(values[elId], condition?.list_items?.map((el: any) => el.id))
          resultOfFindCommonEl?.length > 0
            ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            : needConditions && setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
        if (condition?.type === "nolist" || condition?.type === "noinclude") {
          const resultOfFindCommonEl = findCommonEl(values[elId], condition?.list_items?.map((el: any) => el.id))
          values[elId]?.length > 0 && resultOfFindCommonEl.length < 1
            ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            : needConditions && setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
        if (condition?.type === "match") {
          const resultOfFindCommonEl = findCommonEl(values[elId], condition?.list_items?.map((el: any) => el.id))
          values[elId]?.length > 0 &&
          values[elId]?.length === resultOfFindCommonEl.length &&
          resultOfFindCommonEl.length === condition?.list_items.length
            ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            : needConditions && setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
        if (condition?.type === "notmach") {
          const resultOfFindCommonEl = findCommonEl(values[elId], condition?.list_items?.map((el: any) => el.id))

          resultOfFindCommonEl?.length === condition?.list_items?.length &&
          condition?.list_items?.length === values[elId].length
            ? setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
            : values[elId]?.length > 0
            ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
            : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
        if (condition?.type === "any") {
          if (values[elId]?.length > 0) setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else if (needConditions) {
            setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
      })
    }
    if (elType === "photo") {
      conditionList?.forEach((condition: any) => {
        if (condition?.type === "append") {
          if (values[elId]?.length > 0) setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
      })
    }
    if (elType === "text") {
      conditionList?.forEach((condition: any) => {
        if (condition?.type === "include") {
          if (values[elId]?.toLowerCase().includes(condition?.main_condition?.toLowerCase()))
            setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else if (needConditions) {
            setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "noinclude") {
          if (values[elId] && !values[elId]?.toLowerCase().includes(condition?.main_condition?.toLowerCase()))
            setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else if (needConditions) {
            setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "any") {
          if (values[elId]) setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else if (needConditions) {
            setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
      })
    }
    if (elType === "checkbox") {
      conditionList?.forEach((condition: any) => {
        if (condition?.type === "selected") {
          if (values[elId] === true) setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
      })
    }
    if (elType === "datetime") {
      let valuesDate: string
      if (onlyDate) {
        valuesDate = values[elId]
      } else {
        const date = new Date(Number(values[elId]))
        valuesDate = formatDateAndTime(date)
      }

      conditionList?.forEach((condition: any, i: number) => {
        const conditionDate = new Date(Number(condition?.main_condition * 1000))
        const addConditionDate = condition?.add_condition && new Date(Number(condition?.add_condition * 1000))
        let formattedConditionDate = ""
        let formattedAddConditionDate = ""

        if (onlyDate) {
          formattedConditionDate = formatDate(conditionDate)
          if (addConditionDate) formattedAddConditionDate = formatDate(addConditionDate)
        } else {
          formattedConditionDate = formatDateAndTime(conditionDate)
          if (addConditionDate) formattedAddConditionDate = formatDateAndTime(addConditionDate)
        }
        if (condition?.type === "equals") {
          if (valuesDate && formattedConditionDate) {
            valuesDate === formattedConditionDate
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "noequals") {
          if (valuesDate && formattedConditionDate) {
            valuesDate !== formattedConditionDate
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "more") {
          if (valuesDate && formattedConditionDate) {
            new Date(valuesDate).getTime() > new Date(formattedConditionDate).getTime()
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "less") {
          if (valuesDate && formattedConditionDate) {
            new Date(valuesDate).getTime() < new Date(formattedConditionDate).getTime()
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "moreorequals") {
          if (valuesDate && formattedConditionDate) {
            new Date(valuesDate).getTime() >= new Date(formattedConditionDate).getTime()
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "lessorequals") {
          if (valuesDate && formattedConditionDate) {
            new Date(valuesDate).getTime() <= new Date(formattedConditionDate).getTime()
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "between") {
          if (valuesDate && formattedConditionDate && formattedAddConditionDate) {
            new Date(valuesDate).getTime() >= new Date(formattedConditionDate).getTime() &&
            new Date(valuesDate).getTime() <= new Date(formattedAddConditionDate).getTime()
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "nobetween") {
          if (valuesDate && formattedConditionDate && formattedAddConditionDate) {
            new Date(valuesDate).getTime() <= new Date(formattedConditionDate).getTime() ||
            new Date(valuesDate).getTime() >= new Date(formattedAddConditionDate).getTime()
              ? setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
              : setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
          }
        }
        if (condition?.type === "any") {
          if (values[elId]) setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
      })
    }

    if (elType === "attachment") {
      conditionList?.forEach((condition: any) => {
        if (condition?.type === "appendAttach") {
          if (values[elId]?.length > 0) setNeedConditions((prev: ICondition[]) => setConditions(prev, condition))
          else setNeedConditions((prev: ICondition[]) => filterConditions(prev, condition))
        }
      })
    }
  }

  useEffect(() => {
    findRightCondition()
  }, [values])

  return (
    <>
      {needConditions &&
        needConditions.map((condition: any, i) => (
          <OptionsList
            optionsRef={i === 0 ? optionsRef : null}
            key={condition?.id}
            optionsItems={condition?.options}
            setValidateIds={setValidateIds}
          />
        ))}
    </>
  )
}

export default ConditionList
