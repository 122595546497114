import React, { useEffect, useMemo, useState } from "react"
import Modal from "../../Modal/Modal"
import styles from "./SelectObjModal.module.scss"
import objModStyles from "../OrderModal/OrderModal.module.scss"
import Search from "../../Search/Search"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import { TechnicalObject } from "../../../types/orderTypes"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { useLazyGetChildObjectsQuery } from "../../../redux/api/main"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import Button from "../../Assets/Button/Button"

interface Props {
  open: any
  setOpen: any
  techObjects: TechnicalObject[]
  setBackModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
  prodModalInfo?: any
}

const SelectObjModal: React.FC<Props> = ({ open, setOpen, techObjects, setBackModal, isLoading, prodModalInfo }) => {
  const [searchVal, setSearchVal] = useState("")

  const [childObjcts, setChildObjcts] = useState([])
  // Загрузка родительских элементов
  const [loadChildrens, setloadChildrens] = useState(false)

  const { values, setFieldValue } = useFormikContext<{ [key: string]: any }>()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [getChildObjects] = useLazyGetChildObjectsQuery()
  // Переходы по родительскому дереву
  const [objTree, setObjTree] = useState<any>([])

  useEffect(() => {
    if (!open) {
      setChildObjcts([])
      setObjTree([])
      setSearchVal("")
    }
  }, [open])

  const backStepOrder = () => {
    setOpen(false)
    setSearchVal("")
  }

  const nextStepOrder = () => {
    if (values.technical_object_id) {
      setBackModal(true)
      setOpen(false)
    }
  }

  const getChildList = (parentId: string) => {
    setloadChildrens(true)
    getChildObjects({ serviceId: values?.service_id ?? prodModalInfo?.id, parentId, keywords: searchVal })
      .then((result: any) => {
        setloadChildrens(false)
        setChildObjcts(result?.data)
      })
      .catch((err) => {
        setloadChildrens(false), console.log(err)
      })
  }

  const loadArr = ["", "", "", "", ""]

  // Поиск
  useEffect(() => {
    if (typeof searchVal === "string" && searchVal.trim().length > 0) {
      const timeoutId = setTimeout(() => {
        getChildList("")
        setChildObjcts([])
        setObjTree([])
      }, 500)

      return () => clearTimeout(timeoutId)
    }
  }, [searchVal])

  // При поиске\переходе внутрь объекта выводить локальный список
  const renderList = objTree?.length || searchVal ? childObjcts : techObjects

  // const techObjectsList = useMemo(
  //   () => techObjects.filter(({ name }: any) => name?.toLowerCase().includes(String(searchVal).toLowerCase())),
  //   [searchVal, techObjects],
  // )

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={setOpen}
      className={styles["select-obj"]}
      header={<h2 className={"modal__title"}>{t("selectObj")}</h2>}
      name={"SelectObjModal"}
    >
      <p className={styles["select-obj__text"]}>{t("mustSelectObj")}</p>
      <div className={styles.selectObjModal__main}>
        <Search searchVal={searchVal} setSearchVal={setSearchVal} />
        {objTree.length ? (
          <div
            className={`${objModStyles.orderModal__object}`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setFieldValue("technical_object_name", objTree?.[objTree?.length - 1]?.name)
              setFieldValue("technical_object_id", objTree?.[objTree?.length - 1]?.id).then(() => {
                setBackModal(true)
                setOpen(false)
              })
            }}
          >
            <div className={`${objModStyles.orderModal__objectIco}`}>
              <img src="/img/object_ico.svg" alt="" />
            </div>
            <div className={`${objModStyles.orderModal__objectText}`}>
              <span>{t("selectThisObj")}</span>
              <p>{objTree?.[objTree?.length - 1]?.name}</p>
            </div>
            <div className={`${objModStyles.orderModal__objectArrow}`}>
              <img src="/img/arrow_right.svg" alt="" />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={styles.selectObjModal__list}>
          {loadChildrens ? (
            loadArr.map((el, kk: number) => (
              <div key={kk} className={`${objModStyles.orderModal__sk5}`}>
                <span className={`selectBack`} />
                <div className={`selectBack`} />
              </div>
            ))
          ) : renderList.length ? (
            renderList?.map((el: TechnicalObject, kk: number) => (
              <RadioRow
                key={kk}
                name="name"
                title={el.name}
                onChange={() => {
                  setFieldValue("technical_object_name", el.name)
                  setFieldValue("technical_object_id", el.id)
                }}
                value={el.id}
                checked={values.technical_object_id === el.id}
                withChildrens={!!el?.children_count && !searchVal}
                withChildrensFcn={() => {
                  setObjTree((pr: any) => [...pr, el])
                  getChildList(el.id)
                  setFieldValue("technical_object_name", "")
                  setFieldValue("technical_object_id", "")
                }}
              />
            ))
          ) : (
            <p className={styles.nothingContent}>{t("searchServiceNotFound")}</p>
          )}
        </div>
      </div>
      <div className={`modal-bottom ${styles.selectObjModal__bottom}`}>
        <button
          type="button"
          className={`btn btn_grey btn_sm`}
          onClick={() => {
            if (objTree?.length) {
              setObjTree((pr: any) => {
                const arrDelLast = pr.slice(0, pr.length - 1)
                if (arrDelLast?.length) {
                  getChildList(arrDelLast?.[arrDelLast?.length - 1]?.id)
                }
                return [...arrDelLast]
              })
            } else {
              backStepOrder()
            }
          }}
        >
          {t("back")}
        </button>
        <Button
          txt={t("next")}
          className={styles.btnAuth}
          onClick={nextStepOrder}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>
    </ModalPortal>
  )
}

export default SelectObjModal
