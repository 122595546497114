import Layout from "../../components/Layout/Layout"
import ArticlesAside from "../../components/Articles/ArticlesAside/ArticlesAside"
import Article from "../../components/Articles/Article/Article"
import { useNavigate, useParams } from "react-router-dom"
import { selectAuth } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"

export default function ArticlePage() {
  const { id } = useParams()
  const user = useAppSelector(selectAuth)

  const navigate = useNavigate()

  if (!id) navigate("/")

  return (
    <Layout isHeaderMobHidden={user.token} mainClass={"main--md"} aside={<ArticlesAside />} isUserRequired={false}>
      {id && <Article id={id} />}
    </Layout>
  )
}
