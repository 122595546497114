// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessagesModalUsers_drop__3LXy4 {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  width: 256px;
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 4px;
  padding: 8px;
  z-index: 2;
}
.MessagesModalUsers_drop__3LXy4 .ScrollbarsCustom-TrackY {
  top: 0;
  height: 100%;
}
.MessagesModalUsers_drop__3LXy4 .employees__grid {
  display: block;
}
.MessagesModalUsers_drop__3LXy4 .searchBlock {
  margin-bottom: 4px;
}

.MessagesModalUsers_search__f7jRR {
  margin-bottom: 4px;
}
.MessagesModalUsers_search__f7jRR input {
  padding-left: 6px;
}

.MessagesModalUsers_item__O7RCQ {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-100);
  width: 100%;
  transition: var(--transition);
  padding: 6px 4px;
}
@media (hover: hover) {
  .MessagesModalUsers_item__O7RCQ:hover {
    background-color: var(--accent-color);
    color: var(--white);
  }
}
.MessagesModalUsers_item__O7RCQ:last-child {
  border: none;
}

.MessagesModalUsers_img__QZMUU {
  margin-right: 8px;
}

.MessagesModalUsers_itemTxt__iu\\+AH {
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/MessagesModal/MessagesModalUsers.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,OAAA;EACA,qBAAA;EACA,YAAA;EACA,8BAAA;EACA,iCAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AACF;AAEI;EACE,MAAA;EACA,YAAA;AAAN;AAGI;EACE,cAAA;AADN;AAII;EACE,kBAAA;AAFN;;AAOA;EACE,kBAAA;AAJF;AAME;EACE,iBAAA;AAJJ;;AAQA;EACE,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,WAAA;EACA,6BAAA;EACA,gBAAA;AALF;AAOE;EACE;IACE,qCAAA;IACA,mBAAA;EALJ;AACF;AAQE;EACE,YAAA;AANJ;;AAUA;EACE,iBAAA;AAPF;;AAUA;EACE,uBAAA;EACA,yBAAA;AAPF","sourcesContent":[".drop {\r\n  position: absolute;\r\n  left: 0;\r\n  top: calc(100% + 4px);\r\n  width: 256px;\r\n  background-color: var(--white);\r\n  border: 1px solid var(--gray-100);\r\n  border-radius: 4px;\r\n  padding: 8px;\r\n  z-index: 2;\r\n\r\n  :global {\r\n    .ScrollbarsCustom-TrackY {\r\n      top: 0;\r\n      height: 100%;\r\n    }\r\n\r\n    .employees__grid {\r\n      display: block;\r\n    }\r\n\r\n    .searchBlock {\r\n      margin-bottom: 4px;\r\n    }\r\n  }\r\n}\r\n\r\n.search {\r\n  margin-bottom: 4px;\r\n\r\n  input {\r\n    padding-left: 6px;\r\n  }\r\n}\r\n\r\n.item {\r\n  display: flex;\r\n  align-items: center;\r\n  border-bottom: 1px solid var(--gray-100);\r\n  width: 100%;\r\n  transition: var(--transition);\r\n  padding: 6px 4px;\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      background-color: var(--accent-color);\r\n      color: var(--white);\r\n    }\r\n  }\r\n\r\n  &:last-child {\r\n    border: none;\r\n  }\r\n}\r\n\r\n.img {\r\n  margin-right: 8px;\r\n}\r\n\r\n.itemTxt {\r\n  font-size: var(--fz-sm);\r\n  line-height: var(--lh-sm);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop": `MessagesModalUsers_drop__3LXy4`,
	"search": `MessagesModalUsers_search__f7jRR`,
	"item": `MessagesModalUsers_item__O7RCQ`,
	"img": `MessagesModalUsers_img__QZMUU`,
	"itemTxt": `MessagesModalUsers_itemTxt__iu+AH`
};
export default ___CSS_LOADER_EXPORT___;
