import Layout from "../components/Layout/Layout"
import Employees from "../components/Employees/Employees"

export default function EmployeesPage() {
  return (
    <Layout isHeaderMobHidden>
      <Employees />
    </Layout>
  )
}
