export const BookOpenIconStroke = () => {
  return (
    <svg
      className={"stroke-icon"}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 2.4165V12.4165" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M15.083 2.4165V12.4165" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M0.916992 2.4165V12.4165" strokeWidth="1.2" strokeLinecap="round" />
      <path
        d="M15.0833 12.4167C15.0833 12.4167 14.25 10.75 11.3333 10.75C8.41667 10.75 8 12.4167 8 12.4167"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M0.916667 12.4167C0.916667 12.4167 1.75 10.75 4.66667 10.75C7.58333 10.75 8 12.4167 8 12.4167"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M15.0833 2.41667C15.0833 2.41667 14.25 0.75 11.3333 0.75C8.41667 0.75 8 2.41667 8 2.41667"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M0.916667 2.41667C0.916667 2.41667 1.75 0.75 4.66667 0.75C7.58333 0.75 8 2.41667 8 2.41667"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}
