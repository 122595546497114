// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar_avatar__T6ji- {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Avatar_avatar--is-empty__qJfXP {
  color: var(--accent-text-color);
  background-color: rgba(var(--accent-color-rgb), 0.3);
}
.Avatar_avatar--xs__JoQS7 {
  width: 22px;
  height: 22px;
}
.Avatar_avatar--sm__sYPpl {
  width: 24px;
  height: 24px;
}
.Avatar_avatar--md__XV\\+PJ {
  width: 34px;
  height: 34px;
}
.Avatar_avatar--lg__OHwvE {
  width: 52px;
  height: 52px;
}
.Avatar_avatar__T6ji- img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/Avatar/Avatar.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,+BAAA;EACA,oDAAA;AACJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;AAHJ;AAME;EACE,WAAA;EACA,YAAA;AAJJ;AAOE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,sBAAA;AALJ","sourcesContent":[".avatar {\r\n  flex-shrink: 0;\r\n  width: 40px;\r\n  height: 40px;\r\n  border-radius: 50%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n  &--is-empty {\r\n    color: var(--accent-text-color);\r\n    background-color: rgba(var(--accent-color-rgb), 0.3);\r\n    //background-color: #B9EBCC;\r\n  }\r\n\r\n  &--xs {\r\n    width: 22px;\r\n    height: 22px;\r\n  }\r\n\r\n  &--sm {\r\n    width: 24px;\r\n    height: 24px;\r\n  }\r\n\r\n  &--md {\r\n    width: 34px;\r\n    height: 34px;\r\n  }\r\n\r\n  &--lg {\r\n    width: 52px;\r\n    height: 52px;\r\n  }\r\n\r\n  img {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n    object-position: center;\r\n    border-radius: inherit;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `Avatar_avatar__T6ji-`,
	"avatar--is-empty": `Avatar_avatar--is-empty__qJfXP`,
	"avatar--xs": `Avatar_avatar--xs__JoQS7`,
	"avatar--sm": `Avatar_avatar--sm__sYPpl`,
	"avatar--md": `Avatar_avatar--md__XV+PJ`,
	"avatar--lg": `Avatar_avatar--lg__OHwvE`
};
export default ___CSS_LOADER_EXPORT___;
