export const UserIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.3335C6.89543 4.3335 6 5.22893 6 6.3335C6 7.43807 6.89543 8.3335 8 8.3335C9.10457 8.3335 10 7.43807 10 6.3335C10 5.22893 9.10457 4.3335 8 4.3335ZM5 6.3335C5 4.67664 6.34315 3.3335 8 3.3335C9.65685 3.3335 11 4.67664 11 6.3335C11 7.99035 9.65685 9.3335 8 9.3335C6.34315 9.3335 5 7.99035 5 6.3335Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58621 10.9356C5.57494 10.3249 6.77482 10 8.00014 10C9.22545 10 10.4253 10.3249 11.4141 10.9356C12.4032 11.5466 13.1375 12.4179 13.4745 13.4303C13.5618 13.6923 13.4201 13.9754 13.1581 14.0627C12.896 14.1499 12.6129 14.0082 12.5257 13.7462C12.2729 12.9867 11.7074 12.2922 10.8886 11.7864C10.0693 11.2804 9.05382 11 8.00014 11C6.94645 11 5.93102 11.2804 5.1117 11.7864C4.29283 12.2922 3.72737 12.9867 3.47454 13.7462C3.38732 14.0082 3.10422 14.1499 2.84221 14.0627C2.58021 13.9754 2.43851 13.6923 2.52573 13.4303C2.86275 12.4179 3.59704 11.5466 4.58621 10.9356Z"
      />
    </svg>
  )
}
