export const InfoIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.26119 15.1559C8.32142 15.3217 7.35821 15.3006 6.42656 15.0941C5.4949 14.8876 4.61306 14.4995 3.83136 13.9522C3.04967 13.4048 2.38344 12.7089 1.8707 11.9041C1.35797 11.0992 1.00879 10.2013 0.843079 9.26152C0.677371 8.32175 0.698388 7.35853 0.90493 6.42688C1.11147 5.49523 1.49949 4.61338 2.04684 3.83169C2.59419 3.04999 3.29015 2.38376 4.09497 1.87103C4.8998 1.3583 5.79773 1.00911 6.7375 0.843405C7.67728 0.677697 8.64049 0.698714 9.57214 0.905257C10.5038 1.1118 11.3856 1.49982 12.1673 2.04717C12.949 2.59452 13.6153 3.29047 14.128 4.0953C14.6407 4.90012 14.9899 5.79806 15.1556 6.73783C15.3213 7.67761 15.3003 8.64082 15.0938 9.57247C14.8872 10.5041 14.4992 11.386 13.9519 12.1677C13.4045 12.9494 12.7086 13.6156 11.9037 14.1283C11.0989 14.641 10.201 14.9902 9.26119 15.1559L9.26119 15.1559Z"
        stroke="#4B5058"
        strokeWidth="1.2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M8 8L8 12" stroke="#4B5058" strokeWidth="1.2" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M8 4.66699L8 4.00033" stroke="#4B5058" strokeWidth="1.2" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
  )
}
