export const GlobeLightIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00033 1.1665C3.33095 1.1665 1.16699 3.33046 1.16699 5.99984C1.16699 8.66921 3.33095 10.8332 6.00033 10.8332C8.6697 10.8332 10.8337 8.66921 10.8337 5.99984C10.8337 3.33046 8.6697 1.1665 6.00033 1.1665ZM0.166992 5.99984C0.166992 2.77818 2.77866 0.166504 6.00033 0.166504C9.22199 0.166504 11.8337 2.77818 11.8337 5.99984C11.8337 9.2215 9.22199 11.8332 6.00033 11.8332C2.77866 11.8332 0.166992 9.2215 0.166992 5.99984Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05395 2.40416C4.71801 3.30001 4.5 4.57116 4.5 5.99984C4.5 7.42851 4.71801 8.69966 5.05395 9.59551C5.22261 10.0453 5.41139 10.3745 5.59564 10.5823C5.78041 10.7907 5.9182 10.8332 6 10.8332C6.0818 10.8332 6.21959 10.7907 6.40436 10.5823C6.58861 10.3745 6.77739 10.0453 6.94605 9.59551C7.28199 8.69966 7.5 7.42851 7.5 5.99984C7.5 4.57116 7.28199 3.30001 6.94605 2.40416C6.77739 1.95439 6.58861 1.62515 6.40436 1.41733C6.21959 1.20892 6.0818 1.1665 6 1.1665C5.9182 1.1665 5.78041 1.20892 5.59564 1.41733C5.41139 1.62515 5.22261 1.95439 5.05395 2.40416ZM4.84738 0.75392C5.14116 0.42256 5.52952 0.166504 6 0.166504C6.47048 0.166504 6.85884 0.42256 7.15262 0.75392C7.44692 1.08586 7.68911 1.53767 7.88238 2.05304C8.27029 3.08748 8.5 4.48299 8.5 5.99984C8.5 7.51668 8.27029 8.9122 7.88238 9.94664C7.68911 10.462 7.44692 10.9138 7.15262 11.2458C6.85884 11.5771 6.47048 11.8332 6 11.8332C5.52952 11.8332 5.14116 11.5771 4.84738 11.2458C4.55308 10.9138 4.31089 10.462 4.11762 9.94664C3.72971 8.9122 3.5 7.51668 3.5 5.99984C3.5 4.48299 3.72971 3.08748 4.11762 2.05304C4.31089 1.53767 4.55308 1.08586 4.84738 0.75392Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 6C0.5 5.72386 0.723858 5.5 1 5.5H11C11.2761 5.5 11.5 5.72386 11.5 6C11.5 6.27614 11.2761 6.5 11 6.5H1C0.723858 6.5 0.5 6.27614 0.5 6Z"
      />
    </svg>
  )
}
