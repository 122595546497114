// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderService_link__YmmQP {
  background-color: var(--white);
  border-radius: 42px;
  box-shadow: -8px 0px 24px 0px rgba(0, 0, 0, 0.0901960784);
  font-size: var(--fz-lg);
  line-height: var(--lh-xlg);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  margin-bottom: 16px;
}
.OrderService_link__YmmQP svg {
  flex-shrink: 0;
  margin-right: 10px;
}

.OrderService_linkLoader__csH6F {
  width: 170px;
  height: 28px;
  border-radius: 42px;
  margin-bottom: 16px;
}

.OrderService_bodyLoading__4bxJi {
  height: 400px;
  border-radius: 12px;
  margin-bottom: 16px;
}

.OrderService_main__LkR6y {
  margin-bottom: 16px;
}

.OrderService_header__Q8zYl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.OrderService_title__pVCbS {
  padding-right: 10px;
}

.OrderService_description__bkWcp {
  font-size: var(--fz-lg);
  line-height: 23px;
  margin-bottom: 12px;
}

.OrderService_selectObject__QVR8f {
  margin-bottom: 24px;
}

.OrderService_footer__4pc7f {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 8px;
}

.OrderService_submit__9obRO {
  width: 204px;
}`, "",{"version":3,"sources":["webpack://./src/components/OrderService/OrderService.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,mBAAA;EACA,yDAAA;EACA,uBAAA;EACA,0BAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;AACJ;;AAGA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,uBAAA;EACA,iBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;;AAGA;EACE,YAAA;AAAF","sourcesContent":[".link {\r\n  background-color: var(--white);\r\n  border-radius: 42px;\r\n  box-shadow: -8px 0px 24px 0px #00000017;\r\n  font-size: var(--fz-lg);\r\n  line-height: var(--lh-xlg);\r\n  display: inline-flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 4px 12px;\r\n  margin-bottom: 16px;\r\n\r\n  svg {\r\n    flex-shrink: 0;\r\n    margin-right: 10px;\r\n  }\r\n}\r\n\r\n.linkLoader {\r\n  width: 170px;\r\n  height: 28px;\r\n  border-radius: 42px;\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.bodyLoading {\r\n  height: 400px;\r\n  border-radius: 12px;\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.main {\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 4px;\r\n}\r\n\r\n.title {\r\n  padding-right: 10px;\r\n}\r\n\r\n.description {\r\n  font-size: var(--fz-lg);\r\n  line-height: 23px;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.selectObject {\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.footer {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  align-items: center;\r\n  grid-gap: 8px;\r\n}\r\n\r\n.submit {\r\n  width: 204px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `OrderService_link__YmmQP`,
	"linkLoader": `OrderService_linkLoader__csH6F`,
	"bodyLoading": `OrderService_bodyLoading__4bxJi`,
	"main": `OrderService_main__LkR6y`,
	"header": `OrderService_header__Q8zYl`,
	"title": `OrderService_title__pVCbS`,
	"description": `OrderService_description__bkWcp`,
	"selectObject": `OrderService_selectObject__QVR8f`,
	"footer": `OrderService_footer__4pc7f`,
	"submit": `OrderService_submit__9obRO`
};
export default ___CSS_LOADER_EXPORT___;
