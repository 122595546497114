import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { IChatInfo } from "../../types/content"

export interface modalSliceState {
  messagesModalIsOpen: boolean
  messagesModalFirstChat: IChatInfo | null
}

const initialState: modalSliceState = {
  messagesModalIsOpen: false,
  messagesModalFirstChat: null,
}

export const ModalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setMessagesModalIsOpen: (state: modalSliceState, action: PayloadAction<boolean>) => {
      state.messagesModalIsOpen = action.payload
    },
    setMessagesModalFirstChat: (state: modalSliceState, action: PayloadAction<IChatInfo | null>) => {
      state.messagesModalFirstChat = action.payload
    },
  },
})

export const { setMessagesModalIsOpen, setMessagesModalFirstChat } = ModalSlice.actions

export const getMessagesModal = (state: RootState) => state[ModalSlice.name].messagesModalIsOpen
export const getMessagesModalFirstChat = (state: RootState) => state[ModalSlice.name].messagesModalFirstChat
