import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import React from 'react'

interface Props {
  wrapperClasses?: string
  items: any
}

const ImagesGallery = React.forwardRef(({ wrapperClasses = "", items, ...restProps }:any, ref: any) => (
  // ----- Local state -----

  <div className={wrapperClasses}>
    <ImageGallery items={items} ref={ref} {...restProps} showPlayButton={false} />
  </div>
))

ImagesGallery.displayName = 'ImagesGallery';

export default ImagesGallery
