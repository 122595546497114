export const TreeDownIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 7.5C7.67919 7.5 8.5 8.32081 8.5 9.33333C8.5 10.3459 7.67919 11.1667 6.66667 11.1667C5.65414 11.1667 4.83333 10.3459 4.83333 9.33333C4.83333 8.32081 5.65414 7.5 6.66667 7.5ZM7.5 9.33333C7.5 8.8731 7.1269 8.5 6.66667 8.5C6.20643 8.5 5.83333 8.8731 5.83333 9.33333C5.83333 9.79357 6.20643 10.1667 6.66667 10.1667C7.1269 10.1667 7.5 9.79357 7.5 9.33333Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 11.834C9.67919 11.834 10.5 12.6548 10.5 13.6673C10.5 14.6798 9.67919 15.5007 8.66667 15.5007C7.65414 15.5007 6.83333 14.6798 6.83333 13.6673C6.83333 12.6548 7.65414 11.834 8.66667 11.834ZM9.5 13.6673C9.5 13.2071 9.1269 12.834 8.66667 12.834C8.20643 12.834 7.83333 13.2071 7.83333 13.6673C7.83333 14.1276 8.20643 14.5007 8.66667 14.5007C9.1269 14.5007 9.5 14.1276 9.5 13.6673Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33399 4.83398C1.61013 4.83398 1.83399 5.05784 1.83399 5.33398V9.66732C1.83399 10.6243 1.83505 11.2917 1.90275 11.7952C1.96852 12.2844 2.08882 12.5435 2.27332 12.728C2.45783 12.9125 2.71688 13.0328 3.20607 13.0986C3.70963 13.1663 4.37704 13.1673 5.33399 13.1673H7.33399C7.61013 13.1673 7.83399 13.3912 7.83399 13.6673C7.83399 13.9435 7.61013 14.1673 7.33399 14.1673H5.29742C4.38568 14.1673 3.6508 14.1673 3.07282 14.0896C2.47275 14.009 1.9675 13.8364 1.56622 13.4351C1.16494 13.0338 0.992345 12.5286 0.911667 11.9285C0.83396 11.3505 0.833971 10.6156 0.833985 9.7039L0.833985 5.33398C0.833985 5.05784 1.05784 4.83398 1.33399 4.83398Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.834 6.74321C0.834009 6.71786 0.834017 6.69213 0.834017 6.66602H1.83402C1.83402 7.31819 1.83831 7.54619 1.88513 7.72091C2.02383 8.23855 2.42815 8.64287 2.94579 8.78157C3.12051 8.82839 3.34851 8.83268 4.00068 8.83268H5.33402V9.83268H4.00068C3.97457 9.83268 3.94884 9.83269 3.92349 9.8327C3.37818 9.83288 3.00608 9.833 2.68697 9.7475C1.82424 9.51633 1.15037 8.84246 0.919203 7.97973C0.833698 7.66062 0.833821 7.28852 0.834 6.74321Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0919 6.34165V3.01322L12.0919 3.01322L12.0919 4.63454L9.76352 2.30611C9.56826 2.11085 9.25168 2.11085 9.05641 2.30611C8.86115 2.50137 8.86115 2.81796 9.05641 3.01322L11.3848 5.34165L9.76352 5.34165L9.76352 6.34165L13.0919 6.34165Z"
      />
    </svg>
  )
}
