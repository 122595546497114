export const DeleteCircleIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00143 1.53281C4.70613 1.53281 2.03477 4.20418 2.03477 7.49948C2.03477 10.7948 4.70613 13.4661 8.00143 13.4661C11.2967 13.4661 13.9681 10.7948 13.9681 7.49948C13.9681 4.20418 11.2967 1.53281 8.00143 1.53281ZM0.634766 7.49948C0.634766 3.43098 3.93293 0.132812 8.00143 0.132812C12.0699 0.132812 15.3681 3.43098 15.3681 7.49948C15.3681 11.568 12.0699 14.8661 8.00143 14.8661C3.93293 14.8661 0.634766 11.568 0.634766 7.49948Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30078 7.4998C4.30078 7.11321 4.61418 6.7998 5.00078 6.7998H11.0008C11.3874 6.7998 11.7008 7.11321 11.7008 7.4998C11.7008 7.8864 11.3874 8.1998 11.0008 8.1998H5.00078C4.61418 8.1998 4.30078 7.8864 4.30078 7.4998Z"
      />
    </svg>
  )
}
