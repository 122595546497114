import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IArticle } from "../../types/content"

type IState = {
  favoriteArticles: IArticle[]
}

const initialState: IState = {
  favoriteArticles: [],
}

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setFavoriteArticles: (state: IState, action: PayloadAction<IArticle[]>) => {
      state.favoriteArticles = action?.payload
    },
    toggleFavoriteArticles: (state: IState, action: PayloadAction<IArticle>) => {
      if (!action?.payload) return
      const { id } = action.payload
      if (state.favoriteArticles.find((i) => i.id === id)) {
        state.favoriteArticles = state.favoriteArticles.filter((i) => i.id !== id)
      } else {
        state.favoriteArticles = [...state.favoriteArticles, action.payload]
      }
    },
  },
})

export const { toggleFavoriteArticles, setFavoriteArticles } = articlesSlice.actions

export const selectArticles = (state: any): IState => state[articlesSlice.name]
