import { FC, MouseEvent, ReactNode } from "react"

interface Props {
  className?: string
  href?: string
  isTargetBlank?: boolean
  type?: "button" | "submit" | "reset" | "span"
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>) => void
  children: JSX.Element | JSX.Element[] | ReactNode
  disabled?: boolean
  props?: any
}

const ButtonWrap: FC<Props> = ({
  className,
  href,
  isTargetBlank,
  type = "button",
  onClick,
  children,
  disabled,
  ...props
}) => {
  if (type === "span") {
    return (
      <span
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          if (onClick) onClick(e)
        }}
        className={className}
        {...props}
      >
        {children}
      </span>
    )
  }
  if (href) {
    return (
      <a
        href={href}
        rel={isTargetBlank ? "noreferrer" : undefined}
        target={isTargetBlank ? "_blank" : undefined}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          if (onClick) onClick(e)
        }}
        className={className}
        {...props}
      >
        {children}
      </a>
    )
  }
  return (
    <button
      type={type}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (onClick) onClick(e)
      }}
      className={className}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default ButtonWrap
