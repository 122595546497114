export const CloseSquareIcon = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.2H2C1.55817 1.2 1.2 1.55817 1.2 2V8C1.2 8.44183 1.55817 8.8 2 8.8H10C10.4418 8.8 10.8 8.44183 10.8 8V2C10.8 1.55817 10.4418 1.2 10 1.2ZM2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.895431 10 2 10H10C11.1046 10 12 9.10457 12 8V2C12 0.895431 11.1046 0 10 0H2Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.10039 0.400391V9.60039H6.90039V0.400391H8.10039Z" />
    </svg>
  )
}
