// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreviewFile_block__UApb3 {
  background: #FAFAFA;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  padding: 7px 5px 7px 3px;
  transition: var(--transition);
}
@media (hover: hover) {
  .PreviewFile_block__UApb3:hover {
    border-color: var(--accent-color);
  }
}

.PreviewFile_iconWrap__g0e1P {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.PreviewFile_name__Ftz7m {
  display: block;
  font-size: var(--fz-md);
  line-height: var(--lh-lg);
  margin-bottom: 2px;
}

.PreviewFile_info__doHcS {
  color: #7A808B;
}
.PreviewFile_info__doHcS span {
  color: #6A7CAA;
  flex-shrink: 0;
  margin: 0 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/PreviewFile/PreviewFile.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,6BAAA;EACA,wBAAA;EACA,6BAAA;AACF;AACE;EACE;IACE,iCAAA;EACJ;AACF;;AAGA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,cAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,cAAA;AAAF;AAEE;EACE,cAAA;EACA,cAAA;EACA,aAAA;AAAJ","sourcesContent":[".block {\r\n  background: #FAFAFA;\r\n  border-radius: 6px;\r\n  display: inline-flex;\r\n  align-items: center;\r\n  border: 1px solid transparent;\r\n  padding: 7px 5px 7px 3px;\r\n  transition: var(--transition);\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      border-color: var(--accent-color);\r\n    }\r\n  }\r\n}\r\n\r\n.iconWrap {\r\n  flex-shrink: 0;\r\n  width: 24px;\r\n  height: 24px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-right: 8px;\r\n}\r\n\r\n.name {\r\n  display: block;\r\n  font-size: var(--fz-md);\r\n  line-height: var(--lh-lg);\r\n  margin-bottom: 2px;\r\n}\r\n\r\n.info {\r\n  color: #7A808B;\r\n\r\n  span {\r\n    color: #6A7CAA;\r\n    flex-shrink: 0;\r\n    margin: 0 6px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `PreviewFile_block__UApb3`,
	"iconWrap": `PreviewFile_iconWrap__g0e1P`,
	"name": `PreviewFile_name__Ftz7m`,
	"info": `PreviewFile_info__doHcS`
};
export default ___CSS_LOADER_EXPORT___;
