import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IChatMessage, IServiceItem } from "../../types/content"

type IState = {
  serviceList: IServiceItem[]
  serviceListLoading: boolean
  countOrdersWithoutReview: number
  countUnreadMessages: number
  allMessage: IChatMessage[]
}

const initialState: IState = {
  serviceList: [],
  serviceListLoading: true,
  countOrdersWithoutReview: 0,
  countUnreadMessages: 0,
  allMessage: [],
}

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    updateServiceList: (state: any, action: PayloadAction<IServiceItem[]>) => {
      state.serviceList = action.payload
      state.serviceListLoading = false
    },
    updateCountOrdersWithoutReview: (state: IState, action: PayloadAction<number>) => {
      state.countOrdersWithoutReview = action?.payload
    },
    increaseCountOrdersWithoutReview: (state: IState) => {
      state.countOrdersWithoutReview += 1
    },
    updateCountUnreadMessages: (state: IState, action: PayloadAction<number>) => {
      state.countUnreadMessages = action?.payload
    },
    decreaseCountUnreadMessages: (state: IState) => {
      state.countUnreadMessages -= 1
    },
    setAllMessage: (state: any, action: PayloadAction<any>) => {
      state.allMessage = action.payload
    },
    addAllMessage: (state: IState, action: PayloadAction<IChatMessage>) => {
      state.allMessage = [...state.allMessage.filter((f) => f.id !== action.payload.id), action.payload]
    },
    updateReadMessages: (state: any, action: PayloadAction<any>) => {
      state.allMessage = [
        ...state.allMessage.map((f: any) => (action?.payload?.includes(f.id) ? { ...f, is_readed: true } : f)),
      ]
    },
  },
})

export const {
  updateServiceList,
  updateCountOrdersWithoutReview,
  updateCountUnreadMessages,
  decreaseCountUnreadMessages,
  increaseCountOrdersWithoutReview,
  setAllMessage,
  addAllMessage,
  updateReadMessages,
} = mainSlice.actions
export const countOrdersWithoutReview = (state: any) => state[mainSlice.name].countOrdersWithoutReview
export const countUnreadMessages = (state: any) => state[mainSlice.name].countUnreadMessages
export const selectAllMessage = (state: any) => state[mainSlice.name].allMessage
