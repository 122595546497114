export const ClipIcon = () => {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.166016 3.33301C0.166016 1.9523 1.2853 0.833008 2.66602 0.833008C4.04673 0.833008 5.16602 1.9523 5.16602 3.33301V8.99967C5.16602 9.8281 4.49444 10.4997 3.66602 10.4997C2.83759 10.4997 2.16602 9.8281 2.16602 8.99968V3.33301C2.16602 3.05687 2.38987 2.83301 2.66602 2.83301C2.94216 2.83301 3.16602 3.05687 3.16602 3.33301V8.99968C3.16602 9.27582 3.38987 9.49967 3.66602 9.49967C3.94216 9.49967 4.16602 9.27582 4.16602 8.99967V3.33301C4.16602 2.50458 3.49444 1.83301 2.66602 1.83301C1.83759 1.83301 1.16602 2.50458 1.16602 3.33301V10.333C1.16602 11.7137 2.2853 12.833 3.66602 12.833C5.04673 12.833 6.16602 11.7137 6.16602 10.333V5.33301C6.16602 5.05687 6.38987 4.83301 6.66602 4.83301C6.94216 4.83301 7.16602 5.05687 7.16602 5.33301V10.333C7.16602 12.266 5.59901 13.833 3.66602 13.833C1.73302 13.833 0.166016 12.266 0.166016 10.333V3.33301Z"
      />
    </svg>
  )
}
