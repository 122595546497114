import Layout from "../../../components/Layout/Layout"
import { useNavigate, useParams } from "react-router-dom"
import OrderService from "../../../components/OrderService/OrderService"

export default function OrderServicePage() {
  const { id, productId } = useParams()
  const navigate = useNavigate()

  if (!id || !productId) navigate("/")

  return <Layout isHeaderMobHidden>{productId && id && <OrderService id={productId} categoryId={id} />}</Layout>
}
