export const WorkListIcon = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6998 13.6C1.6998 14.318 2.28184 14.9 2.9998 14.9H11.0098C11.2 14.9 11.3232 14.6939 11.2588 14.515C11.1559 14.2293 11.0998 13.9212 11.0998 13.6V12.95C11.0998 12.8119 10.9879 12.7 10.8498 12.7H1.9498C1.81173 12.7 1.6998 12.8119 1.6998 12.95V13.6ZM2.9998 16.3H13.7998H14.1998H14.5998C16.091 16.3 17.2998 15.0912 17.2998 13.6V2.2C17.2998 1.26112 16.5387 0.5 15.5998 0.5H4.3998C3.46092 0.5 2.6998 1.26112 2.6998 2.2V9C2.6998 9.3866 3.01321 9.7 3.3998 9.7C3.7864 9.7 4.0998 9.3866 4.0998 9V2.2C4.0998 2.03431 4.23412 1.9 4.3998 1.9H15.5998C15.7655 1.9 15.8998 2.03431 15.8998 2.2V13.6C15.8998 14.318 15.3178 14.9 14.5998 14.9H14.1998H13.7998C13.0818 14.9 12.4998 14.318 12.4998 13.6V12.5C12.4998 11.8373 11.9625 11.3 11.2998 11.3H1.49981C0.837065 11.3 0.299805 11.8373 0.299805 12.5V13.6C0.299805 15.0912 1.50864 16.3 2.9998 16.3ZM9.40085 9.38891L13.318 5.47175C13.4156 5.37412 13.4156 5.21583 13.318 5.1182L12.6816 4.4818C12.584 4.38417 12.4257 4.38417 12.3281 4.4818L8.97658 7.83327C8.87895 7.93091 8.72066 7.93091 8.62303 7.83327L7.67156 6.8818C7.57392 6.78417 7.41563 6.78417 7.318 6.8818L6.68161 7.5182C6.58398 7.61583 6.58398 7.77412 6.68161 7.87175L8.19876 9.38891C8.53071 9.72085 9.0689 9.72085 9.40085 9.38891Z"
      />
    </svg>
  )
}
