import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
// import { HYDRATE } from 'next-redux-wrapper'
import { RootState } from "../store"
// import { getCookie } from 'cookies-next'
import { USER_TOKEN_COOKIE } from "../../utils/constants"
import { getCookie } from "../../utils/cookies"
import {getApiUrl} from "../../utils/getApiUrl";

// https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery

/*const endpointsWithoutAuthorization = [
  'login',
  'register',
  'emailVerify',
  'resendEmailVerify',
  'setRole',
  'resetPassword',
  'createLid',
  'getCatalogTeams',
]*/

const endpointsFormData = ["createProject", "updateUser", "sendMessage", "postCreateOrder"]

const endpointsWithoutAuthorization = [
  "login",
  "register",
  "emailVerify",
  "resendEmailVerify",
  "setRole",
  "resetPassword",
  "createLid",
  "getCatalogTeams",
]

const staqBaseQuery = async (args: any, api: any, extraOptions: any) => {
  const defaultResult: any = await fetchBaseQuery({
    baseUrl: `${args.isWeb ? getApiUrl()+'/web/' : getApiUrl()}`,
    credentials: "same-origin",
    prepareHeaders: (headers, { endpoint, ...api }) => {
      const token = (api.getState() as RootState).auth.token || getCookie(USER_TOKEN_COOKIE) || localStorage.getItem("token")
      const currentLang = localStorage.getItem("i18nextLng") || 'en'
      // || getCookie(USER_TOKEN_COOKIE)

      if (token && !endpointsWithoutAuthorization.includes(endpoint)) {
        headers.set("authorization", `Bearer ${token}`)
        headers.set("Accept-Language", currentLang)
      }

      // headers.set('Accept', 'application/json')
      if (!endpointsFormData.includes(endpoint)) {
        headers.set("Content-Type", "application/json")
      }
      return headers
    },
  })(args, api, extraOptions)

  return {
    ...defaultResult,
  }
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["Orders"],
  baseQuery: staqBaseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    // if (action.type === HYDRATE) {
    return action?.payload?.[reducerPath]
    // }
  },
  endpoints: () => ({}),
})
