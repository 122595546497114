import styles from "./MobileMenu.module.scss"
import clsx from "clsx"
import { WorkListIcon } from "../../icons/WorkListIcon"
import { DoubleCheckIcon } from "../../icons/DoubleCheckIcon"
import { SendIcon } from "../../icons/SendIcon"
import { BookOpenIconStroke } from "../../icons/BookOpenIconStroke"
import { ThreeDots } from "../../icons/ThreeDots"
import { useLocation } from "react-router-dom"
import { Fragment, memo } from "react"
import LocalizedLink from "../../hoc/LocalizedLink"
import { useDispatch } from "react-redux"
import { getOpenIsMoreModal, setOpenIsMoreModal } from "../../redux/slice/isMoreModal"
import { useAppSelector } from "../../hooks"
import { getMessagesModal, setMessagesModalIsOpen } from "../../redux/slice/modals"
import { countUnreadMessages } from "../../redux/slice/main"
import { useTranslation } from "react-i18next"

interface IMenuItem {
  name: string
  icon?: JSX.Element
  link?: string
  activeLinks?: string | string[]
  func?: () => void
  strokeIcon?: boolean
  circleIcon?: boolean
  isActive?: boolean
  quantityIndicator?: number
}

const MobileMenu = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const mobileMoreModalIsOpen = useAppSelector(getOpenIsMoreModal)
  const messagesModalIsOpen = useAppSelector(getMessagesModal)
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const isActiveItem = (link: string | string[]): boolean | undefined => {
    if (!link || link.length < 1) return

    if (
      (typeof link === "string" && location.pathname.includes(link)) ||
      (Array.isArray(link) && link.includes(location.pathname))
    )
      return true
  }

  const quantityCountUnreadMessages = useAppSelector(countUnreadMessages)

  const menuList: IMenuItem[] = [
    {
      name: "home",
      icon: <WorkListIcon />,
      link: "/",
      func: () => {
        dispatch(setOpenIsMoreModal(false))
        dispatch(setMessagesModalIsOpen(false))
      },
      isActive: !messagesModalIsOpen && !mobileMoreModalIsOpen && isActiveItem(["/", "/orders", "/history"]),
    },
    // { name: "Согласования", icon: <DoubleCheckIcon /> },
    {
      name: "chats",
      icon: <SendIcon />,
      func: () => {
        dispatch(setMessagesModalIsOpen(true))

        setTimeout(() => {
          dispatch(setOpenIsMoreModal(false))
        }, 80)
      },
      quantityIndicator: quantityCountUnreadMessages,
      isActive: messagesModalIsOpen && !mobileMoreModalIsOpen,
    },
    {
      name: "knowledgeBase",
      icon: <BookOpenIconStroke />,
      link: "/articles",
      activeLinks: "/articles",
      strokeIcon: true,
      isActive: !messagesModalIsOpen && !mobileMoreModalIsOpen && isActiveItem("/articles"),
      func: () => {
        dispatch(setOpenIsMoreModal(false))
        dispatch(setMessagesModalIsOpen(false))
      },
    },
    {
      name: "more",
      icon: <ThreeDots />,
      func: () => {
        dispatch(setOpenIsMoreModal(true))

        setTimeout(() => {
          dispatch(setMessagesModalIsOpen(false))
        }, 80)
      },
      circleIcon: true,
      isActive: !messagesModalIsOpen && mobileMoreModalIsOpen,
    },
  ]

  const MenuItem = ({ el, link }: { el: IMenuItem; link?: boolean }) => {
    return (
      <div
        className={clsx(styles["item"], {
          [styles["is-active"]]: el.isActive,
        })}
        onClick={() => {
          if (link) return
          if (el.func) el.func()
        }}
      >
        <div
          className={clsx(styles["item__icon"], {
            [styles["stroke-icon"]]: el.strokeIcon,
            [styles["circle-icon"]]: el.circleIcon,
          })}
        >
          {el.icon}
          {el.quantityIndicator ? (
            <span
              className={clsx(styles["quantity-indicator"], {
                [styles["quantity-indicator__bigger"]]: el.quantityIndicator > 99,
              })}
            >
              {el.quantityIndicator}
            </span>
          ) : null}
        </div>
        <div className={clsx(styles["item__name"])}>{t(el.name)}</div>
      </div>
    )
  }

  return (
    <div className={clsx(styles["mobile-menu"], "mobile-menu")}>
      {menuList.map((el, i) => (
        <Fragment key={i}>
          {el.link ? (
            <LocalizedLink
              to={el.link}
              className={clsx(styles["item"])}
              onClick={() => {
                if (el.func) el.func()
              }}
            >
              <MenuItem el={el} link />
            </LocalizedLink>
          ) : (
            <MenuItem el={el} />
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default memo(MobileMenu)
