// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchList_searchList__3g6oT {
  margin-top: 24px;
}
@media (min-width: 640px) {
  .SearchList_searchList__3g6oT {
    margin-top: 16px;
  }
}
.SearchList_searchList__title__8--jT {
  margin-bottom: 6px;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  color: #000;
}
@media (min-width: 640px) {
  .SearchList_searchList__title__8--jT {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 12px;
  }
}
@media (min-width: 1200px) {
  .SearchList_searchList__title__8--jT {
    font-size: 16px;
    line-height: 18px;
  }
}
.SearchList_searchList__wp__-buxF {
  margin-top: 14px;
}
@media (min-width: 480px) {
  .SearchList_searchList__wp__-buxF {
    grid-template-columns: 32% 32% 32%;
  }
}

.SearchList_nothingContent__lJFQ5 {
  margin-top: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchList/SearchList.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EAHF;IAII,gBAAA;EAEF;AACF;AAAE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AAEJ;AAAI;EARF;IASI,eAAA;IACA,iBAAA;IACA,mBAAA;EAGJ;AACF;AADI;EAdF;IAeI,eAAA;IACA,iBAAA;EAIJ;AACF;AADE;EACE,gBAAA;AAGJ;AAAE;EACE;IACE,kCAAA;EAEJ;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AACF","sourcesContent":[".searchList {\n  margin-top: 24px;\n\n  @media (min-width: 640px) {\n    margin-top: 16px;\n  }\n\n  &__title {\n    margin-bottom: 6px;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 36px;\n    color: #000;\n\n    @media (min-width: 640px) {\n      font-size: 36px;\n      line-height: 42px;\n      margin-bottom: 12px;\n    }\n\n    @media (min-width: 1200px) {\n      font-size: 16px;\n      line-height: 18px;\n    }\n  }\n\n  &__wp {\n    margin-top: 14px; \n  }\n\n  @media (min-width: 480px) {\n    &__wp {\n      grid-template-columns: 32% 32% 32%;\n    }\n  }\n}\n\n.nothingContent {\n  margin-top: auto;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchList": `SearchList_searchList__3g6oT`,
	"searchList__title": `SearchList_searchList__title__8--jT`,
	"searchList__wp": `SearchList_searchList__wp__-buxF`,
	"nothingContent": `SearchList_nothingContent__lJFQ5`
};
export default ___CSS_LOADER_EXPORT___;
