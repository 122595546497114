import Layout from "../components/Layout/Layout"
import ReviewsLayout from "../components/ReviewsLayout/ReviewsLayout"

export default function ReviewsPage() {
  return (
    <Layout isHeaderMobHidden>
      <ReviewsLayout />
    </Layout>
  )
}
