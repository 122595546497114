export const PlusIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.60039 0.400391V9.60039H4.40039V0.400391H5.60039Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59961 5.60039L0.399609 5.60039L0.399609 4.40039L9.59961 4.40039L9.59961 5.60039Z"
      />
    </svg>
  )
}
