import CountBtn from "../Assets/CountBtn/CountBtn"
import Button from "../Assets/Button/Button"
import Label from "../Assets/Label/Label"
import clsx from "clsx"
import Paper from "../Assets/Paper/Paper"
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { declensionNumber, timeUnitsForLang } from "../../utils/helpers"
import { useFormikContext } from "formik"
import OptionsList from "../Modals/OrderModal/OptionsList"
import SelectObject from "../SelectObject/SelectObject"
import { IOrder } from "../../types/orderTypes"
import styles from "./OrderService.module.scss"

interface Props {
  data: IOrder
  setValidateIds: Dispatch<SetStateAction<any[]>>
  isLoadingSubmit?: boolean
}

const OrderServiceForm = ({ data, isLoadingSubmit, setValidateIds }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const currentLang = localStorage.getItem("i18nextLng") || "en"

  const ref = useRef<HTMLDivElement>(null)
  const [isError, setIsError] = useState(false)

  const setErrorHandler = (val: boolean) => {
    setIsError(val)
  }

  const { setFieldValue, setFieldTouched, isSubmitting, errors, touched, isValidating } = useFormikContext<{
    [key: string]: any
  }>()

  useEffect(() => {
    // Проскролливать при наличии ошибок и окончании сабмиттинга и валидатинга
    if (Object.keys(errors)?.length && !isValidating && !isSubmitting) {
      const errorsEls = ref.current?.querySelector(".error")
      errorsEls?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }, [isSubmitting, ref])

  const labelTime = useMemo(() => {
    let units = timeUnitsForLang["en"]["minutes"]
    if (data?.execution_time_units && currentLang) {
      units =
        timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][
          data.execution_time_units as "minutes" | "hours" | "days"
        ]
    }
    const firstTime = data?.execution_time_from
    const secondTime = data?.execution_time_to
    if (!firstTime && !secondTime) return null
    if (currentLang === "ru" && data.execution_time_units) {
      let arrayUnits: string[] = []

      switch (data.execution_time_units) {
        case "hours": {
          arrayUnits =
            firstTime && secondTime
              ? ["час", "часа", "часов"]
              : (firstTime && !secondTime) || (!firstTime && secondTime)
              ? ["часа", "часов", "часов"]
              : []
          break
        }
        case "days": {
          arrayUnits =
            firstTime && secondTime
              ? ["день", "дня", "дней"]
              : (firstTime && !secondTime) || (!firstTime && secondTime)
              ? ["дня", "дней", "дней"]
              : []
          break
        }
      }
      if (arrayUnits.length) {
        units = declensionNumber(Number(secondTime), arrayUnits)
      }
    }
    let txt = ""
    if (firstTime && secondTime) {
      txt = `${firstTime}-${secondTime} ${units}`
    } else if (firstTime && !secondTime) {
      txt = `${t("from")} ${firstTime} ${units}`
    } else if (!firstTime && secondTime) {
      txt = `${t("to")} ${secondTime} ${units}`
    }
    return <Label txt={txt} className={clsx(styles.productItemNew__time)} mode={"orange"} size={"sm"} />
  }, [data, currentLang])

  return (
    <>
      <Paper
        className={styles.main}
        footer={
          data?.service_action_name && (
            <>
              <div className={styles.footer}>
                {data?.is_multiorder && (
                  <CountBtn
                    onChange={(num) => {
                      void setFieldValue("count", num)
                    }}
                  />
                )}
                <Button
                  isLoading={isLoadingSubmit}
                  className={styles.submit}
                  txt={data?.service_action_name || t("order")}
                  disabled={isLoadingSubmit}
                  type={"submit"}
                />
              </div>
            </>
          )
        }
      >
        <div ref={ref}>
          <header className={styles.header}>
            <h2 className={styles.title}>{data?.name}</h2>
            {labelTime}
          </header>
          {data?.description && (
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: data?.description }} />
          )}
          {data?.technicalObjects?.length > 0 && (
            <SelectObject
              className={styles.selectObject}
              data={data}
              error={touched?.technical_object_id && errors?.technical_object_id}
              callbackSubmit={(el) => {
                if (!el) return
                void setFieldTouched("technical_object_id")
                void setFieldValue("technical_object_id", el.id)
              }}
            />
          )}
          {data?.options && data?.options?.length > 0 && (
            <OptionsList optionsItems={data.options} setError={setErrorHandler} setValidateIds={setValidateIds} />
          )}
        </div>
      </Paper>
    </>
  )
}

export default OrderServiceForm
