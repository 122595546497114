import Layout from "../components/Layout/Layout"
import PageNotFound from "../components/PageNotFound/PageNotFound"

const Page404 = () => {
  return (
    <Layout isHeaderMobHidden isAsideHidden>
      <PageNotFound />
    </Layout>
  )
}

export default Page404
