import { FC } from "react"
import clsx from "clsx"
import TextareaAutosize from "react-textarea-autosize"
import styles from "./TextArea.module.scss"

interface Props {
  className?: string
  autoSize?: boolean
  [key: string]: any
}

const TextArea: FC<Props> = ({ className, autoSize, ...props }) => {
  if (autoSize) return <TextareaAutosize {...props} className={clsx(styles.textarea, className)} />
  return <textarea {...props} className={clsx(styles.textarea, className)} />
}

export default TextArea
