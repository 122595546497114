// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterSupport_support__LxCjl {
  display: none;
  flex-wrap: wrap;
  justify-content: flex-end;
  grid-gap: 4px;
  max-width: 320px;
  font-size: var(--fz-xs);
  line-height: var(--lh-xs);
}
@media (min-width: 1200px) {
  .FooterSupport_support__LxCjl {
    display: flex;
  }
}

.FooterSupport_label__J9H\\+f {
  transition: var(--transition);
  transition-property: color, border;
}
@media (hover: hover) {
  .FooterSupport_label__J9H\\+f:hover {
    color: var(--text-color);
    border-color: var(--gray-500);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/FooterSupport/FooterSupport.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;AACF;AACE;EATF;IAUI,aAAA;EAEF;AACF;;AACA;EACE,6BAAA;EACA,kCAAA;AAEF;AAAE;EACE;IACE,wBAAA;IACA,6BAAA;EAEJ;AACF","sourcesContent":[".support {\r\n  display: none;\r\n  flex-wrap: wrap;\r\n  justify-content: flex-end;\r\n  grid-gap: 4px;\r\n  max-width: 320px;\r\n  font-size: var(--fz-xs);\r\n  line-height: var(--lh-xs);\r\n\r\n  @media (min-width: 1200px) {\r\n    display: flex;\r\n  }\r\n}\r\n\r\n.label {\r\n  transition: var(--transition);\r\n  transition-property: color, border;\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      color: var(--text-color);\r\n      border-color: var(--gray-500);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support": `FooterSupport_support__LxCjl`,
	"label": `FooterSupport_label__J9H+f`
};
export default ___CSS_LOADER_EXPORT___;
