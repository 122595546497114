import { useEffect, useMemo, useState } from "react"
import moment from "moment/moment"
import Review from "../Review/Review"
import ReviewForm from "../Review/ReviewForm/ReviewForm"
import { IOrderWithoutReview, IReview } from "../../types/content"
import { useTranslation } from "react-i18next"
import styles from "./Reviews.module.scss"

const Reviews = ({ reviews, orders }: { reviews?: IReview[]; orders?: IOrderWithoutReview[] }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [reviewsState, setReviewsState] = useState<{
    orders: IOrderWithoutReview[]
    reviews: IReview[]
  }>({
    orders: [],
    reviews: [],
  })

  useEffect(() => {
    setReviewsState({ orders: orders || [], reviews: reviews || [] })
  }, [reviews, orders])

  const reviewsList = useMemo(() => {
    const revArray: {
      date: string
      list: IReview[]
    }[] = []
    const ordArray: {
      date: string
      list: IOrderWithoutReview[]
    }[] = []

    const arr = [reviewsState.reviews, reviewsState.orders]

    arr.forEach((state, index) => {
      state.map((item) => {
        const today = moment().format("DD MMMM YYYY")
        const itemDate = moment(item.created_at * 1000).format("DD MMMM YYYY")
        const date = today === itemDate ? t("today") : itemDate

        if (index === 0) {
          const itemIndexRev = revArray.findIndex((i) => i.date === date)
          if (itemIndexRev >= 0) {
            revArray[itemIndexRev].list.push(item as IReview)
          } else {
            revArray.push({
              date: date,
              list: [item as IReview],
            })
          }
        } else {
          const itemIndexOrd = ordArray.findIndex((i) => i.date === date)
          if (itemIndexOrd >= 0) {
            ordArray[itemIndexOrd].list.push(item as IOrderWithoutReview)
          } else {
            ordArray.push({
              date: date,
              list: [item as IOrderWithoutReview],
            })
          }
        }
      })
    })

    return {
      reviews:
        revArray?.map((item, index) => {
          return (
            <div key={index} className={styles.block}>
              <h4 className={styles.title}>{item.date}</h4>
              <div>
                {item.list.map((review) => (
                  <Review key={review.order_id} {...review} className={styles.item} />
                ))}
              </div>
            </div>
          )
        }) || null,
      orders:
        ordArray?.map((item, index) => {
          return (
            <div key={index} className={styles.block}>
              <h4 className={styles.title}>{item.date}</h4>
              <div>
                {item.list.map((review) => (
                  <ReviewForm {...review} key={review.id} className={styles.item} setReviews={setReviewsState} />
                ))}
              </div>
            </div>
          )
        }) || null,
    }
  }, [reviewsState])

  return (
    <div>
      {reviewsList?.orders}
      {reviewsList?.reviews}
    </div>
  )
}

export default Reviews
