const userBgColors = [
  '#9C40F8',
  '#08A657',
  '#BF3333',
  '#EBEB0E',
  '#F484F4',
  '#F15858',
  '#2FFB2F',
  '#F7A553',
  '#41C061',
  '#EB7D0E',
  '#09A6A6',
  '#EB0EEB',
  '#0EEBEB',
]

export default userBgColors
