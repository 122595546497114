import Layout from "../components/Layout/Layout"
import OrdersLayout from "../components/OrdersLayout/OrdersLayout"

export default function History() {
  return (
    <Layout isHeaderMobHidden>
      <OrdersLayout layout={"history"} />
    </Layout>
  )
}
