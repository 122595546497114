export const ArrowDownIcon = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.35352 1.35348L8.64641 0.646371L4.99996 4.29282L1.35352 0.646372L0.646408 1.35348L4.99996 5.70703L9.35352 1.35348Z"
      />
    </svg>
  )
}
