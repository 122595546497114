// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectObjModal_select-obj__0Gc45 {
  padding: 12px;
  align-items: flex-end;
}
.SelectObjModal_select-obj__0Gc45 .modal__header {
  padding-bottom: 0;
  border-bottom: none;
  min-height: auto;
}
.SelectObjModal_select-obj__0Gc45 .modal__header .modal__title {
  font-size: 22px;
  line-height: 114%;
}
.SelectObjModal_select-obj__0Gc45 .modal__body {
  padding-top: 8px;
}
.SelectObjModal_select-obj__text__kQVgd {
  font-size: var(--fz-sm);
  line-height: 114%;
}

.SelectObjModal_selectObjModal__main__b24XF .searchBlock {
  margin: 16px 0;
}
.SelectObjModal_selectObjModal__main__b24XF .searchBlock input {
  font-size: var(--fz-sm);
}
.SelectObjModal_selectObjModal__list__\\+idTM {
  margin-top: 8px;
  min-height: 340px;
  max-height: 340px;
  overflow: auto;
  position: relative;
}
.SelectObjModal_selectObjModal__bottom__zbuk9 {
  margin-top: 16px;
}

.SelectObjModal_nothingContent__LXs37 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: calc(50% - 7px);
  font-size: var(--fz-lg);
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/SelectObjModal/SelectObjModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;AACF;AAEI;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;AAAN;AAEM;EACE,eAAA;EACA,iBAAA;AAAR;AAII;EACE,gBAAA;AAFN;AAME;EACE,uBAAA;EACA,iBAAA;AAJJ;;AAWM;EACE,cAAA;AARR;AAUQ;EACE,uBAAA;AARV;AAgBE;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AAdJ;AAiBE;EACE,gBAAA;AAfJ;;AAmBA;EACE,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,oBAAA;EACA,uBAAA;AAhBF","sourcesContent":[".select-obj {\n  padding: 12px;\n  align-items: flex-end;\n\n  :global {\n    .modal__header {\n      padding-bottom: 0;\n      border-bottom: none;\n      min-height: auto;\n\n      .modal__title {\n        font-size: 22px;\n        line-height: 114%;\n      }\n    }\n\n    .modal__body {\n      padding-top: 8px;\n    }\n  }\n\n  &__text {\n    font-size: var(--fz-sm);\n    line-height: 114%;\n  }\n}\n\n.selectObjModal {\n  &__main {\n    :global {\n      .searchBlock {\n        margin: 16px 0;\n        \n        input {\n          font-size: var(--fz-sm);\n        }\n      }\n    }\n  }\n\n  \n\n  &__list {\n    margin-top: 8px;\n    min-height: 340px;\n    max-height: 340px;\n    overflow: auto;\n    position: relative;\n  }        \n\n  &__bottom {\n    margin-top: 16px;\n  }\n}\n\n.nothingContent {\n  position: absolute;\n  text-align: center;\n  width: 100%;\n  top: calc(50% - 7px);\n  font-size: var(--fz-lg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-obj": `SelectObjModal_select-obj__0Gc45`,
	"select-obj__text": `SelectObjModal_select-obj__text__kQVgd`,
	"selectObjModal__main": `SelectObjModal_selectObjModal__main__b24XF`,
	"selectObjModal__list": `SelectObjModal_selectObjModal__list__+idTM`,
	"selectObjModal__bottom": `SelectObjModal_selectObjModal__bottom__zbuk9`,
	"nothingContent": `SelectObjModal_nothingContent__LXs37`
};
export default ___CSS_LOADER_EXPORT___;
