import LocalizedLink from "../../hoc/LocalizedLink"
import Paper from "../Assets/Paper/Paper"
import { IServiceOrder } from "../../types/orderTypes"
import styles from "./OrderLayout.module.scss"

const OrderLayoutHeader = ({ order }: { order: IServiceOrder }) => {
  return (
    <Paper className={styles.header}>
      <h2 className={styles.headerTitle}>Заявка #{order.number} успешно оформлена</h2>
      <p className={styles.headerDescription}>Мы получили вашу заявку и уже отправили её ответственному сотруднику</p>
      <LocalizedLink to={`/`} className={styles.headerBtn}>
        На главную
      </LocalizedLink>
    </Paper>
  )
}

export default OrderLayoutHeader
