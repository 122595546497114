import Accordion from "../Assets/Accordion/Accordion"
import moment from "moment"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import { getDateDiff } from "../../utils/getDateDiff"
import { IOrderReview } from "../../types/content"
import StarsRating from "../Assets/StarsRating/StarsRating"
import { getApiUrl } from "../../utils/getApiUrl"
import InformationRow from "./assets/InformationRow"
import useWindowSize from "../../hooks/useWindowSize"
import { PlusIcon } from "../../icons/PlusIcon"
import ScrollBlock from "../Assets/ScrollBlock"
import styles from "./OrderDescripton.module.scss"

interface IListTemplate {
  name: string
  val: string
  layout?: "active" | "history"
}

const listTemplate: IListTemplate[] = [
  {
    name: "executor",
    val: "hasReview",
    layout: "history",
  },
  {
    name: "object",
    val: "TechnicalObject",
  },
  {
    name: "options",
    val: "options",
  },
  {
    name: "date",
    val: "created_at",
  },
  {
    name: "orderTime",
    val: "created_at",
  },
  {
    name: "orderTimeEnd",
    val: "finished_at",
  },
  {
    name: "orderNum",
    val: "number",
  },
]

interface Props {
  order: any
  layout?: "active" | "history"
  isLoading?: boolean
  review?: IOrderReview[]
}

const formatedName = (name: string) => {
  const arr = name.split(" ")

  return arr.length > 1 ? `${arr[0]} ${arr[1][0]}.` : `${arr}`
}

const OrderDescription: FC<Props> = ({ order, layout, isLoading, review }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()

  const list = useMemo(() => {
    if (isLoading || !order) return null
    return listTemplate.map((item: any) => {
      if (item.layout && item.layout !== layout) return null
      if (!order[item.val]) return null
      let txt

      if (item.name === "options") {
        return order[item.val].map((optionItem: any) => {
          switch (optionItem.type) {
            case "information": {
              return <InformationRow el={optionItem} key={optionItem.id} />
            }
            case "text":
            case "number":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>{optionItem.value}</p>
                </li>
              )
            case "boolean":
            case "checkbox":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>{optionItem.value ? t("yes") : t("no")}</p>
                </li>
              )
            case "datetime":
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>
                    {optionItem.value &&
                      (typeof optionItem.value === "number"
                        ? moment(Number(optionItem.value)).format("DD MMMM YYYY HH:mm")
                        : moment(optionItem.value).format("DD MMMM YYYY"))}
                  </p>
                </li>
              )
            case "list": {
              const items = optionItem.items
                ? optionItem.items.map((optI: any, ind: number) => {
                    return optI.name + (ind !== optionItem.items.length - 1 ? ", " : "")
                  })
                : null
              return (
                <li key={optionItem.id} className={styles.row}>
                  <h6 className={styles.rowTitle}>{optionItem.name}</h6>
                  <p className={styles.rowTxt}>{items}</p>
                </li>
              )
            }
            default:
              return null
          }
        })
      }
      switch (item.name) {
        case "executor":
        case "assignees":
          if (item.name === "executor" && !review?.length) return null
          return (
            <div key={item.name} className={styles.assignee}>
              <img
                src={
                  order?.assignees[0]?.avatar_id
                    ? getApiUrl() + "/web/user/avatar/" + order.assignees[0].avatar_id
                    : "/img/productNoImg.svg"
                }
                alt=""
                className={clsx(styles.assignee__avatar)}
              />
              <div>
                <p className={styles.assignee__name}>
                  {order?.assignees[0]?.name ? formatedName(order?.assignees[0]?.name) : t("executorNotFound")}
                </p>
                {item.name === "executor" && review && review?.length > 0 && (
                  <StarsRating disabled rating={review[0].rating} />
                )}
              </div>
            </div>
          )
        case "date":
          txt = moment(order[item.val]).format("DD MMMM YYYY")
          break
        case "orderTime":
          txt = moment(order[item.val]).format("kk:mm")
          break
        case "orderTimeEnd": {
          const diff = getDateDiff(new Date(order.created_at), new Date(order[item.val]))
          txt = diff.fullHours + ":" + diff.minutes
          break
        }
        default:
          txt = order[item.val]
      }

      return (
        <li key={item.name} className={styles.row}>
          <h6 className={styles.rowTitle}>{t(item.name)}</h6>
          <p className={styles.rowTxt}>{txt}</p>
        </li>
      )
    })
  }, [order, review, layout, isLoading])

  if (isLoading) return <div className={clsx(styles["description--is-loading"], "skeletonBlock")} />

  return (
    <div className={styles.description}>
      {isDesktop ? (
        <Accordion header={t("description")} className={styles["order-descr-accord"]}>
          <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={500}>
            <ul className={styles.list}>{list}</ul>
          </ScrollBlock>
        </Accordion>
      ) : (
        <div className={styles["order-descr-container"]}>
          {order?.status === -1 && (
            <div className={styles.canceled}>
              <PlusIcon />
              {t("canceledOrder")}
            </div>
          )}
          <ul className={styles.list}>{list}</ul>
        </div>
      )}
    </div>
  )
}

export default OrderDescription
