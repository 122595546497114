import { useLayoutEffect, useState } from "react"

const useWindowSize = () => {
  const [size, setSize] = useState<number[]>([0, 0])
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
      setIsDesktop(window.innerWidth >= 1200)
      setIsMobile(window.innerWidth <= 639)
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return { size, isMobile, isDesktop }
}

export default useWindowSize
