// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesFilter_grid__Rp5a5 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;
}
.ArticlesFilter_grid--is-open__nOBJP .ArticlesFilter_btn__qXGCj {
  transform: rotate(180deg);
}

.ArticlesFilter_label__CiFgh {
  font-weight: normal;
  font-size: var(--fz-md);
  line-height: var(--lh-md);
  border-radius: 47px;
  width: auto;
  padding-left: 16px;
  padding-right: 16px;
}
.ArticlesFilter_label--is-active__Gp26t {
  background-color: var(--accent-color);
  color: var(--accent-text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticlesFilter/ArticlesFilter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,aAAA;AACF;AAEI;EACE,yBAAA;AAAN;;AAKA;EACE,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;AAFF;AAQE;EACE,qCAAA;EACA,+BAAA;AANJ","sourcesContent":[".grid {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  grid-gap: 4px;\r\n\r\n  &--is-open {\r\n    .btn {\r\n      transform: rotate(180deg);\r\n    }\r\n  }\r\n}\r\n\r\n.label {\r\n  font-weight: normal;\r\n  font-size: var(--fz-md);\r\n  line-height: var(--lh-md);\r\n  border-radius: 47px;\r\n  width: auto;\r\n  padding-left: 16px;\r\n  padding-right: 16px;\r\n\r\n  @media (min-width: 1200px) {\r\n    //width: 100%;\r\n  }\r\n\r\n  &--is-active {\r\n    background-color: var(--accent-color);\r\n    color: var(--accent-text-color)\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `ArticlesFilter_grid__Rp5a5`,
	"grid--is-open": `ArticlesFilter_grid--is-open__nOBJP`,
	"btn": `ArticlesFilter_btn__qXGCj`,
	"label": `ArticlesFilter_label__CiFgh`,
	"label--is-active": `ArticlesFilter_label--is-active__Gp26t`
};
export default ___CSS_LOADER_EXPORT___;
