import { useMemo, useRef } from "react"
import SliderNav from "../../Assets/SliderNav/SliderNav"
import { Swiper, SwiperSlide } from "swiper/react"
import ArticlePrevItem from "../ArticlePrevItem/ArticlePrevItem"
import { SwiperOptions } from "swiper/types/swiper-options"
import { Navigation } from "swiper/modules"
import clsx from "clsx"
import type { Swiper as SwiperClass } from "swiper/types"
import { IArticle } from "../../../types/content"
import styles from "./ArticlesPrevBlock.module.scss"

interface Props {
  id?: string
  className?: string
  title: string
  list: IArticle[]
}

const swiperParams: SwiperOptions = {
  slidesPerView: 1,
  modules: [Navigation],
}

const ArticlesPrevBlock = ({ title, list, className }: Props) => {
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)

  const slides = useMemo(() => {
    const result = list.reduce((accumulator: any[], currentValue, index) => {
      if (index % 5 === 0) {
        accumulator.push([])
      }
      accumulator[accumulator.length - 1].push(currentValue)
      return accumulator
    }, [])

    return result.map((item, index) => (
      <SwiperSlide key={index}>
        {item.map((itemInner: IArticle) => (
          <ArticlePrevItem key={itemInner.id} {...itemInner} className={styles.item} />
        ))}
      </SwiperSlide>
    ))
  }, [list])

  return (
    <div className={clsx(styles.block, className)}>
      <header className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <SliderNav className={clsx(styles.nav)} mode={"white"} prevRef={prevRef} nextRef={nextRef} />
      </header>
      <Swiper
        {...swiperParams}
        onBeforeInit={(swiper: SwiperClass) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
      >
        {slides}
      </Swiper>
    </div>
  )
}

export default ArticlesPrevBlock
