import { useState } from "react"
import clsx from "clsx"
import styles from "./CountBtn.module.scss"

const CountBtn = ({
  value = 1,
  onChange,
  className,
}: {
  value?: number
  onChange: (num: number) => void
  className?: string
}) => {
  const [val, setVal] = useState<number>(value)

  const handleChange = (type: "increment" | "decrement") => {
    if (type === "decrement" && val <= 1) return
    setVal((prev) => {
      if (type === "increment") return prev + 1
      if (type === "decrement") return prev - 1
      return prev
    })
    onChange(type === "increment" ? val + 1 : val - 1)
  }

  return (
    <div className={clsx(styles.wrap, className)}>
      <button
        className={styles.btn}
        disabled={val <= 1}
        type={"button"}
        onClick={() => {
          handleChange("decrement")
        }}
      />
      <span>{val}</span>
      <button
        className={clsx(styles.btn, styles["btn--increment"])}
        type={"button"}
        onClick={() => {
          handleChange("increment")
        }}
      />
    </div>
  )
}

export default CountBtn
