import { getFileIco } from "../../../utils/helpers"
import { getApiUrl } from "../../../utils/getApiUrl"
import moment from "moment/moment"
import { useTranslation } from "react-i18next"
import styles from "./PreviewFile.module.scss"
import clsx from "clsx"

interface Props {
  createdAt: string
  filename: string
  id: string
  size: number
  className?: string
}

const PreviewFile = ({ createdAt, id, size, filename, className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [iconVisible, iconSrc] = getFileIco(filename?.substring(filename?.lastIndexOf(".") + 1, filename?.length))

  return (
    <a
      href={`${getApiUrl()}/public/attachment/${id}`}
      target="_blank"
      rel="noreferrer"
      className={clsx(styles.block, className)}
    >
      <span className={styles.iconWrap}>
        {iconVisible ? <img src={`${iconSrc}`} className={styles.icon} alt="" /> : <></>}
      </span>
      <span>
        <span className={styles.name}>{filename}</span>
        <span className={styles.info}>
          {Number(size / 1000000).toFixed(2)}Mb
          <span>●</span>
          {moment(Number(createdAt) * 1000).format(`DD MMM YYYY ${t("in")} HH:mm`)}
        </span>
      </span>
    </a>
  )
}

export default PreviewFile
