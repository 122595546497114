import { ReactNode } from "react"
import clsx from "clsx"
import styles from "./Paper.module.scss"

const Paper = ({ children, footer, className }: { children: ReactNode; footer?: ReactNode; className?: string }) => {
  return (
    <section className={clsx("paper", styles.paper, className)}>
      <div className={clsx("paper__main", styles.main)}>{children}</div>{" "}
      {footer && <footer className={styles.footer}>{footer}</footer>}
    </section>
  )
}

export default Paper
