import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { mainApi } from "../api/main"
import { RootState } from "../store"

const initialState: any = {
  technicalObject: {
    id: "",
    name: "",
    path_name: "",
    photos: [],
    shortName: "",
  },
  serviceCategories: [],
  categories: [],
  subCatsObject: [],
  servicesInCategory: [],
  allServicesInObject: [],
  isLoadingServicesList: true
}


export const getServicesList = createAsyncThunk<any, any, { state: RootState }>(
  "object/servicesList",
  async (payload, { getState, dispatch }): Promise<any> => {
    const response: any = await dispatch(
      mainApi.endpoints.getRoomServicesList.initiate({
        technical_object_id: payload.technical_object_id,
      }),
    )
    return response.data
  },
)

export const objectSlice = createSlice({
  name: "object",
  initialState,
  reducers: {
    updateObjectPage: (state: any, action: PayloadAction<any>) => {
      state.technicalObject = { ...action.payload.technicalObject }
      state.serviceCategories = [...action.payload.serviceCategories]
      state.allServicesInObject = []
      action.payload.serviceCategories
        .map((el: any) => el.services)
        .forEach((el: any) => el.forEach((service: any) => state.allServicesInObject.push(service)))
    },
    updateSubCatsObject: (state: any, action: PayloadAction<any>) => {
      state.subCatsObject = [...action.payload.data.data.serviceCategories]
    },
    setServicesInCategory: (state: any, action: PayloadAction<any>) => {      
      state.servicesInCategory = action.payload
    },
    resetServicesInCategory: (state: any) => {
      state.servicesInCategory = []
    },
    resetSubCatsLists: (state: any) => {
      state.subCatsObject = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServicesList.pending, (state) => {
        state.isLoadingServicesList = true
    })
    builder.addCase(getServicesList.fulfilled, (state, action) => {
        state.isLoadingServicesList = false
        if (action.payload) state.categories = action?.payload?.data
    })
    builder.addCase(getServicesList.rejected, (state) => {
        state.isLoadingServicesList = false
    })
  },
})

export const {
  updateObjectPage,
  updateSubCatsObject,
  resetSubCatsLists,
  setServicesInCategory,
  resetServicesInCategory,
} = objectSlice.actions

export const currentObject = (state: any) => state[objectSlice.name]
