// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Paper_paper__zf9TT {
  background-color: var(--white);
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);
  border-radius: 12px;
}

.Paper_main__fCgyO {
  padding: 12px;
}

.Paper_footer__7LhA\\+ {
  background-color: #FAFAFA;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top: 1px solid #EFF3F5;
  padding: 14px 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/Paper/Paper.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,uDAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,yBAAA;EACA,kCAAA;EACA,mCAAA;EACA,6BAAA;EACA,kBAAA;AACF","sourcesContent":[".paper {\r\n  background-color: var(--white);\r\n  box-shadow: 0px 5px 9px 0px #0000000D;\r\n  border-radius: 12px;\r\n}\r\n\r\n.main {\r\n  padding: 12px;\r\n}\r\n\r\n.footer {\r\n  background-color: #FAFAFA;\r\n  border-bottom-left-radius: inherit;\r\n  border-bottom-right-radius: inherit;\r\n  border-top: 1px solid #EFF3F5;\r\n  padding: 14px 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `Paper_paper__zf9TT`,
	"main": `Paper_main__fCgyO`,
	"footer": `Paper_footer__7LhA+`
};
export default ___CSS_LOADER_EXPORT___;
