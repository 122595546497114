import { Dispatch, FC, SetStateAction } from "react"
import clsx from "clsx"
import styles from "./StarsRating.module.scss"

interface IStarRating {
  value: number
  name: string
  id: string
  disabled?: boolean
  val: number
  setVal: Dispatch<SetStateAction<number>>
  onChange?: (val: number) => void
}

const StarRating: FC<IStarRating> = ({ val, setVal, value, name, id, onChange, disabled }) => {
  return (
    <>
      <input
        className={clsx("rating-star-input", styles.input)}
        type="radio"
        value={value}
        name={name}
        id={id}
        checked={value === val}
        onChange={() => {
          setVal(value)
          if (onChange) onChange(value)
        }}
        disabled={disabled}
      />
      <label
        className={clsx("rating-star-label", styles.label)}
        htmlFor={id}
        style={{ pointerEvents: disabled ? "none" : "auto" }}
      >
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1941 0.582386C10.4814 -0.0404822 11.3667 -0.0404829 11.654 0.582385L14.0241 5.72072C14.1412 5.97457 14.3818 6.14936 14.6594 6.18228L20.2786 6.84853C20.9598 6.92929 21.2333 7.77121 20.7297 8.23693L16.5753 12.0788C16.37 12.2687 16.2781 12.5515 16.3326 12.8257L17.4354 18.3758C17.5691 19.0485 16.8529 19.5689 16.2544 19.2338L11.3167 16.4699C11.0728 16.3334 10.7754 16.3334 10.5314 16.4699L5.59377 19.2338C4.99523 19.5689 4.27905 19.0485 4.41273 18.3758L5.51553 12.8257C5.57001 12.5515 5.47812 12.2687 5.27287 12.0788L1.11842 8.23693C0.614821 7.77121 0.888379 6.92929 1.56954 6.84853L7.18878 6.18228C7.4664 6.14936 7.70697 5.97457 7.82406 5.72072L10.1941 0.582386Z" />
        </svg>
      </label>
    </>
  )
}

export default StarRating
