import clsx from "clsx"
import Avatar from "../Assets/Avatar/Avatar"
import StarsRating from "../Assets/StarsRating/StarsRating"
import moment from "moment"
import { IReview } from "../../types/content"
import { getApiUrl } from "../../utils/getApiUrl"
import styles from "./Review.module.scss"

interface Props extends IReview {
  className?: string
}

const Review = ({ order_id, order_name, text, created_at, assigneeUsers, rating, className }: Props) => {
  return (
    <div className={clsx(styles.item, className)}>
      <h4 className={styles.title}>{order_name}</h4>
      {text && <p className={styles.txt}>{text}</p>}
      <div className={styles.rateSection}>
        {assigneeUsers?.length > 0 && (
          <>
            <Avatar
              img={assigneeUsers[0]?.avatar_id ? getApiUrl() + "/web/user/avatar/" + assigneeUsers[0].avatar_id : ""}
              name={assigneeUsers[0].name}
              className={styles.avatar}
              size={"sm"}
            />
            <div>
              <p className={styles.name}>{assigneeUsers[0].name}</p>
              <StarsRating rating={rating} size={"sm"} name={"review-rate-" + order_id} disabled />
            </div>
          </>
        )}
        <p className={styles.time}>{moment(created_at * 1000).format("kk:mm")}</p>
      </div>
    </div>
  )
}

export default Review
