// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesFavorites_wrap__TZvCd {
  background-color: var(--white);
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.0509803922);
  border-radius: 12px;
  padding: 12px 0 20px 0;
  margin-bottom: 24px;
}
@media (max-width: 640px) {
  .ArticlesFavorites_wrap__TZvCd .swiper .swiper-slide {
    height: auto;
  }
}

.ArticlesFavorites_title__EExNm {
  font-weight: 500;
}

.ArticlesFavorites_header__LIsK3 {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 12px;
  margin-bottom: 12px;
}

.ArticlesFavorites_iconWrap__fMTqg {
  display: inline-flex;
  flex-shrink: 0;
  color: var(--orange-500);
  margin-left: 15px;
}

.ArticlesFavorites_nav__d19cH {
  margin-left: auto;
}
.ArticlesFavorites_nav__d19cH .slider-prev-btn {
  margin-right: 4px;
}

.ArticlesFavorites_item__ZKpOk {
  padding: 12px 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticlesFavorites/ArticlesFavorites.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,mDAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAGM;EACA;IACE,YAAA;EADN;AACF;;AAOA;EACE,gBAAA;AAJF;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AAJF;;AAOA;EACE,oBAAA;EACA,cAAA;EACA,wBAAA;EACA,iBAAA;AAJF;;AAOA;EACE,iBAAA;AAJF;AAOI;EACE,iBAAA;AALN;;AAUA;EACE,kBAAA;AAPF","sourcesContent":[".wrap {\r\n  background-color: var(--white);\r\n  box-shadow: 0 5px 9px 0 #0000000D;\r\n  border-radius: 12px;\r\n  padding: 12px 0 20px 0;\r\n  margin-bottom: 24px;\r\n\r\n  :global {\r\n    .swiper {\r\n      @media (max-width: 640px) {\r\n      .swiper-slide {\r\n        height: auto;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n.title {\r\n  font-weight: 500;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  align-items: center;\r\n  position: relative;\r\n  padding: 0 12px;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.iconWrap {\r\n  display: inline-flex;\r\n  flex-shrink: 0;\r\n  color: var(--orange-500);\r\n  margin-left: 15px;\r\n}\r\n\r\n.nav {\r\n  margin-left: auto;\r\n\r\n  :global {\r\n    .slider-prev-btn {\r\n      margin-right: 4px;\r\n    }\r\n  }\r\n}\r\n\r\n.item {\r\n  padding: 12px 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ArticlesFavorites_wrap__TZvCd`,
	"title": `ArticlesFavorites_title__EExNm`,
	"header": `ArticlesFavorites_header__LIsK3`,
	"iconWrap": `ArticlesFavorites_iconWrap__fMTqg`,
	"nav": `ArticlesFavorites_nav__d19cH`,
	"item": `ArticlesFavorites_item__ZKpOk`
};
export default ___CSS_LOADER_EXPORT___;
