// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleBack_titleBack__Q720D {
  display: flex;
  align-items: center;
}
@media (min-width: 1200px) {
  .TitleBack_titleBack__Q720D {
    margin-bottom: 31px;
  }
}
.TitleBack_titleBack__back__TCojx {
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
  background: var(--white);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
  transition-property: background, box-shadow;
}
@media (hover: hover) {
  .TitleBack_titleBack__back__TCojx:hover {
    box-shadow: -8px 0px 24px 0px rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 640px) {
  .TitleBack_titleBack__back__TCojx {
    width: 52px;
    height: 52px;
  }
}
@media (min-width: 1200px) {
  .TitleBack_titleBack__back__TCojx {
    width: 28px;
    height: 28px;
    min-width: auto;
    flex-shrink: 0;
    box-shadow: -8px 0px 24px 0px rgba(0, 0, 0, 0.0901960784);
  }
}
.TitleBack_titleBack__text__pWqzO {
  margin-left: 16px;
}
@media (min-width: 1200px) {
  .TitleBack_titleBack__text__pWqzO {
    margin-left: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TitleBack/TitleBack.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EAJF;IAKI,mBAAA;EAEF;AACF;AAAE;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,wBAAA;EACA,2CAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,6BAAA;EACA,2CAAA;AAEJ;AAAI;EACE;IACE,gDAAA;EAEN;AACF;AACI;EApBF;IAqBI,WAAA;IACA,YAAA;EAEJ;AACF;AAAI;EAzBF;IA0BI,WAAA;IACA,YAAA;IACA,eAAA;IACA,cAAA;IACA,yDAAA;EAGJ;AACF;AAAE;EACE,iBAAA;AAEJ;AAAI;EAHF;IAII,gBAAA;EAGJ;AACF","sourcesContent":[".titleBack {\n  display: flex;\n  align-items: center;\n\n  @media (min-width: 1200px) {\n    margin-bottom: 31px;\n  }\n\n  &__back {\n    height: 36px;\n    width: 36px;\n    min-width: 36px;\n    border-radius: 50%;\n    background: var(--white);\n    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    transition: var(--transition);\n    transition-property: background, box-shadow;\n\n    @media (hover: hover) {\n      &:hover {\n        box-shadow: -8px 0px 24px 0px rgba(0, 0, 0, 0.2);\n      }\n    }\n\n    @media (min-width: 640px) {\n      width: 52px;\n      height: 52px;\n    }\n\n    @media (min-width: 1200px) {\n      width: 28px;\n      height: 28px;\n      min-width: auto;\n      flex-shrink: 0;\n      box-shadow: -8px 0px 24px 0px #00000017;\n    }\n  }\n\n  &__text {\n    margin-left: 16px;\n\n    @media (min-width: 1200px) {\n      margin-left: 8px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleBack": `TitleBack_titleBack__Q720D`,
	"titleBack__back": `TitleBack_titleBack__back__TCojx`,
	"titleBack__text": `TitleBack_titleBack__text__pWqzO`
};
export default ___CSS_LOADER_EXPORT___;
