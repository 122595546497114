import Layout from "../components/Layout/Layout"
import OrdersLayout from "../components/OrdersLayout/OrdersLayout"

export default function Orders() {
  return (
    <Layout isHeaderMobHidden>
      <OrdersLayout layout={"active"} />
    </Layout>
  )
}
