import { FC } from "react"
import Reviews from "../Reviews/Reviews"
import TitleBack from "../TitleBack/TitleBack"
import { useTranslation } from "react-i18next"
import { useGetOrdersWithoutReviewsQuery, useGetReviewsQuery } from "../../redux/api/review"
import clsx from "clsx"
import styles from "./ReviewsLayout.module.scss"

const ReviewsLayout: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const { data: ordersData, isLoading: isLoadingOrder } = useGetOrdersWithoutReviewsQuery()
  const { data: reviewsData, isLoading: isLoadingReview } = useGetReviewsQuery()

  return (
    <>
      <TitleBack title={t("myReviewsTitle")} className={styles.titleBack} />
      {isLoadingOrder || isLoadingReview ? (
        <>
          <div className={clsx("skeletonBlock", styles.subtitleLoader)} />
          <div className={clsx("skeletonBlock", styles.itemLoader)} />
          <div className={clsx("skeletonBlock", styles.itemLoader)} />
        </>
      ) : ordersData?.orders?.length || reviewsData?.reviews?.length ? (
        <Reviews orders={ordersData?.orders} reviews={reviewsData?.reviews} />
      ) : (
        <div className={styles.noReviews}>{t("noReviewsYet")}</div>
      )}
    </>
  )
}

export default ReviewsLayout
