import React, { useState, useEffect } from "react"
import styles from "./SearchList.module.scss"
import ProductItem from "../ProductItem/ProductItem"
import SelectObjModal from "../Modals/SelectObjModal/SelectObjModal"
import OrderModal from "../Modals/OrderModal/OrderModal"
import { Form, Formik, FormikConfig } from "formik"
import { usePostCreateOrderMutation } from "../../redux/api/content"
import { toBase64 } from "../../utils/helpers"
import SuccessModal from "../Modals/SuccessModal/SuccessModal"
import AuthModal from "../Modals/AuthModal/AuthModal"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { authModalOpen } from "../../redux/slice/authModal"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { addActiveOrdersCount } from "../../redux/slice/auth"
import useWindowSize from "../../hooks/useWindowSize"
import { useLazyGetProductInfoQuery } from "../../redux/api/main"
import { useScrollBlock } from "../../hooks/useScrollBlock"

interface Props {
  title?: string
  list: any[]
  isSearchLoading?: boolean
  catId?: string
  searchVal?: string | number
  className?: string
}

interface IOption {
  alias?: string
  conditions?: any[]
  id?: string
  name?: string
  type?: string
}

const SearchList: React.FC<Props> = ({ title, list, isSearchLoading, catId, searchVal, className }) => {
  const dispatch = useAppDispatch()
  const { isDesktop } = useWindowSize()
  const navigate = useNavigate()

  const [createOrder, { isLoading }] = usePostCreateOrderMutation()
  const [getProductInfo] = useLazyGetProductInfoQuery()

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { token } = useAppSelector((state) => state.auth)

  const [selectObjModal, setSelectObjModal] = useState(false)
  const [orderModal, setOrderModal] = useState(false)
  const [orderModalLoading, setOrderModalLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [successModalNum, setSuccessModalNum] = useState<number>(0)
  const [initialValuesForm, setInitialValuesForm] = useState<{ [key: string]: any }>({})
  const { allowScroll } = useScrollBlock()

  const [validateIds, setValidateIds] = useState<any[]>([])

  const [prodModalInfo, setProdModalInfo] = useState<any>({})

  const { pathname } = useLocation()
  const query = useParams()
  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")

  const orderModalFcn = (id: string) => {
    setOrderModal(true)
    setOrderModalLoading(true)
    getProductInfo(id)
      .then((result: any) => {
        setProdModalInfo(result?.data ?? {})
        setOrderModalLoading(false)
        if (result?.data?.technicalObjects?.length && !isObjectPage) {
          if (
            (result?.data?.technicalObjects?.length === 1 && !!result?.data?.technicalObjects?.[0]?.children_count) ||
            result?.data?.technicalObjects?.length > 1
          ) {
            setOrderModal(false)
            setTimeout(() => {
              setSelectObjModal(true)
              getProductInfo(id).then((res: any) => setProdModalInfo(res?.data))
            }, 0)
          }
        }
      })
      .catch((err) => console.log(err))
  }

  const deepFindNeedOption = (parentArr: IOption[], searchedId: string): IOption | null => {
    const needEl = parentArr.find((el) => el.id === searchedId)
    let isFind = false
    if (needEl) return needEl

    let result: IOption | null = null
    parentArr.forEach((ell) => {
      if (ell?.conditions?.length) {
        ell?.conditions?.forEach((elll: any) => {
          if (!isFind) {
            result = deepFindNeedOption(elll.options, searchedId)
            if (result) isFind = true
          }
        })
      }
    })
    return result
  }

  useEffect(() => {
    if (!orderModal && !selectObjModal) {
      // setInitialValuesForm({})
    }
  }, [orderModal])

  const submitForm = async (values: { [key: string]: any }, actions: any) => {
    const formData = new FormData()
    let key: keyof typeof values
    let arrNum = 0

    for (key in values) {
      if (key !== "count" && key !== "service_id" && key !== "technical_object_id" && key !== "technical_object_name") {
        if (Array.isArray(values[key]) && values[key].length && values[key][0] !== "information") {
          let numArr = 0
          for (const item of values[key]) {
            formData.append(`options[${arrNum}][id]`, key as string)

            const fileName = item?.name ?? ""
            if (item instanceof Blob) {
              const attachmentType = deepFindNeedOption(prodModalInfo.options, key)?.type
              const file = await toBase64(item)
              //@ts-ignore
              formData.append(
                `options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}]${
                  attachmentType === "attachment" ? "[data]" : ""
                }`,
                file as any,
              )
              if (attachmentType === "attachment") {
                formData.append(
                  `options[${arrNum}][${attachmentType === "attachment" ? "files" : "images"}][${numArr}][filename]`,
                  fileName,
                )
              }
            } else {
              formData.append(`options[${arrNum}][items][${numArr}]`, item)
            }
            numArr++
          }
          arrNum++
        } else if (Array.isArray(values[key]) && values[key][0] && values[key][0] === "information") {
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][wasShowed]`, values[key][1])
          arrNum++
        } else if (typeof values[key] === "boolean" || (!Array.isArray(values[key]) && values[key])) {
          formData.append(`options[${arrNum}][id]`, key)
          formData.append(`options[${arrNum}][value]`, values[key])
          arrNum++
        }
      }
    }

    formData.append("service_id", values.service_id)
    formData.append("count", `${values.count}`)
    if (
      prodModalInfo?.technicalObjects?.length === 1 &&
      prodModalInfo?.technicalObjects?.technicalObjects?.[0]?.children_count
    )
      formData.append("technical_object_id", `${prodModalInfo?.technicalObjects[0]?.id}`)
    if (values.technical_object_id) formData.append("technical_object_id", `${values.technical_object_id}`)
    else if (isObjectPage) formData.append("technical_object_id", `${query.id}`)

    const userToken = token || localStorage.getItem("token")
    if (userToken) {
      actions.setSubmitting(true)
      try {
        await createOrder(formData)
          .unwrap()
          .then((res) => {
            actions.resetForm()
            setOrderModal(false)
            setSelectObjModal(false)
            dispatch(addActiveOrdersCount())
            setSuccessModalNum(res?.data?.number ?? 0)
            setSuccessModal(true)
            actions.setSubmitting(false)
            navigate(`/order/${res.data.id}`)

            allowScroll(true)
          })
      } catch (e) {
        console.warn(e)
        actions.setSubmitting(false)
      }
    } else {
      dispatch(authModalOpen({ flag: true }))
    }
  }
  const skeletonArr = ["", "", "", "", "", "", "", ""]

  const validateOpt = (values: any) => {
    const errors: any = {}
    const boolTypes = ["boolean"]
    const arrTypes = ["list", "attachment", "photo"]

    validateIds.forEach((el: any) => {
      if (boolTypes.includes(el.type)) {
        if (values?.[el.id] === null) errors[el.id] = "empty"
      } else if (arrTypes.includes(el.type) && !values?.[el.id]?.length) {
        errors[el.id] = "empty"
      } else if (!values?.[el.id]) {
        errors[el.id] = "empty"
      }
      // if (values?.[el.id]?.length === 0 || !values?.[el.id]) errors[el.id] = "empty"
    })
    return errors
  }

  return (
    <>
      {!isSearchLoading && !list.length && searchVal ? (
        <p className={styles.nothingContent}>{t("searchServiceNotFound")}</p>
      ) : (
        <div className={`${styles.searchList} searchList-item ${className ? className : ""}`} id={catId ?? ""}>
          {title ? <h4 className={styles.searchList__title}>{title}</h4> : <></>}
          <div className={styles.searchList__wp}>
            {isSearchLoading
              ? [...Array(4)].map((_item, kk: number) => <ProductItem key={kk} isLoading={true} />)
              : list.map((el: any, kk: number) => (
                  <ProductItem
                    img={el?.photos?.[0]}
                    text={el.name}
                    firstTime={el?.execution_time_from}
                    secondTime={el?.execution_time_to}
                    timeUnit={el?.execution_time_units}
                    ordered={false}
                    onClick={() => {
                      if (isDesktop && el?.category_id) {
                        navigate(`/products/${el.category_id}/${el.id}`)
                      } else {
                        orderModalFcn(el.id)
                      }
                    }}
                    description={el?.description}
                    key={kk}
                  />
                ))}
          </div>
        </div>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValuesForm}
        onSubmit={(values, actions) => {
          void submitForm(values, actions)
        }}
        validate={validateOpt}
        // validateOnChange={false}
      >
        {({ handleSubmit }) => (
          <Form>
            {prodModalInfo?.technicalObjects?.length ? (
              <SelectObjModal
                open={selectObjModal}
                setOpen={setSelectObjModal}
                techObjects={prodModalInfo.technicalObjects}
                prodModalInfo={prodModalInfo}
                setBackModal={setOrderModal}
                isLoading={isLoading}
              />
            ) : (
              <></>
            )}

            <OrderModal
              open={orderModal}
              setOpen={setOrderModal}
              modalInfo={prodModalInfo}
              setInitialValuesForm={setInitialValuesForm}
              setSelectObjModal={setSelectObjModal}
              isLoading={isLoading}
              orderModalLoading={orderModalLoading}
              setValidateIds={setValidateIds}
            />
            <SuccessModal
              modalTitle={`${t("cancelOrderApplication")} #${successModalNum} ${t("openOrderPublished")}`}
              modalText={`${t("openOrderComment")}`}
              setOpen={setSuccessModal}
              open={successModal}
            />
            {!isObjectPage ? <AuthModal appendFcn={handleSubmit} /> : ""}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SearchList
