import React from "react"
import styles from "./RoomServicesEl.module.scss"
import { Link } from "react-router-dom"
import {getApiUrl} from "../../utils/getApiUrl";

interface IRoomServicesEl {
  title?: string
  background?: any
  to?: any
  descr: string
  img: string
}

const RoomServicesEl: React.FC<IRoomServicesEl> = ({ title, background, to, descr, img }) => {
  return (
    <Link to={to} className={styles.RoomServicesEl} style={{ background: background }}>
      <div className={styles.RoomServicesEl__title}>{title}</div>
      <span
        className={styles.RoomServicesEl__descr}
        dangerouslySetInnerHTML={{
          __html: descr,
        }}
      />
      {img && (
        <img className={styles.RoomServicesEl__img} src={img ? getApiUrl() + "/public/photo/" + img : ""} alt="" />
      )}
    </Link>
  )
}

export default RoomServicesEl
