export const PhoneIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74342 1.78355C2.70128 1.74142 2.63297 1.74142 2.59083 1.78355C1.4833 2.89109 1.35869 4.64447 2.29846 5.89749L3.08617 6.94778C3.92951 8.07223 4.92839 9.0711 6.05284 9.91444L7.10312 10.7022C8.35615 11.6419 10.1095 11.5173 11.2171 10.4098C11.2592 10.3676 11.2592 10.2993 11.2171 10.2572L9.45164 8.49178C9.38655 8.42669 9.28103 8.42669 9.21594 8.49178L8.53517 9.17254C8.2748 9.43291 7.87704 9.49746 7.54769 9.33279C5.86872 8.4933 4.50731 7.1319 3.66782 5.45292C3.50315 5.12357 3.5677 4.72581 3.82807 4.46544L4.50883 3.78467C4.57392 3.71959 4.57392 3.61406 4.50883 3.54897L2.74342 1.78355ZM1.88372 1.07645C2.31638 0.643788 3.01786 0.643789 3.45052 1.07645L5.21594 2.84186C5.67155 3.29748 5.67155 4.03617 5.21594 4.49178L4.60936 5.09836C5.33948 6.51048 6.49013 7.66114 7.90225 8.39125L8.50883 7.78467C8.96444 7.32906 9.70314 7.32906 10.1587 7.78467L11.9242 9.55009C12.3568 9.98275 12.3568 10.6842 11.9242 11.1169C10.4647 12.5763 8.15427 12.7405 6.50312 11.5022L5.45284 10.7144C4.25258 9.81425 3.18636 8.74803 2.28617 7.54778L1.49846 6.49749C0.260099 4.84635 0.4243 2.53587 1.88372 1.07645Z"
      />
    </svg>
  )
}
