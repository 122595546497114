export default function MainLayoutLoader() {
  return (
    <div>
      <div className={"skeletonBlock"} style={{ height: "30px", borderRadius: "8px", marginBottom: "31px" }} />
      <div className={"skeletonBlock"} style={{ height: "102px", borderRadius: "16px", marginBottom: "65px" }} />
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: "16px" }}>
        <div className={"skeletonBlock"} style={{ height: "160px", borderRadius: "12px", gridRow: "span 2" }} />
        <div className={"skeletonBlock"} style={{ borderRadius: "12px" }} />
        <div className={"skeletonBlock"} style={{ borderRadius: "12px" }} />
        <div className={"skeletonBlock"} style={{ borderRadius: "12px" }} />
        <div className={"skeletonBlock"} style={{ borderRadius: "12px" }} />
      </div>
    </div>
  )
}
