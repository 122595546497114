export const ChatIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6V9.41328C12 9.67898 12 9.90826 11.9882 10.0984C11.9758 10.2984 11.9485 10.4988 11.8739 10.6989C11.6712 11.2425 11.2425 11.6712 10.6989 11.8739C10.4988 11.9485 10.2984 11.9758 10.0984 11.9882C9.90826 12 9.67898 12 9.41328 12H6C2.68629 12 0 9.31371 0 6ZM6 1.33333C3.42267 1.33333 1.33333 3.42267 1.33333 6C1.33333 8.57733 3.42267 10.6667 6 10.6667H9.39394C9.68442 10.6667 9.87194 10.6664 10.0158 10.6574C10.1543 10.6488 10.2077 10.6341 10.233 10.6246C10.4142 10.5571 10.5571 10.4142 10.6246 10.233C10.6341 10.2077 10.6488 10.1543 10.6574 10.0158C10.6664 9.87194 10.6667 9.68442 10.6667 9.39394V6C10.6667 3.42267 8.57733 1.33333 6 1.33333Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33398 5.33268C3.33398 4.96449 3.63246 4.66602 4.00065 4.66602L8.00065 4.66602C8.36884 4.66602 8.66732 4.96449 8.66732 5.33268C8.66732 5.70087 8.36884 5.99935 8.00065 5.99935L4.00065 5.99935C3.63246 5.99935 3.33398 5.70087 3.33398 5.33268Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33398 7.99967C5.33398 7.63148 5.63246 7.33301 6.00065 7.33301H8.00065C8.36884 7.33301 8.66732 7.63148 8.66732 7.99967C8.66732 8.36786 8.36884 8.66634 8.00065 8.66634H6.00065C5.63246 8.66634 5.33398 8.36786 5.33398 7.99967Z"
      />
    </svg>
  )
}
