import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import styles from "./AuthModal2.module.scss"
import { useGetCompanyLogoQuery } from "../../../redux/api/auth"
import LocalizedLink from "../../../hoc/LocalizedLink"
import SelectLang from "../../SelectLang/SelectLang"
import AuthModalLogin from "./AuthModalLogin"
import AuthModalCode from "./AuthModalCode"
import AuthModalReg from "./AuthModalReg"
import clsx from "clsx"
import Button from "../../Assets/Button/Button"
import { useTranslation } from "react-i18next"

const AuthModal2 = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { data: logoCompany } = useGetCompanyLogoQuery()

  const [isOpen, setOpen] = useState<boolean>(true)
  const [step, setStep] = useState<number>(1)
  const [seconds, setSeconds] = useState<number>(0)
  const [phoneNumber, setPhoneNumber] = useState<string>("")

  useEffect(() => {
    let interval: any = null
    if (step === 2 && seconds && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((sec: number) => sec - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [step, seconds])

  return (
    <ModalPortal className={styles.modal} isOpen={isOpen} setIsOpen={setOpen}>
      {logoCompany && logoCompany?.data?.length !== 0 && (
        <div className={styles.header}>
          <LocalizedLink to={"/"} className={styles.logo}>
            <div dangerouslySetInnerHTML={{ __html: logoCompany }} />
          </LocalizedLink>
          <SelectLang className={styles.lang} />
        </div>
      )}

      <p className={styles.logoTxt}>powered by Staq</p>

      {step === 1 ? (
        <AuthModalLogin
          setStep={setStep}
          setSeconds={setSeconds}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      ) : step === 2 ? (
        <AuthModalCode
          setStep={setStep}
          setSeconds={setSeconds}
          phoneNumber={phoneNumber}
          seconds={seconds}
          setOpen={setOpen}
        />
      ) : step === 3 ? (
        <AuthModalReg setStep={setStep} setOpen={setOpen} />
      ) : null}
      {/* Окошко ошибки */}
      {(step === 10 || step === 11) && (
        <>
          <div className={clsx(`modal-head`, styles.authHead)}>
            <div className={`modal-title`}>{step === 11 ? t("numberIsBlocked") : t("numberNotFound")}</div>
          </div>
          <div className={`modal-text`}>{step === 11 ? t("accIsBlocked") : t("authText3")}</div>
          <div className={clsx("modal-bottom", styles.authBottom, styles["authBottom--center"])}>
            <Button txt={t("back")} onClick={() => setStep(1)} mode={"gray"} />
          </div>
        </>
      )}
    </ModalPortal>
  )
}

export default AuthModal2
