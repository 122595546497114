import SimpleSelect from "../Assets/SimpleSelect/SimpleSelect"
import { useTranslation } from "react-i18next"

const SelectLang = ({ className }: { className?: string }) => {
  const currentLang = localStorage.getItem("i18nextLng")
  const { i18n } = useTranslation("translation", { keyPrefix: `interface` })

  const onChange = (lang: { value: string; label: string; leftIcon?: string }) => {
    void i18n.changeLanguage(lang.value)
  }

  return (
    <SimpleSelect
      className={className}
      value={currentLang ?? "ru"}
      type={"lang"}
      list={[
        { value: "ru", label: "Русский", leftIcon: "/img/lang/ru.png" },
        { value: "en", label: "English", leftIcon: "/img/lang/en.png" },
        {
          value: "th",
          label: "ภาษาไทย",
          leftIcon: "/img/lang/th.png",
        },
      ]}
      onChange={onChange}
    />
  )
}

export default SelectLang
