// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StarsRating_stars__UUMSY {
  width: -moz-fit-content;
  width: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-shrink: 0;
  justify-content: flex-end;
}
@media (min-width: 400px) {
  .StarsRating_stars__UUMSY {
    width: -moz-fit-content;
    width: fit-content;
  }
}
.StarsRating_stars--sm__6OSSk .StarsRating_label__C-C\\+F {
  width: 13px;
  height: 12px;
}
.StarsRating_stars--sm__6OSSk .StarsRating_label__C-C\\+F svg {
  max-height: 10px;
}
.StarsRating_stars--lg__FN6Cr .StarsRating_label__C-C\\+F {
  width: 35px;
  height: 34px;
}
.StarsRating_stars--lg__FN6Cr .StarsRating_label__C-C\\+F svg {
  width: 100%;
  height: 100%;
}

.StarsRating_input__wReVm {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  opacity: 0;
}
.StarsRating_input__wReVm:checked svg, .StarsRating_input__wReVm:checked ~ .StarsRating_label__C-C\\+F svg {
  color: var(--orange-500);
}

.StarsRating_label__C-C\\+F {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.StarsRating_label__C-C\\+F:hover svg, .StarsRating_label__C-C\\+F:hover ~ .StarsRating_label__C-C\\+F svg, .StarsRating_label__C-C\\+F:checked ~ .StarsRating_label__C-C\\+F:hover svg {
  color: var(--orange-500);
}
@media (min-width: 1200px) {
  .StarsRating_label__C-C\\+F {
    width: 26px;
    height: 24px;
  }
}
.StarsRating_label__C-C\\+F svg {
  color: var(--gray-100);
  transition: all 0.15s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/StarsRating/StarsRating.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EAAA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,2BAAA;EACA,8BAAA;EACA,cAAA;EACA,yBAAA;AACF;AACE;EATF;IAUI,uBAAA;IAAA,kBAAA;EAEF;AACF;AACI;EACE,WAAA;EACA,YAAA;AACN;AACM;EACE,gBAAA;AACR;AAKI;EACE,WAAA;EACA,YAAA;AAHN;AAKM;EACE,WAAA;EACA,YAAA;AAHR;;AASA;EACE,SAAA;EACA,UAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AANF;AASI;EACE,wBAAA;AAPN;;AAYA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AATF;AAaI;EACE,wBAAA;AAXN;AAeE;EAbF;IAcI,WAAA;IACA,YAAA;EAZF;AACF;AAcE;EACE,sBAAA;EACA,iCAAA;AAZJ","sourcesContent":[".stars {\n  width: fit-content;\n  width: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  flex-shrink: 0;\n  justify-content: flex-end;\n\n  @media (min-width: 400px) {\n    width: fit-content;\n  }\n\n  &--sm {\n    .label {\n      width: 13px;\n      height: 12px;\n\n      svg {\n        max-height: 10px;\n      }\n    }\n  }\n\n  &--lg {\n    .label {\n      width: 35px;\n      height: 34px;\n\n      svg {\n        width: 100%;\n        height: 100%;\n      }\n    }\n  }\n}\n\n.input {\n  margin: 0;\n  padding: 0;\n  width: 0;\n  height: 0;\n  opacity: 0;\n  &:checked,\n  &:checked ~ .label {\n    svg {\n      color: var(--orange-500);\n    }\n  }\n}\n\n.label {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  &:hover,\n  &:hover ~ &,\n  &:checked ~ &:hover {\n    svg {\n      color: var(--orange-500);\n    }\n  }\n\n  @media (min-width: 1200px) {\n    width: 26px;\n    height: 24px;\n  }\n\n  svg {\n    color: var(--gray-100);\n    transition: all 0.15s ease-in-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stars": `StarsRating_stars__UUMSY`,
	"stars--sm": `StarsRating_stars--sm__6OSSk`,
	"label": `StarsRating_label__C-C+F`,
	"stars--lg": `StarsRating_stars--lg__FN6Cr`,
	"input": `StarsRating_input__wReVm`
};
export default ___CSS_LOADER_EXPORT___;
