export const MailIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11315 0.779888C6.4128 0.385643 7.8033 0.408397 9.08936 0.844954C10.3754 1.28151 11.4924 2.10994 12.2835 3.2139C13.0746 4.31785 13.5 5.64187 13.5 7C13.5 7.27614 13.2761 7.5 13 7.5C12.7239 7.5 12.5 7.27614 12.5 7C12.5 5.85081 12.14 4.73049 11.4707 3.79637C10.8013 2.86226 9.85612 2.16128 8.76792 1.79188C7.67972 1.42249 6.50314 1.40324 5.40344 1.73683C4.30373 2.07042 3.33614 2.7401 2.63656 3.65181C1.93698 4.56353 1.54055 5.67147 1.50295 6.82004C1.46535 7.96862 1.78846 9.10012 2.42692 10.0556C3.06537 11.0112 3.98709 11.7427 5.06263 12.1475C6.13816 12.5523 7.31347 12.61 8.42351 12.3126C8.69024 12.2411 8.96441 12.3994 9.03588 12.6661C9.10735 12.9329 8.94906 13.207 8.68233 13.2785C7.37047 13.63 5.98146 13.5618 4.71038 13.0834C3.43929 12.605 2.34999 11.7405 1.59545 10.6112C0.840911 9.48196 0.459045 8.14473 0.503482 6.78733C0.547919 5.42992 1.01643 4.12053 1.8432 3.04305C2.66998 1.96557 3.8135 1.17413 5.11315 0.779888Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99967 4.8335C5.80306 4.8335 4.83301 5.80355 4.83301 7.00016C4.83301 8.19678 5.80306 9.16683 6.99967 9.16683C8.19629 9.16683 9.16634 8.19678 9.16634 7.00016C9.16634 5.80355 8.19629 4.8335 6.99967 4.8335ZM3.83301 7.00016C3.83301 5.25126 5.25077 3.8335 6.99967 3.8335C8.74858 3.8335 10.1663 5.25126 10.1663 7.00016C10.1663 8.74906 8.74858 10.1668 6.99967 10.1668C5.25077 10.1668 3.83301 8.74906 3.83301 7.00016Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66699 4.5C9.94313 4.5 10.167 4.72386 10.167 5V8C10.167 8.64433 10.6893 9.16667 11.3337 9.16667C11.978 9.16667 12.5003 8.64433 12.5003 8V7C12.5003 6.72386 12.7242 6.5 13.0003 6.5C13.2765 6.5 13.5003 6.72386 13.5003 7V8C13.5003 9.19662 12.5303 10.1667 11.3337 10.1667C10.137 10.1667 9.16699 9.19662 9.16699 8V5C9.16699 4.72386 9.39085 4.5 9.66699 4.5Z"
      />
    </svg>
  )
}
