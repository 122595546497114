import { api } from "./index"
import { BaseResponseType, IImportantEvent } from "../../types/content"

export const importantEventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUnreadImportantEvents: builder.query<{ aEvents: IImportantEvent[]; bIsEnd: boolean; iCount: number }, void>({
      query: () => ({
        url: `public/important-events/unreaded`,
        method: "GET",
      }),
      transformResponse: (
        response: BaseResponseType<{ aEvents: IImportantEvent[]; bIsEnd: boolean; iCount: number }>,
      ) => response.data,
    }),
    getImportantEvent: builder.query<BaseResponseType<IImportantEvent>, string>({
      query: (id) => ({
        url: `public/important-events/${id}`,
        method: "GET",
      }),
    }),
    markReadImportantEvents: builder.query<any, string[]>({
      query: (ids) => ({
        url: `public/important-events/mark-readed`,
        method: "POST",
        body: { ids: ids },
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetUnreadImportantEventsQuery, useLazyMarkReadImportantEventsQuery } = importantEventsApi
