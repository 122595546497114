// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderCallbackModal_txt__4AVXq {
  font-size: var(--fz-sm);
  line-height: var(--lh-sm);
  color: #54575C;
  margin-bottom: 8px;
}

.OrderCallbackModal_btn__qricL {
  display: flex;
  margin-left: auto;
  padding-left: 42px;
  padding-right: 42px;
  margin-top: 12px;
}

.OrderCallbackModal_btn-container__mEzvc {
  display: flex;
  gap: 12px;
}

.OrderCallbackModal_back-btn__AhVcA {
  width: 33.3333333333%;
}

.OrderCallbackModal_active-textarea-type__0LSV7 {
  min-height: 52px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 13px 16px;
}

.OrderCallbackModal_history-textarea-type__Xtqjl {
  min-height: 230px;
  height: 230px;
  padding: 13px 16px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/OrderInfoModal/OrderCallbackModal.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AACF","sourcesContent":[".txt {\r\n  font-size: var(--fz-sm);\r\n  line-height: var(--lh-sm);\r\n  color: #54575C;\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.btn {\r\n  display: flex;\r\n  margin-left: auto;\r\n  padding-left: 42px;\r\n  padding-right: 42px;\r\n  margin-top: 12px;\r\n}\r\n\r\n.btn-container {\r\n  display: flex;\r\n  gap: 12px;\r\n}\r\n\r\n.back-btn {\r\n  width: calc(100% / 3);\r\n}\r\n\r\n.active-textarea-type {\r\n  min-height: 52px;\r\n  height: 52px;\r\n  font-size: 16px;\r\n  font-weight: 400;\r\n  line-height: 24px;\r\n  padding: 13px 16px;\r\n}\r\n\r\n.history-textarea-type {\r\n  min-height: 230px;\r\n  height: 230px;\r\n  padding: 13px 16px;\r\n  line-height: 24px;\r\n  font-size: 16px;\r\n  font-weight: 400;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt": `OrderCallbackModal_txt__4AVXq`,
	"btn": `OrderCallbackModal_btn__qricL`,
	"btn-container": `OrderCallbackModal_btn-container__mEzvc`,
	"back-btn": `OrderCallbackModal_back-btn__AhVcA`,
	"active-textarea-type": `OrderCallbackModal_active-textarea-type__0LSV7`,
	"history-textarea-type": `OrderCallbackModal_history-textarea-type__Xtqjl`
};
export default ___CSS_LOADER_EXPORT___;
