export const ArrowBoldRightIcon = () => {
  return (
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20696 0.292969L0.792742 1.70718L3.08564 4.00008L0.792742 6.29297L2.20696 7.70718L5.91406 4.00008L2.20696 0.292969Z"
      />
    </svg>
  )
}
