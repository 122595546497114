import { useEffect, useMemo } from "react"
import styles from "./ServicesList.module.scss"
import ServiceEl from "./ServiceEl"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { languageModalOpen } from "../../redux/slice/languageModal"
import { useAppSelector } from "../../hooks"
import { selectIsAllowProposeNewServiceMain, updateIsAllowNewServiceCategory } from "../../redux/slice/auth"
import moment from "moment"
import { langWithCurrentMomentCode, servicesBgList } from "../../utils/helpers"
import useWindowSize from "../../hooks/useWindowSize"
import { IServiceItem } from "../../types/content"
import clsx from "clsx"

interface IItem extends IServiceItem {
  bg: string
}

const ServicesList = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const currentLang = localStorage.getItem("i18nextLng")

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale("en")
    }
  }, [currentLang])

  useEffect(() => {
    dispatch(updateIsAllowNewServiceCategory(false))
  }, [])

  const { serviceList, serviceListLoading } = useAppSelector((state) => state.main)

  const isAllowProposeNewServiceMain = useAppSelector(selectIsAllowProposeNewServiceMain)
  const { isDesktop } = useWindowSize()

  const list = useMemo(() => {
    if (!serviceList?.length) return
    let isFive = true
    const result = serviceList.reduce((accumulator: IItem[][], currentValue, index) => {
      if (!isDesktop) {
        if (index % 4 === 0) accumulator.push([])
      } else {
        if (isFive) {
          if (index === 0) accumulator.push([])
          if ((index + 3) % 8 === 0) {
            accumulator.push([])
            if (index !== 0) isFive = false
          }
        } else {
          if (index % 8 === 0) {
            accumulator.push([])
            isFive = true
          }
        }
      }

      accumulator[accumulator.length - 1].push({ ...currentValue, bg: servicesBgList[index % 12] })
      return accumulator
    }, [])

    let sufNum5 = 0
    let sufNum3 = 0
    return result.map((item, index) => {
      const num = index % 2 === 0 ? 5 : 3
      let fullNum = null
      if (isDesktop) {
        if (num === 5) {
          sufNum5 = sufNum5 >= 3 ? 1 : ++sufNum5
        }
        if (num === 3) {
          sufNum3 = sufNum3 >= 3 ? 1 : ++sufNum3
        }
        fullNum = num + "-" + (num === 5 ? sufNum5 : sufNum3)
      }

      let lastClass = null
      if (index + 1 === result.length) {
        lastClass = styles["row--last-" + (isDesktop ? "" : "mob-") + item.length]
      }

      return (
        <div key={index} className={clsx(styles.row, !lastClass && fullNum && styles[`row--${fullNum}`], lastClass)}>
          {item?.map((el) => {
            return (
              <ServiceEl
                key={el.id}
                id={el.id}
                title={el?.name}
                subtitle={el?.description}
                background={el.bg}
                img={el?.image_id}
              />
            )
          })}
        </div>
      )
    })
  }, [serviceList, isDesktop])

  return (
    <div className={styles.serviceList}>
      {isDesktop && (
        <h4
          className={`${styles.serviceList__title} ${
            serviceListLoading ? `selectBack ${styles.serviceList__title_load}` : ""
          }`}
        >
          {t("services")}
        </h4>
      )}

      <div className={styles.serviceList__list}>
        {serviceListLoading && !serviceList.length ? (
          <div className={styles.gridLoading}>
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
          </div>
        ) : (
          <div className={styles.grid}>{list}</div>
        )}
      </div>
    </div>
  )
}

export default ServicesList
