import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface mobileMenuModalState {
  openIsMoreModal: boolean
  isHidden: boolean
}

const initialState: mobileMenuModalState = {
  openIsMoreModal: false,
  isHidden: false,
}

export const MobileMenuModalSlice = createSlice({
  name: "mobileMenu",
  initialState,
  reducers: {
    setOpenIsMoreModal: (state: mobileMenuModalState, action: PayloadAction<boolean>) => {
      state.openIsMoreModal = action.payload
    },
    setMobileMenuIsHidden: (state: mobileMenuModalState, action: PayloadAction<boolean>) => {
      state.isHidden = action.payload
    },
  },
})

export const { setOpenIsMoreModal, setMobileMenuIsHidden } = MobileMenuModalSlice.actions

export const getOpenIsMoreModal = (state: RootState) => state[MobileMenuModalSlice.name].openIsMoreModal
export const getMobileMenuIsHidden = (state: RootState) => state[MobileMenuModalSlice.name].isHidden
