import clsx from "clsx"

export const ArrowRightIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={clsx(className)}
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.35348 0.646485L0.646371 1.35359L4.29282 5.00004L0.646372 8.64648L1.35348 9.35359L5.70703 5.00004L1.35348 0.646485Z"
      />
    </svg>
  )
}
