export const ArrowLeftBoldIcon = () => {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79304 0.292969L7.20726 1.70718L2.91436 6.00008L7.20726 10.293L5.79304 11.7072L0.085937 6.00008L5.79304 0.292969Z"
      />
    </svg>
  )
}
