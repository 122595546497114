// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadBox_box__q6\\+4S {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
@media (min-width: 1200px) {
  .UploadBox_box__q6\\+4S {
    width: 24px;
    height: 24px;
  }
}
@media (hover: hover) {
  .UploadBox_box__q6\\+4S:hover .UploadBox_box__dropdownWrapper__yAjE4 {
    opacity: 1;
    visibility: visible;
  }
  .UploadBox_box__q6\\+4S:hover .UploadBox_box__icon__Ke2f2 {
    opacity: 0.7;
  }
}
.UploadBox_box__icon__Ke2f2 {
  width: 100%;
  height: 100%;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
  transition-property: opacity;
}
.UploadBox_box__icon__Ke2f2 svg {
  width: 12px;
  height: 21px;
}
@media (min-width: 1200px) {
  .UploadBox_box__icon__Ke2f2 svg {
    width: auto;
    height: auto;
  }
}
.UploadBox_box__dropdownWrapper__yAjE4 {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 222;
  padding-bottom: 12px;
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
}
.UploadBox_box__dropdown__skF\\+7 {
  border: 1px solid #e8ecee;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: #fff;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/UploadBox/UploadBox.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AACF;AACE;EATF;IAUI,WAAA;IACA,YAAA;EAEF;AACF;AAAE;EAGM;IACE,UAAA;IACA,mBAAA;EAAR;EAGM;IACE,YAAA;EADR;AACF;AAME;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,6BAAA;EACA,4BAAA;AAJJ;AAMI;EACE,WAAA;EACA,YAAA;AAJN;AAMM;EAJF;IAKI,WAAA;IACA,YAAA;EAHN;AACF;AAOE;EACE,kBAAA;EACA,YAAA;EACA,OAAA;EACA,YAAA;EACA,oBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;AALJ;AAQE;EACE,yBAAA;EACA,yCAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AANJ","sourcesContent":[".box {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 40px;\n  height: 40px;\n  flex-shrink: 0;\n\n  @media (min-width: 1200px) {\n    width: 24px;\n    height: 24px;\n  }\n\n  @media (hover: hover) {\n    &:hover {\n      .box {\n        &__dropdownWrapper {\n          opacity: 1;\n          visibility: visible;\n        }\n\n        &__icon {\n          opacity: 0.7;\n        }\n      }\n    }\n  }\n\n  &__icon {\n    width: 100%;\n    height: 100%;\n    flex: 0 0 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    transition: var(--transition);\n    transition-property: opacity;\n\n    svg {\n      width: 12px;\n      height: 21px;\n\n      @media (min-width: 1200px) {\n        width: auto;\n        height: auto;\n      }\n    }\n  }\n\n  &__dropdownWrapper {\n    position: absolute;\n    bottom: 100%;\n    left: 0;\n    z-index: 222;\n    padding-bottom: 12px;\n    transition: 0.2s;\n    opacity: 0;\n    visibility: hidden;\n  }\n\n  &__dropdown {\n    border: 1px solid #e8ecee;\n    box-shadow: 0 4px 6px rgba(0,0,0,.08);\n    border-radius: 4px;\n    background: #fff;\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `UploadBox_box__q6+4S`,
	"box__dropdownWrapper": `UploadBox_box__dropdownWrapper__yAjE4`,
	"box__icon": `UploadBox_box__icon__Ke2f2`,
	"box__dropdown": `UploadBox_box__dropdown__skF+7`
};
export default ___CSS_LOADER_EXPORT___;
