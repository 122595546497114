import { forwardRef, ReactNode } from "react"
import { Scrollbars } from "react-custom-scrollbars-2"
import clsx from "clsx"

interface IMyComponentProps {
  children: ReactNode
  hideTracksWhenNotNeeded?: boolean
  autoHide?: boolean
  scrollBarSize?: number
  className?: string
  autoHeightMax?: number
  fullHeightScrollBar?: boolean
  onUpdate?: () => void
}

const ScrollBlock = forwardRef<Scrollbars, IMyComponentProps>(
  (
    {
      children,
      hideTracksWhenNotNeeded = false,
      autoHide = true,
      scrollBarSize = 8,
      className,
      autoHeightMax,
      fullHeightScrollBar,
      onUpdate,
    },
    ref,
  ) => {
    return (
      <Scrollbars
        ref={ref}
        className={clsx(fullHeightScrollBar && "ScrollbarsCustom--full-height", className)}
        universal
        autoHide={autoHide}
        autoHeight={autoHeightMax ? true : undefined}
        autoHeightMax={autoHeightMax}
        hideTracksWhenNotNeeded={hideTracksWhenNotNeeded}
        renderTrackHorizontal={(props: any) => (
          <div
            {...props}
            style={{ ...props.style, height: scrollBarSize }}
            className="ScrollbarsCustom-Track ScrollbarsCustom-TrackX"
          />
        )}
        renderTrackVertical={(props: any) => (
          <div
            {...props}
            style={{ ...props.style, width: scrollBarSize }}
            className="ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
          />
        )}
        renderThumbHorizontal={(props: any) => <div {...props} className="ScrollbarsCustom-Thumb" />}
        renderThumbVertical={(props: any) => <div {...props} className="ScrollbarsCustom-Thumb" />}
        renderView={(props) => <div {...props} className="ScrollbarsCustom-Content" />}
        onUpdate={onUpdate}
      >
        {children}
      </Scrollbars>
    )
  },
)

ScrollBlock.displayName = "ScrollBlock"
export default ScrollBlock
