import Button from "../Assets/Button/Button"
import styles from "./PageNotFound.module.scss"

const PageNotFound = () => {
  return (
    <div className={styles.wrap}>
      <div>
        <p className={styles.description}>
          Мы надеялись, что вы никогда не прочитаете это сообщение, но что-то пошло не так...
        </p>
        <h2 className={styles.num}>404</h2>
        <p className={styles.note}>Возможно, этой страницы больше не существует.</p>
        <Button href={"/"} txt={"Главное меню"} className={styles.btn} />
      </div>
    </div>
  )
}

export default PageNotFound
