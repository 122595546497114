import { isValidElement, ReactNode, useEffect, useRef, useState } from "react"
import { ArrowRightIcon } from "../../../icons/ArrowRightIcon"
import clsx from "clsx"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import { StarIcon } from "../../../icons/StarIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"
import { useParams } from "react-router-dom"
import styles from "./ArticleListAccordion.module.scss"

interface Props {
  name: string
  href: string
  isGlobalOpen?: boolean
  isGlobalOpenAllowed?: boolean
  delay?: number
  isChildren?: boolean
  isDefaultOpen?: boolean
  children: ReactNode
}

const TRANSITION_DURATION_MS = 200

const ArticleListAccordion = ({
  name,
  href,
  isGlobalOpen,
  isGlobalOpenAllowed,
  delay,
  isChildren = true,
  isDefaultOpen = false,
  children,
}: Props) => {
  const params = useParams()
  const { favoriteArticles } = useAppSelector(selectArticles)

  const refWrap = useRef<HTMLDivElement>(null)
  const refListWrap = useRef<HTMLDivElement>(null)
  const refList = useRef<HTMLDivElement>(null)
  const [isOpen, setOpen] = useState<boolean>(isDefaultOpen)

  const handleToggle = () => {
    if (!refList.current || !refWrap.current || !refListWrap.current) return
    refListWrap.current.style.overflow = "hidden"
    let height = refList.current.offsetHeight
    refListWrap.current.style.height = height + "px"
    setOpen((prev) => !prev)

    setTimeout(() => {
      if (!refList.current || !refWrap.current || !refListWrap.current) return
      height = refList.current.offsetHeight
      refListWrap.current.style.height = isOpen ? "0px" : height + "px"
    }, 0)

    setTimeout(() => {
      if (!refList.current || !refWrap.current || !refListWrap.current) return
      refListWrap.current.style.overflow = ""
      refListWrap.current.style.height = ""
    }, TRANSITION_DURATION_MS)
  }

  useEffect(() => {
    if (!isGlobalOpenAllowed) return
    if (isOpen === isGlobalOpen) return
    if (delay) {
      setTimeout(() => {
        handleToggle()
      }, delay)
    } else {
      handleToggle()
    }
  }, [isGlobalOpen, delay, isGlobalOpenAllowed])

  return (
    <div ref={refWrap} className={clsx("accordion", styles.accordion)}>
      <div className={clsx(styles.mainItem, params?.id === href && styles["mainItem--is-active"])}>
        <button
          className={clsx("accordion__btn", styles.btn, !isChildren && styles["btn--hide"])}
          onClick={handleToggle}
        >
          <span className={clsx("accordion__iconWrap", styles.iconWrap, isOpen && styles["iconWrap--is-open"])}>
            <ArrowRightIcon />
          </span>
        </button>

        <LocalizedLink to={`/articles/` + href} className={clsx(styles.item)}>
          {name} {Boolean(favoriteArticles?.find((i) => i.id === href)) && <StarIcon />}
        </LocalizedLink>
      </div>
      <div ref={refListWrap} className={styles.listWrap}>
        <div ref={refList} className={styles.list}>
          {isOpen && children}
        </div>
      </div>
    </div>
  )
}

export default ArticleListAccordion
