import SliderNav from "../../Assets/SliderNav/SliderNav"
import { SwiperOptions } from "swiper/types/swiper-options"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { StarIcon } from "../../../icons/StarIcon"
import ArticlePrevItem from "../ArticlePrevItem/ArticlePrevItem"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import "swiper/css"
import styles from "./ArticlesFavorites.module.scss"
import { useMemo } from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import { useTranslation } from "react-i18next"

const ArticlesFavorites = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { favoriteArticles } = useAppSelector(selectArticles)
  const { isDesktop, isMobile } = useWindowSize()

  const swiperParams: SwiperOptions = useMemo(() => {
    let countViewSlides = 0
    if (isDesktop) {
      countViewSlides = 3
    }
    if (isMobile) {
      countViewSlides = 1
    }
    if (!isDesktop && !isMobile) {
      countViewSlides = 2
    }

    const params = {
      slidesPerView: countViewSlides,
      modules: [Navigation],
      navigation: {
        prevEl: `.${styles.nav} .slider-prev-btn`,
        nextEl: `.${styles.nav} .slider-next-btn`,
      },
    }
    return params
  }, [favoriteArticles, isDesktop, isMobile])

  const slides = useMemo(() => {
    const result = favoriteArticles.reduce((accumulator: any[], currentValue, index) => {
      if (isMobile) {
        if (index % 3 === 0) {
          accumulator.push([])
        }
      } else {
        accumulator.push([])
      }
      accumulator[accumulator.length - 1].push(currentValue)
      return accumulator
    }, [])

    return result.map((item, index) => (
      <SwiperSlide key={index}>
        {item.map((itemInner: any) => (
          <ArticlePrevItem key={itemInner.id} {...itemInner} className={styles.item} />
        ))}
      </SwiperSlide>
    ))
  }, [favoriteArticles, isDesktop, isMobile])

  if (!favoriteArticles?.length) return null

  return (
    <div className={styles.wrap}>
      <header className={styles.header}>
        <h4 className={styles.title}>{t("favorites")}</h4>
        <span className={styles.iconWrap}>
          <StarIcon />
        </span>
        <SliderNav className={styles.nav} mode={"white"} />
      </header>

      <Swiper {...swiperParams}>{slides}</Swiper>
    </div>
  )
}

export default ArticlesFavorites
