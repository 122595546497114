// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesPrevBlock_block__MGsbP {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.0509803922);
  padding: 12px 0 20px 0;
}

.ArticlesPrevBlock_header__hXKNO {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 12px;
}

.ArticlesPrevBlock_nav__6cV7D {
  position: relative;
}

.ArticlesPrevBlock_title__d4wil {
  font-weight: 500;
}

.ArticlesPrevBlock_item__Otec1 {
  padding: 12px 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Articles/ArticlesPrevBlock/ArticlesPrevBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,mBAAA;EACA,uDAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".block {\r\n  background-color: var(--white);\r\n  border-radius: 12px;\r\n  box-shadow: 0px 5px 9px 0px #0000000D;\r\n  padding: 12px 0 20px 0;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 0 12px;\r\n  margin-bottom: 12px;\r\n}\r\n\r\n.nav {\r\n  position: relative;\r\n}\r\n\r\n.title {\r\n  font-weight: 500;\r\n}\r\n\r\n.item {\r\n  padding: 12px 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `ArticlesPrevBlock_block__MGsbP`,
	"header": `ArticlesPrevBlock_header__hXKNO`,
	"nav": `ArticlesPrevBlock_nav__6cV7D`,
	"title": `ArticlesPrevBlock_title__d4wil`,
	"item": `ArticlesPrevBlock_item__Otec1`
};
export default ___CSS_LOADER_EXPORT___;
