// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountBtn_wrap__6ZJXp {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 112px;
  height: 40px;
  background-color: var(--white);
  border-radius: 12px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  padding: 8px;
}

.CountBtn_btn__PreGa {
  width: 24px;
  height: 24px;
  background-color: var(--white);
  transition: var(--transition);
  border-radius: 4px;
  position: relative;
}
@media (hover: hover) {
  .CountBtn_btn__PreGa:hover {
    background-color: var(--accent-color);
    color: var(--white);
  }
}
.CountBtn_btn__PreGa[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.CountBtn_btn__PreGa::before, .CountBtn_btn__PreGa::after {
  content: "";
  position: absolute;
  background-color: currentColor;
  left: 50%;
  top: calc(50% - 1px);
  transform: translate(-50%);
}
.CountBtn_btn__PreGa::before {
  width: 10px;
  height: 2px;
}
.CountBtn_btn__PreGa::after {
  height: 10px;
  width: 2px;
  top: calc(50% - 5px);
  display: none;
}
.CountBtn_btn--increment__I7yQq::after {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/CountBtn/CountBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,8BAAA;EACA,6BAAA;EACA,kBAAA;EACA,kBAAA;AACF;AACE;EACE;IACE,qCAAA;IACA,mBAAA;EACJ;AACF;AAEE;EACE,oBAAA;EACA,YAAA;AAAJ;AAGE;EAEE,WAAA;EACA,kBAAA;EACA,8BAAA;EACA,SAAA;EACA,oBAAA;EACA,0BAAA;AAFJ;AAKE;EACE,WAAA;EACA,WAAA;AAHJ;AAME;EACE,YAAA;EACA,UAAA;EACA,oBAAA;EACA,aAAA;AAJJ;AAQI;EACE,cAAA;AANN","sourcesContent":[".wrap {\r\n  display: inline-flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  width: 112px;\r\n  height: 40px;\r\n  background-color: var(--white);\r\n  border-radius: 12px;\r\n  font-size: 12px;\r\n  line-height: 12px;\r\n  font-weight: 500;\r\n  text-align: center;\r\n  padding: 8px;\r\n}\r\n\r\n.btn {\r\n  width: 24px;\r\n  height: 24px;\r\n  background-color: var(--white);\r\n  transition: var(--transition);\r\n  border-radius: 4px;\r\n  position: relative;\r\n\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      background-color: var(--accent-color);\r\n      color: var(--white);\r\n    }\r\n  }\r\n\r\n  &[disabled] {\r\n    pointer-events: none;\r\n    opacity: 0.5;\r\n  }\r\n\r\n  &::before,\r\n  &::after {\r\n    content: '';\r\n    position: absolute;\r\n    background-color: currentColor;\r\n    left: 50%;\r\n    top: calc(50% - 1px);\r\n    transform: translate(-50%);\r\n  }\r\n\r\n  &::before {\r\n    width: 10px;\r\n    height: 2px;\r\n  }\r\n\r\n  &::after {\r\n    height: 10px;\r\n    width: 2px;\r\n    top: calc(50% - 5px);\r\n    display: none;\r\n  }\r\n\r\n  &--increment {\r\n    &::after {\r\n      display: block;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CountBtn_wrap__6ZJXp`,
	"btn": `CountBtn_btn__PreGa`,
	"btn--increment": `CountBtn_btn--increment__I7yQq`
};
export default ___CSS_LOADER_EXPORT___;
