import Pusher from "pusher-js"
import Echo from "laravel-echo"
import { getApiUrl } from "./getApiUrl"

declare global {
  interface Window {
    Echo: any
    Pusher: any
  }
}

const EchoConfig = (token: string) => {
  if (typeof window !== undefined && typeof window.Echo !== undefined) {
    window.Pusher = Pusher

    const apiUrl = new URL(String(getApiUrl()))

    window.Echo = new Echo({
      broadcaster: "pusher",
      disableStats: true,
      client: new Pusher("your-pusher-key", {
        wsPort: 6001,
        wssPort: 6001,
        forceTLS: false,
        disableStats: true,
        wsHost: apiUrl.hostname + process.env.REACT_APP_PUBLIC_SOCKET_SUFFIX,
        cluster: "NaN",
        enabledTransports: ["ws", "wss"],
        auth: {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "/",
          },
        },
        authEndpoint: `${apiUrl.origin}/broadcasting/auth`,
      }),
    })

    window.Echo.connector.pusher.connection.strategy.transports.wss.transport.manager.livesLeft = Infinity

    window.Echo.connector.pusher.connection.bind("state_change", (states: any) => {
      if (states.current === "disconnected") {
        window.Echo.connector.pusher.connect()
      }
    })
  }
}

export default EchoConfig
