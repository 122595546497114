import { FC, ReactNode } from "react"
import clsx from "clsx"
import styles from "./Label.module.scss"

interface Props {
  className?: string
  txt: string
  href?: string
  isTarget?: boolean
  icon?: ReactNode
  mode?: "orange" | "gray"
  size?: "sm"
}

const Label: FC<Props> = ({ className, txt, href, isTarget, icon, mode, size }) => {
  if (href) {
    return (
      <a
        href={href}
        target={isTarget ? "_blank" : undefined}
        rel={isTarget ? "noreferrer" : undefined}
        className={clsx(
          styles.label,
          icon && styles["label--with-icon"],
          mode && styles["label--" + mode],
          size && styles["label--" + size],
          className,
        )}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        {txt}
      </a>
    )
  }
  return (
    <div className={clsx(styles.label, mode && styles["label--" + mode], size && styles["label--" + size], className)}>
      {txt}
    </div>
  )
}

export default Label
