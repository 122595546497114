import React from "react"
import Modal from "../../Modal/Modal"
import { useTranslation } from "react-i18next"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalTitle?: string
  modalText?: string
}

const SuccessModal: React.FC<Props> = ({ open, setOpen, modalTitle, modalText }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  return (
    <Modal open={open} setOpen={setOpen} addClass={"success"}>
      <div className={`modal-head`}>
        <div className={`modal-title`}>{modalTitle ?? ""}</div>
      </div>
      <div className={`modal-text`}>{modalText ?? ""}</div>
      <div className={`modal-bottom`}>
        <button type="button" onClick={() => setOpen(false)} className={`btn`}>
          {t("thanx")}!
        </button>
      </div>
      <div className={`modal-bottom-signature`}>
        {t("digitalPlatf")}
        <img src="/img/logo_staq_sm.svg" alt="" />
      </div>
    </Modal>
  )
}

export default SuccessModal
