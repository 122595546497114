import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import OrderDescription from "../../OrderDescription/OrderDescription"
import { IChatInfo, ILastMessage } from "../../../types/content"
import Button from "../../Assets/Button/Button"
import { useTranslation } from "react-i18next"
import OrderCallbackModal from "../OrderInfoModal/OrderCallbackModal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import clsx from "clsx"
import Chat from "../../Chat/Chat"
import { selectAllMessage, setAllMessage } from "../../../redux/slice/main"
import { useDispatch } from "react-redux"
import Search from "../../Search/Search"
import MessagesAsideList from "./MessagesAsideList"
import _debounce from "lodash/debounce"
import MessagesModalUsers from "./MessagesModalUsers"
import { useCreateChatMutation, useLazyGetChatListQuery, useLazyGetChatMessagesQuery } from "../../../redux/api/chat"
import styles from "./MessagesModal.module.scss"
import { useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import { IServiceOrder } from "../../../types/orderTypes"
import { selectAuth } from "../../../redux/slice/auth"
import { useAppSelector } from "../../../hooks"
import useWindowSize from "../../../hooks/useWindowSize"
import TitleBack from "../../TitleBack/TitleBack"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"
import { getMessagesModalFirstChat, setMessagesModalFirstChat } from "../../../redux/slice/modals"

interface Props {
  open: boolean
  setOpen: (res?: boolean) => void
  // firstChatId?: string
}

const LIMIT_CHAT_LIST = 14

const MessagesModal: FC<Props> = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const loaderRef = useRef<HTMLDivElement>(null)

  const { user } = useAppSelector(selectAuth)
  const allMessage = useAppSelector(selectAllMessage)

  const [getChatMessages] = useLazyGetChatMessagesQuery()
  const [getChatList, { isUninitialized, isFetching, isLoading }] = useLazyGetChatListQuery()
  const [getOrder] = useLazyGetServiceOrderQuery()
  const [createChat] = useCreateChatMutation()
  const { isDesktop } = useWindowSize()

  const currentFirstChat = useAppSelector(getMessagesModalFirstChat)

  const isFirstLoadMess = useRef<any>(false)
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false)
  const [isCanceledModal, setCanceledModal] = useState<boolean>(false)

  const [loadingChatList, setLoadingChatList] = useState<boolean>(true)
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [loadingOrderInfo, setLoadingOrderInfo] = useState<boolean>(true)

  const [searchChat, setSearchChat] = useState<string>("")
  const [chatList, setChatList] = useState<IChatInfo[]>([])
  const [order, setOrder] = useState<IServiceOrder | null>(null)
  const [activeChatId, setActiveChatId] = useState<string>("")
  const [activeOrderId, setActiveOrderId] = useState<string | null>("")
  const [offset, setOffset] = useState<number>(LIMIT_CHAT_LIST)
  const [isAllChats, setAllChats] = useState<boolean>(false)
  const [unreadMessList, setUnreadMessList] = useState<string[]>([])
  const [isFirstNoChats, setFirstNoChats] = useState<boolean>(false)
  const [isOpenChatOnMobile, setIsOpenChatOnMobile] = useState<boolean>(false)
  const [firstChatInList, setFirstChatInList] = useState<IChatInfo | null>(null)
  const [dummyChats, setDummyChats] = useState<boolean>(false)

  const { allowScroll } = useScrollBlock()

  const isViewChat = useMemo((): boolean => {
    if (isDesktop || (!isDesktop && isOpenChatOnMobile) || currentFirstChat) return true
    return false
  }, [isDesktop, isOpenChatOnMobile])

  const setInitialChats = async () => {
    try {
      const list = await getChatList({ limit: LIMIT_CHAT_LIST })
      if (!list?.data?.aDialogs?.length) {
        setAllChats(true)
        setFirstNoChats(true)
        setDummyChats(true)
        return
      }
      if (list?.data?.bIsEnd) setAllChats(true)

      const firstChat = currentFirstChat || list.data.aDialogs[0]
      if (currentFirstChat) {
        setFirstChatInList(currentFirstChat)
        setIsOpenChatOnMobile(true)
        dispatch(setMessagesModalFirstChat(null))
      }

      setChatList([firstChat, ...list.data.aDialogs.filter((item) => item.id !== firstChat.id)])
      setActiveChatId(firstChat.id)
      setActiveOrderId(firstChat.order_id)
      setLoadingChatList(false)

      const chat = await getChatMessages(firstChat.id)
      if (!chat?.data) return

      isFirstLoadMess.current = true
      dispatch(setAllMessage(chat.data.aMessages))
      setLoadingChat(false)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!open) {
      setInfoOpen(false)
      setLoadingChat(true)
      return
    }
    void setInitialChats()

    return () => {
      allowScroll(true)
    }
  }, [open])

  useEffect(() => {
    if (!isDesktop) {
      dispatch(setMobileMenuIsHidden(isViewChat))
    }
  }, [isViewChat])

  const updateChatListWithLastMess = (event: IChatInfo, chat_id: string) => {
    // обновляем последнее сообщение в общем списке чатов и добавляем чат в самый верх, если чата еще нет в общем списке
    setChatList((prev) => {
      const index = prev.findIndex((i) => i.id === event.id)
      const clone = [...prev]
      if ((index !== 0 && !index) || !clone[index]) return [event, ...prev]

      // Открыт ли текущий чат(делать ли сообщение как непрочитанное)
      const isNewUnread = chat_id && event.id !== chat_id
      const unread = isNewUnread ? { unreaded_messages: clone[index]?.unreaded_messages + 1 } : {}
      clone[index] = { ...clone[index], last_message: event.last_message, ...unread }
      if (isNewUnread) {
        const id = clone[index].id
        return [clone[index], ...clone.filter((i) => i.id !== id)]
      }
      return clone
    })
  }

  const addNewChatBySocket = (event: IChatInfo) => {
    if (!event?.id) return
    const existedChat = chatList.find((i) => i.id === event.id)
    if (!existedChat) {
      setChatList((prev) => {
        const curChat = prev.find((i) => i.id === event.id)
        if (curChat) return prev
        return [event, ...prev]
      })
    }
    setFirstNoChats(false)
    setLoadingChatList(false)
    isFirstLoadMess.current = true
  }

  const removeChatBySocket = (event: { id: string }) => {
    if (!event?.id) return
    setChatList((prev) => [...prev.filter((item) => item.id !== event.id)])
  }

  const editMessBySocket = (event: ILastMessage, currentActiveChatId: string) => {
    if (currentActiveChatId === event.chat_dialog_id) {
      dispatch(
        setAllMessage(
          allMessage.map((item: any) => {
            if (item.id === event.id) item = { ...item, ...event }
            return item
          }),
        ),
      )
    }
    setChatList((prev) => {
      const index = prev.findIndex((i) => i.id === event.chat_dialog_id)
      const clone = [...prev]
      if ((index !== 0 && !index) || !clone[index]) return prev
      if (clone[index]?.last_message?.id !== event.id) return prev
      clone[index] = {
        ...clone[index],
        last_message: { ...event, with_photos: false, with_attachments: false },
      }
      return clone
    })
  }

  const deleteMessBySocket = (event: ILastMessage, currentActiveChatId: string) => {
    if (currentActiveChatId === event.chat_dialog_id) {
      // если чат открыт, то удаляем это сообщение из чата
      dispatch(setAllMessage(allMessage.filter((item: any) => item.id !== event.id)))
    }
    // получаем все сообщения этого чата, и если удалённое сообщение было последним, то подставляем из всех сообщений предпоследнее
    getChatMessages(event.chat_dialog_id).then((res) => {
      const messages = res?.data?.aMessages
      if (messages && messages.length > 0) messages.filter((item) => item.id !== event.id)
      const lastMes = messages && messages.length > 0 ? messages[messages.length - 1] : null
      setChatList((prev) => {
        const index = prev.findIndex((i) => i.id === event.chat_dialog_id)
        const clone = [...prev]
        if ((index !== 0 && !index) || !clone[index]) return prev
        if (clone[index]?.last_message?.id !== event.id) return prev
        clone[index] = {
          ...clone[index],
          // @ts-ignore
          last_message: lastMes?.id ? { ...lastMes, with_attachments: false, with_photos: false } : [],
        }
        return clone
      })
    })
  }

  useEffect(() => {
    if (isUninitialized) return
    if (typeof window.Echo === "undefined") return
    if (!activeChatId) return
    const channel = window.Echo.private(`privateUser.${user.id}`)
    // channel?.listen(".changeDialogList", updateChatList)
    // добавление диалога в список
    channel?.listen(".newChatDialog", (event: IChatInfo) => addNewChatBySocket(event))
    // удаление диалога из списка
    channel?.listen(".removeChatDialog", (event: { id: string }) => removeChatBySocket(event))
    // пришло новое сообщение
    channel?.listen(".newMessageInDialog", (event: IChatInfo) => updateChatListWithLastMess(event, activeChatId))
    return () => {
      channel.stopListening(`.newChatDialog`)
      channel.stopListening(`.removeChatDialog`)
      channel.stopListening(`.newMessageInDialog`)
    }
  }, [isUninitialized, activeChatId])

  useEffect(() => {
    if (isUninitialized) return
    if (typeof window.Echo === "undefined") return
    if (!activeChatId) return
    const channel = window.Echo.private(`privateUser.${user.id}`)

    channel?.listen(".changeMessageInDialog", (event: ILastMessage) => editMessBySocket(event, activeChatId))
    channel?.listen(".deleteMessageInDialog", (event: ILastMessage) => deleteMessBySocket(event, activeChatId))

    return () => {
      channel.stopListening(`.changeMessageInDialog`)
      channel.stopListening(`.deleteMessageInDialog`)
    }
  }, [allMessage, isUninitialized, activeChatId])

  // Обновление счётчика непрочитанных сообщений, когда отправляется запрос markReadedMessage
  useEffect(() => {
    if (!unreadMessList.length) return
    setChatList((prev) => {
      const index = prev.findIndex((i) => i.id === activeChatId)
      const clone = [...prev]
      if (index !== 0 && !index) return prev
      let newUnreadMess = clone[index].unreaded_messages - unreadMessList.length
      if (newUnreadMess < 0) newUnreadMess = 0
      clone[index] = { ...clone[index], unreaded_messages: newUnreadMess }
      return clone
    })
  }, [unreadMessList, activeChatId])

  // Очищаем список id прочитанных сообщений, при смене чата
  useEffect(() => {
    setUnreadMessList([])
  }, [activeChatId])

  const debounceFn = useCallback(
    _debounce((str: string) => {
      if (!isFirstLoadMess.current) return
      setChatList([])
      setLoadingChatList(true)
      getChatList({ search: str, limit: LIMIT_CHAT_LIST, offset: 0 }).then(({ data }) => {
        setChatList(data?.aDialogs || [])
        setLoadingChatList(false)
        setOffset(LIMIT_CHAT_LIST)
        setAllChats(data?.bIsEnd || false)
      })
    }, 500),
    [],
  )

  useEffect(() => {
    debounceFn(searchChat)
  }, [searchChat])

  const callbackSearch = () => {
    if (isAllChats) return
    setLoadingChatList(true)
    getChatList({ search: searchChat, limit: LIMIT_CHAT_LIST, offset: offset }).then(({ data }) => {
      if (!data?.aDialogs) {
        setLoadingChatList(false)
        setAllChats(true)
        return
      }
      setChatList((prev) => [...new Set([...prev, ...data.aDialogs])])
      setLoadingChatList(false)
      setAllChats(data?.bIsEnd || false)
      setOffset((prev) => prev + LIMIT_CHAT_LIST)
    })
  }

  const clickChat = (chatId: string) => {
    setInfoOpen(false)
    setLoadingChat(true)
    setActiveChatId(chatId)
    const currentChat = chatList.find((i) => i.id === chatId)
    setActiveOrderId(currentChat?.order_id || null)
    getChatMessages(chatId).then((res) => {
      if (res?.data?.aMessages) {
        dispatch(setAllMessage(res.data.aMessages))
        setLoadingChat(false)
      }
    })
  }

  const toggleInfoOrder = () => {
    const currentChat = chatList.find((i) => i.id === activeChatId)
    if (!currentChat?.order_id) return
    setLoadingOrderInfo(true)
    setInfoOpen((prev) => !prev)
    getOrder(currentChat.order_id).then(({ data }) => {
      if (data) setOrder(data)
      setLoadingOrderInfo(false)
    })
  }

  const handlerCreateChat = (val: string) => {
    if (!val) return
    createChat({ type: "users", id: val })
      .unwrap()
      .then((res) => {
        if (!res) return
        const existedChat = chatList.find((i) => i.id === res.id)
        if (!existedChat) {
          setFirstChatInList(res)
          setChatList((prev) => [res, ...prev])
          clickChat(res.id)
          setFirstNoChats(false)
          setLoadingChatList(false)
          isFirstLoadMess.current = true
          setDummyChats(false)
        } else {
          setChatList((prev) => [res, ...prev.filter((item) => item.id !== res.id)])
          clickChat(res.id)
          setFirstChatInList(res)
        }
      })
  }

  return (
    <>
      <ModalPortal
        isOpen={open}
        setIsOpen={setOpen}
        className={clsx(styles.modal, {
          [styles["modal-mobile"]]: !isDesktop,
          [styles["mobile-chat__opened"]]: isViewChat && !isDesktop,
        })}
        isCloseBtn={isDesktop}
        header={
          <>
            {!isDesktop ? (
              <div className={styles["header-container"]}>
                <TitleBack
                  title={isViewChat ? chatList.find((i) => i.id === activeChatId)?.name ?? t("chats") : t("chats")}
                  onClick={() => {
                    if (isViewChat && !isInfoOpen) {
                      setIsOpenChatOnMobile(false)
                      return
                    }
                    if (isInfoOpen) {
                      setInfoOpen(false)
                      return
                    }

                    setOpen(false)
                  }}
                  noLinkBack
                />

                {activeOrderId && isViewChat && !isInfoOpen && (
                  <IconBtn
                    icon={"info"}
                    borderSize={"circle"}
                    mode={"white"}
                    className={clsx(styles.mobileBtnInfo)}
                    onClick={toggleInfoOrder}
                  />
                )}
              </div>
            ) : (
              <>
                <div className={clsx(styles.headerSlice, styles.headerSliceAside)}>
                  <h2 className={"modal__title"}>{t("chats")}</h2>
                  <MessagesModalUsers className={styles.headerChatWrap} onClick={handlerCreateChat} />
                </div>
                <div
                  className={clsx(
                    styles.headerSlice,
                    styles.headerSliceMain,
                    isInfoOpen && styles["headerSliceMain--is-info-open"],
                  )}
                >
                  <h2 className={clsx("modal__title", styles.titleName)}>
                    {chatList.find((i) => i.id === activeChatId)?.name}
                  </h2>
                  {activeOrderId && (
                    <IconBtn
                      icon={"close-square"}
                      borderSize={"sm"}
                      mode={"gray"}
                      size={"sm"}
                      className={clsx(styles.btnInfo, isInfoOpen && styles["btnInfo--is-open"])}
                      onClick={toggleInfoOrder}
                    />
                  )}
                  <div className={styles.separator} />
                </div>
              </>
            )}
          </>
        }
        name="MessagesModal"
      >
        <div className={styles.main}>
          {(isDesktop || (!isDesktop && !isViewChat)) && (
            <aside className={styles.aside}>
              <Search searchVal={searchChat} setSearchVal={setSearchChat} className={styles.search} />
              {(!isUninitialized && chatList?.length > 0) || isLoading || isFetching ? (
                <MessagesAsideList
                  list={chatList}
                  activeChatId={activeChatId}
                  isLoading={loadingChatList}
                  callbackSearch={callbackSearch}
                  onClick={(chatId) => {
                    clickChat(chatId)
                    if (!isDesktop) {
                      setIsOpenChatOnMobile(true)
                    }
                  }}
                />
              ) : searchChat && !isUninitialized && !isFetching && !chatList.length ? (
                <p className={styles.noChatList}>{t("objNotFound")}</p>
              ) : isFirstNoChats ? (
                <p className={styles.noChatList}>{t("noChatsYet")}</p>
              ) : null}

              <div ref={loaderRef} />
            </aside>
          )}

          {dummyChats ? (
            <div className={styles.dummyChat} />
          ) : (
            <>
              {isViewChat && (
                <>
                  {loadingChat ? (
                    <div className={clsx(styles.chatLoader, "skeletonBlock")} />
                  ) : (
                    <Chat
                      className={styles.chat}
                      chatID={activeChatId}
                      orderInfo={{
                        id: activeChatId,
                        name: chatList.find((i) => i.id === activeChatId)?.name,
                      }}
                      isFirstLoadMess={isFirstLoadMess.current}
                      setUnreadMessList={setUnreadMessList}
                      emptyTxt={t(activeOrderId ? "emptyTxtChatOrder" : "nothingYet")}
                    />
                  )}
                </>
              )}
            </>
          )}

          {isInfoOpen && (
            <div className={clsx(styles.info, { [styles["mobile-info"]]: !isDesktop })}>
              {loadingOrderInfo ? (
                <div className={clsx("skeletonBlock", styles.orderLoader)} />
              ) : (
                <>
                  {order && (
                    <>
                      <OrderDescription order={order} layout={"history"} />
                      <footer className={styles.infoFooter}>
                        {!isDesktop && (
                          <Button
                            txt={t("back")}
                            type="button"
                            onClick={() => {
                              setInfoOpen(false)
                            }}
                            className={`btn btn_grey ${styles["back-btn"]}`}
                          />
                        )}

                        {order.status === 0 && (
                          <Button
                            //TODO: нет реализации возвращения в работу
                            // txt={order.status === 0 ? t("cancel") : t("returnToWork")}
                            txt={t("cancel")}
                            mode={"warning"}
                            size={"sm"}
                            onClick={() => {
                              setCanceledModal(true)
                            }}
                          />
                        )}
                      </footer>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </ModalPortal>

      {isCanceledModal && order?.id && (
        <OrderCallbackModal
          layout={order.status === 0 ? "active" : "history"}
          open={isCanceledModal}
          setOpen={setCanceledModal}
          orderId={order.id}
          callbackClose={() => {
            setOpen(false)
            allowScroll(true)
          }}
          className={!isDesktop && styles["callback-modal"]}
        />
      )}
    </>
  )
}

export default MessagesModal
