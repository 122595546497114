// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BtnObject_btn__R-630 {
  background-color: #FAFAFA;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border: 1px solid transparent;
  position: relative;
  padding: 8px;
  transition: var(--transition);
}
.BtnObject_btn__R-630.error {
  border-color: var(--error);
}

@media (hover: hover) {
  button.BtnObject_btn__R-630:hover {
    border-color: var(--accent-color);
  }
}

.BtnObject_icon__8t7cp {
  flex-shrink: 0;
  margin-right: 8px;
}

.BtnObject_content__ZFd9x {
  font-size: var(--fz-lg);
  line-height: 23px;
}

.BtnObject_txt__pQQ8z {
  display: block;
  color: #7A808B;
  font-size: var(--fz-md);
  line-height: var(--lh-md);
}

.BtnObject_iconBack__kZhAK {
  flex-shrink: 0;
  margin-left: auto;
}

.BtnObject_error__1jl1\\+ {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  color: var(--error);
  font-size: 12px;
  line-height: 14px;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/BtnObject/BtnObject.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,6BAAA;EACA,kBAAA;EACA,YAAA;EACA,6BAAA;AACF;AACE;EACE,0BAAA;AACJ;;AAIE;EACE;IACE,iCAAA;EADJ;AACF;;AAKA;EACE,cAAA;EACA,iBAAA;AAFF;;AAKA;EACE,uBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,cAAA;EACA,cAAA;EACA,uBAAA;EACA,yBAAA;AAFF;;AAKA;EACE,cAAA;EACA,iBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,OAAA;EACA,qBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AAFF","sourcesContent":[".btn {\r\n  background-color: #FAFAFA;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  text-align: left;\r\n  border: 1px solid transparent;\r\n  position: relative;\r\n  padding: 8px;\r\n  transition: var(--transition);\r\n\r\n  &:global(.error) {\r\n    border-color: var(--error);\r\n  }\r\n}\r\n\r\nbutton.btn {\r\n  @media (hover: hover) {\r\n    &:hover {\r\n      border-color: var(--accent-color);\r\n    }\r\n  }\r\n}\r\n\r\n.icon {\r\n  flex-shrink: 0;\r\n  margin-right: 8px;\r\n}\r\n\r\n.content {\r\n  font-size: var(--fz-lg);\r\n  line-height: 23px;\r\n}\r\n\r\n.txt {\r\n  display: block;\r\n  color: #7A808B;\r\n  font-size: var(--fz-md);\r\n  line-height: var(--lh-md);\r\n}\r\n\r\n.iconBack {\r\n  flex-shrink: 0;\r\n  margin-left: auto;\r\n}\r\n\r\n.error {\r\n  position: absolute;\r\n  left: 0;\r\n  top: calc(100% + 4px);\r\n  color: var(--error);\r\n  font-size: 12px;\r\n  line-height: 14px;\r\n  pointer-events: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `BtnObject_btn__R-630`,
	"icon": `BtnObject_icon__8t7cp`,
	"content": `BtnObject_content__ZFd9x`,
	"txt": `BtnObject_txt__pQQ8z`,
	"iconBack": `BtnObject_iconBack__kZhAK`,
	"error": `BtnObject_error__1jl1+`
};
export default ___CSS_LOADER_EXPORT___;
