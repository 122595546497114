import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import AuthModal from "../Modals/AuthModal/AuthModal"
import styles from "./RoomLayout.module.scss"
import RoomHead from "./RoomHead"
import SearchList from "../SearchList/SearchList"
import { useGetRoomServicesMutation } from "../../redux/api/main"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { selectAuth, updateIsAllowNewServiceCategory } from "../../redux/slice/auth"
import { currentObject, updateObjectPage, getServicesList } from "../../redux/slice/object"
import LanguageModal from "../Modals/LanguageModal/LanguageModal"
import useWindowSize from "../../hooks/useWindowSize"

interface Props {
  children?: any
}

const RoomLayout: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop, size } = useWindowSize()

  const [marginTopContent, setMarginTopContent] = useState<number>(0)
  const [searchedList, setSearchedList] = useState<any[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [notFoundText, setNotFoundText] = useState<string>("")
  // const [isAllowNewServiceCategory, setIsAllowNewServiceCategory] = useState<boolean>(false)
  const serviceItemsContainer = useRef<null | NodeListOf<HTMLElement>>(null)

  const { categories, subCatsObject, isLoadingServicesList } = useAppSelector(currentObject)

  const headRef = useRef<HTMLElement>(null)
  // Дабы показывать прелоадер, а не то, что нет услуш для объектов
  const firstLoad = useRef<any>(true)

  // Модалка аутентификации
  // const [authModal, setAuthModal] = useState(false)

  const scrollTrigger = () => {
    const head = document.querySelector<HTMLElement>(`.${styles.roomLayout__hero}`)
    const windowWidth = window.innerWidth
    const headText = document.querySelector<HTMLElement>(`.${styles["roomLayout__hero-title"]}`)

    // Не законченная функция изменения размера текста в заголовке. Если понадобится - доработать
    // const headText = document.querySelector<HTMLElement>(`.${styles["roomLayout__hero-title"]}`)
    // function scale(block: HTMLElement, size = 20) {
    //   setFlag(true)
    //   const step = 1
    //   const minfs = 9
    //   const scw = block.scrollWidth
    //   const w = block.offsetWidth

    //   console.log(block, "block")

    //   console.log(scw, "scw")
    //   console.log(w, "w")
    //   if (scw > w) {
    //     // block.style.fontSize
    //     const qwe = document.defaultView?.getComputedStyle(block, null).fontSize.split("px")[0]
    //     console.log(qwe, "qwe")
    //     //@ts-ignore
    //     const fontsize = size - step
    //     console.log(fontsize, "fontsize")
    //     // block.style.background = "red"
    //     if (fontsize >= minfs) {
    //       block.style.fontSize = `${fontsize}px`
    //       console.log("ZZZ")

    //       console.log(block.scrollWidth, "block.scrollWidth@@@")
    //       // block.style.fontSize = `${fontsize} px`
    //       // ("font-size", fontsize)

    //       const resizeObserver = new ResizeObserver(() => {
    //         console.log("The element was resized")
    //       })

    //       resizeObserver.observe(block)

    //       // scale(block, fontsize)
    //     }
    //   }
    // }

    //Хеадер с картинкой и названием
    if (head) {
      // const headContent = document.querySelector<HTMLElement>(`.${styles["roomLayout__hero-content"]}`)
      const headContent = headRef.current
      const maxHeiHead = windowWidth <= 640 ? 198 : 354
      if (windowWidth > 640) {
        if (maxHeiHead - window.scrollY < 220) {
          if (headText && headText?.innerText.length > 30) {
            headText.style.fontSize = "20px"
          }
          headContent?.classList.add(styles["roomLayout__hero-content-flex"])
        } else {
          //@ts-ignore
          headText.style.fontSize = "36px"
          headContent?.classList.remove(styles["roomLayout__hero-content-flex"])
        }
        // maxHeiHead - window.scrollY < 220
        //   ?
        //   :
      } else {
        if (maxHeiHead - window.scrollY < 120) {
          if (headText && headText?.innerText.length > 30) {
            headText.style.fontSize = "12px"
          }
          headContent?.classList.add(styles["roomLayout__hero-content-flex"])
          head.style.padding = "10px 16px"
        } else {
          //@ts-ignore
          headText.style.fontSize = "20px"
          headContent?.classList.remove(styles["roomLayout__hero-content-flex"])
          head.style.padding = "16px 16px 28px"
        }
      }
      head.style.height = `${maxHeiHead - window.scrollY}px`
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", scrollTrigger)
    document.addEventListener("resize", scrollTrigger)
    scrollTrigger()

    return () => {
      document.removeEventListener("scroll", scrollTrigger)
      document.removeEventListener("resize", scrollTrigger)
    }
  }, [])

  useEffect(() => {
    scrollTrigger()
  }, [size])

  const [getRoomServices, { isLoading }] = useGetRoomServicesMutation()
  const user = useAppSelector(selectAuth)
  const { serviceCategories } = useAppSelector(currentObject)
  const dispatch: any = useAppDispatch()
  const queries: any = useParams()

  const getList = () => getRoomServices({ technical_object_id: queries?.id ?? "" })

  useEffect(() => {
    if (user.token) {
      getList()
        .then((result: any) => {
          dispatch(updateObjectPage(result?.data?.data ?? {}))
        })
        .catch((err) => console.warn("Error", err))

      dispatch(getServicesList({ technical_object_id: queries?.id }))
    }
  }, [user])

  useEffect(() => {
    if (serviceCategories && (!serviceItemsContainer.current || serviceItemsContainer.current.length === 0))
      serviceItemsContainer.current = document.querySelectorAll<HTMLElement>(`.searchList-item`)
  })

  const getPage = () => {
    if (queries.id && !queries.category_id) {
      return 1
    }
    if (queries.category_id) {
      return 2
    }
  }

  const marginTopContentLHundler = () => {
    if (getPage() === 1) {
      window.innerWidth <= 640 ? setMarginTopContent(240) : setMarginTopContent(416)
    }

    if (getPage() === 2 && window.innerWidth <= 640) {
      subCatsObject?.length > 0 ? setMarginTopContent(289) : setMarginTopContent(248)
    }
    if (getPage() === 2 && window.innerWidth > 640) {
      subCatsObject?.length > 0 ? setMarginTopContent(424) : setMarginTopContent(402)
    }
  }

  useEffect(() => {
    marginTopContentLHundler()
    window.addEventListener("resize", marginTopContentLHundler)
    return () => window.removeEventListener("resize", marginTopContentLHundler)
  }, [subCatsObject])

  useEffect(() => {
    if (getPage() === 2) {
      const openedCat = categories.find((cat: any) => cat.id === queries.category_id)
      if (openedCat) dispatch(updateIsAllowNewServiceCategory(openedCat?.is_allow_prorose_new_service))
      // if (openedCat) setIsAllowNewServiceCategory(openedCat.is_allow_prorose_new_service)
    }
  }, [queries])

  useEffect(() => {
    if (isSearching && !searchedList.length) setNotFoundText(t("searchServiceNotFound"))
    else if (categories.length < 1 && !isLoadingServicesList) setNotFoundText(t("emptyObject"))
  }, [isSearching, searchedList, categories, isLoadingServicesList])

  const getSearchedListHandler = (list: Array<any>) => {
    setSearchedList(list)
  }

  const searchLoadingHandler = (bool: boolean) => {
    setIsSearchLoading(bool)
  }

  const getIsSearchingHandler = (val: boolean) => {
    setIsSearching(val)
  }

  return (
    <React.Fragment>
      <RoomHead
        headRef={headRef}
        getSearchedList={getSearchedListHandler}
        searchLoading={searchLoadingHandler}
        subCats={subCatsObject?.length}
        getIsSearching={getIsSearchingHandler}
      />
      <div
        className={`${styles.roomLayout__content} ${getPage() === 1 ? styles.roomLayout__content_firstPage : ""}`}
        style={{ marginTop: marginTopContent !== 0 ? marginTopContent : "" }}
      >
        {isSearching ? <SearchList list={searchedList} isSearchLoading={isSearchLoading} /> : children}
        {/* {searchedList.length > 0 ? <SearchList list={searchedList} isSearchLoading={isSearchLoading} /> : children} */}
      </div>
      <>
        {((isSearching && !searchedList.length) || (categories.length < 1 && !isLoadingServicesList)) && (
          <p style={{ margin: "auto 0", textAlign: "center" }}>{notFoundText}</p>
        )}
      </>

      {/* <div className={styles.roomLayout__content}>
        <div style={{ display: serviceCategories.length ? "block" : "none" }} className={styles.roomLayout__cats}>
          <div className={styles.roomLayout__catsWp}>
            <div className={styles.roomLayout__catsWrap}>
              <Swiper {...swiperParams} ref={swiperRef}>
                {serviceCategories?.map((el: any, kk: number) => (
                  <SwiperSlide
                    onClick={() => scrollToItem(kk)}
                    key={el.id}
                    className={`${styles["roomLayout__catsWrap-anchor"]} ${
                      numActiveLink === kk ? `${styles["roomLayout__catsWrap-anchor-active"]}` : ""
                    }`}
                  >
                    <a>{el.name}</a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <div className={styles.roomLayout__list}>
          {serviceCategories.length ? (
            serviceCategories.map((cat: any, kk: number) => (
              <SearchList key={kk} title={cat?.name ?? ""} list={cat?.services} catId={cat?.id} />
            ))
          ) : firstLoad?.current ? (
            <>
              <ProductItem isLoading={true} />
              <ProductItem isLoading={true} />
              <ProductItem isLoading={true} />
            </>
          ) : (
            <div className={styles.roomLayout__empty}>{t("emptyObject")}</div>
          )}
        </div>
      </div> */}
      <AuthModal type={""} />
      {/* {!isDesktop && <LanguageModal />} */}
    </React.Fragment>
  )
}

export default RoomLayout
